import { ORIGIN } from "@dpdgroupuk/mydpd-enums";

import api from "./api";
import { REFERENCE } from "./basePaths";

export const fetchCountries = (query, options) =>
  api.get({
    path: `${REFERENCE}/countries`,
    query,
    options,
  });

export const searchCollections = (query, options) =>
  api.get({
    path: `${REFERENCE}/collections`,
    query: {
      ...query,
      origin: ORIGIN.CCTK,
    },
    options,
  });

export const searchParcels = (query, options) =>
  api.get({
    path: `${REFERENCE}/parcels`,
    query: {
      ...query,
      origin: ORIGIN.PCTK,
    },
    options,
  });

export const fetchExportReasons = (query, options) =>
  api.get({
    path: `${REFERENCE}/exportreasons`,
    query,
    options,
  });

export const fetchOutboundServices = (query, options) =>
  api.get({
    path: `${REFERENCE}/outboundservices`,
    query,
    options,
  });

export const fetchInboundServices = (query, options) =>
  api.get({
    path: `${REFERENCE}/inboundservices`,
    query,
    options,
  });

export const searchAddresses = (query, options) =>
  api.get({
    path: `${REFERENCE}/addresses`,
    query,
    options,
  });

export const fetchPostcodeIssues = (postcode, options = {}) =>
  api.get({
    path: `${REFERENCE}/postcode/issues`,
    query: {
      postcode,
    },
    options,
  });

export const fetchCurrencies = (query, options) =>
  api.get({
    path: `${REFERENCE}/currencies`,
    query,
    options,
  });

export const fetchNetworks = (query, options) =>
  api.get({
    path: `${REFERENCE}/networks`,
    query,
    options,
  });

export const fetchCommoditiesByFilter = (query, options) =>
  api.get({
    path: `${REFERENCE}/commodities/filter`,
    query,
    options,
  });

export const validateCommodity = (query, options) =>
  api.get({
    path: `${REFERENCE}/validatecommodity`,
    query,
    options,
  });

export const fetchCommodities = (query, options) =>
  api.get({
    path: `${REFERENCE}/commodities`,
    query,
    options,
  });

export const fetchCommoditiesByCode = (query, options) =>
  api.get({
    path: `${REFERENCE}/commodities/code`,
    query,
    options,
  });

export const fetchGroupDispatchServices = (query, options) =>
  api.get({
    path: `${REFERENCE}/groupDispatchService`,
    query,
    options,
  });

export const fetchRequiredFieldsForDomesticZone = (query, options) =>
  api.get({
    path: `${REFERENCE}/requiredfieldsfordomesticzone`,
    query,
    options,
  });
