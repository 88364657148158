import { useMemo } from "react";

import { PropTypes } from "prop-types";
import { propTypes } from "redux-form";

import { Step } from "@dpdgroupuk/mydpd-ui";

import ReturnPackageDetailsContent from "~/components/ReturnPackageDetailsContent";

import { PACKAGE_RETURN_DETAILS } from "~/constants/forms";
import { HELP_INFO } from "~/constants/info";
import * as S from "~/constants/strings";
import { ShipmentModels } from "~/models";

const ReturnPackageDetails = props => {
  const { createShipmentSyncErrors } = props;
  const isValidReturnPackageDetailsSection = useMemo(
    () =>
      ShipmentModels.isValidReturnPackageDetailsSection(
        createShipmentSyncErrors
      ),
    [createShipmentSyncErrors]
  );

  return (
    <Step
      totalSteps={props.totalSteps}
      step={props.step}
      withStepCounter
      isLoading={props.isLoadingInboundServices}
      complete={!props.pristine && isValidReturnPackageDetailsSection}
      title={S.PACKAGE_RETURN_DETAILS}
      help={HELP_INFO[PACKAGE_RETURN_DETAILS]}
      helpModalTitle={S.PACKAGE_RETURN_DETAILS}
    >
      <ReturnPackageDetailsContent {...props} />
    </Step>
  );
};

ReturnPackageDetails.propTypes = {
  returnServiceValues: PropTypes.array,
  returnProductValues: PropTypes.array,
  ...propTypes,
};

export default ReturnPackageDetails;
