import isEmpty from "lodash/isEmpty";
import { initialize, reset, touch } from "redux-form";

import { UserDataSelectors } from "@dpdgroupuk/mydpd-app";

import {
  DELIVERY_DETAILS_SEARCH_FORM,
  RETURN_DETAILS_SEARCH_FORM,
} from "~/constants/forms";
import { ShipmentSelectors } from "~/pages/Shipment/redux";
import {
  AddressBookActions,
  ProfilesSelectors,
  ReferenceActions,
  ReferenceSelectors,
  UmsSelectors,
} from "~/redux";
import { getDeepKeys } from "~/utils/object";
import { touchFields } from "~/utils/reduxForm";

import { getCreateShipmentInitialValues } from "./models";
import { getAddressBookErrors } from "./selectors";

export const handleSubmitAddressbook =
  ({ pageConfig, notifier }) =>
  (dispatch, getState) =>
  onSuccess => {
    const errors = getAddressBookErrors(getState(), pageConfig);

    if (!isEmpty(errors)) {
      const flat = getDeepKeys(errors);
      dispatch(touch(pageConfig.formName, ...flat));
      notifier.scrollToError(flat);
    } else {
      onSuccess();
    }
  };

export const clearCreateShipmentPage = formName => (dispatch, getState) => {
  const shippingSettings = UmsSelectors.getShippingSettings(getState());
  const initialValues = getCreateShipmentInitialValues(
    ProfilesSelectors.getActiveProfile(getState(), { formName }),
    ReferenceSelectors.getCountriesKeyValue(getState()),
    UmsSelectors.getPreferences(getState()),
    UserDataSelectors?.getItem(getState().app, "date"),
    shippingSettings,
    ShipmentSelectors.getUniqueSenderRef1(getState())
  );
  dispatch(ReferenceActions.clearServices());
  dispatch(initialize(formName, initialValues));
  dispatch(touchFields(formName, initialValues));
  dispatch(reset(DELIVERY_DETAILS_SEARCH_FORM));
  dispatch(reset(RETURN_DETAILS_SEARCH_FORM));
  dispatch(AddressBookActions.clearAddressBook());
};
