import { useMemo } from "react";
import PropTypes from "prop-types";
import { Col, Container, Row } from "react-bootstrap";
import { Field, propTypes } from "redux-form";

import BaseContactForm from "~/components/BaseContactForm";
import DoubleAstericksInput from "~/components/DoubleAstericksInput/DoubleAstericksInput";
import DeliveryContactCard from "~/components/ReviewCard/cards/DeliveryContact";
import {
  OUTBOUND_CONSIGNMENT_UPPERCASE,
  ShipmentEntity,
} from "~/constants/forms";
import * as S from "~/constants/strings";
import { Normalizers, ServiceModels, SettingsModels } from "~/models";

const DeliveryContactContent = props => {
  const {
    disabledFields,
    requiredFields,
    allowedFields,
    customer,
    selectedService,
  } = props;

  const isNewVersion = useMemo(
    () => SettingsModels.isNewVersion(customer.shippingVersion),
    [customer.shippingVersion]
  );
  const isNotifyRequired = useMemo(
    () => ServiceModels.isFlagRequired(selectedService, "notifyRequired"),
    [selectedService]
  );

  return (
    <Container fluid className="p-0">
      <Row>
        <Col md={isNotifyRequired && !isNewVersion ? 6 : 12}>
          <Col
            className={`px-0 ${isNotifyRequired && !isNewVersion && "pr-md-4"}`}
          >
            <BaseContactForm
              {...props}
              shipmentSection={OUTBOUND_CONSIGNMENT_UPPERCASE}
            />
            <Row>
              <Col>
                <Field
                  component={DoubleAstericksInput}
                  label={S.NOTIFICATION_EMAIL}
                  name={
                    ShipmentEntity.OUTBOUND_CONSIGNMENT.DELIVERY_DETAILS
                      .NOTIFICATION_DETAILS.EMAIL
                  }
                  maxLength={100}
                  helperText={S.MAX_100_CHARACTERS}
                  onBlur={props.onFieldEntry}
                  disabled={
                    disabledFields[
                      ShipmentEntity.OUTBOUND_CONSIGNMENT.DELIVERY_DETAILS
                        .NOTIFICATION_DETAILS.EMAIL
                    ]
                  }
                  required={
                    requiredFields[
                      ShipmentEntity.OUTBOUND_CONSIGNMENT.DELIVERY_DETAILS
                        .NOTIFICATION_DETAILS.EMAIL
                    ]
                  }
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <Field
                  component={DoubleAstericksInput}
                  label={S.MOBILE_NOTIFICATION_TEXT}
                  name={
                    ShipmentEntity.OUTBOUND_CONSIGNMENT.DELIVERY_DETAILS
                      .NOTIFICATION_DETAILS.MOBILE
                  }
                  normalize={Normalizers.phoneNumberNormalize}
                  helperText={S.MAX_15_CHARACTERS}
                  onBlur={props.onFieldEntry}
                  disabled={
                    disabledFields[
                      ShipmentEntity.OUTBOUND_CONSIGNMENT.DELIVERY_DETAILS
                        .NOTIFICATION_DETAILS.MOBILE
                    ]
                  }
                  required={
                    requiredFields[
                      ShipmentEntity.OUTBOUND_CONSIGNMENT.DELIVERY_DETAILS
                        .NOTIFICATION_DETAILS.MOBILE
                    ]
                  }
                />
              </Col>
            </Row>
          </Col>
        </Col>

        {allowedFields.deliveryContactCard && (
          <Col md={isNewVersion ? 12 : 6}>
            <DeliveryContactCard />
          </Col>
        )}
      </Row>
    </Container>
  );
};

DeliveryContactContent.propTypes = {
  requiredFields: PropTypes.object,
  disabledFields: PropTypes.object,
  allowedFields: PropTypes.object,
  ...propTypes,
};

export default DeliveryContactContent;
