import {
  DELIVERY_CONTACT,
  DELIVERY_DETAILS,
  EXPORTER_DETAILS,
  IMPORTER_DETAILS,
  PACKAGE_CONTENT,
  PACKAGE_DETAILS,
  PACKAGE_RETURN_DETAILS,
  PACKAGE_SERVICE_DETAILS,
  PARCEL_DETAILS,
  PARCEL_RETURN_DETAILS,
  PARCEL_RETURN_SERVICE_DETAILS,
  RETURN_CONTACT,
  RETURN_DETAILS,
  SHIPPING_INFORMATION,
} from "~/constants/forms";

const packageDetailsStartTextContent = [
  {
    name: "Delivery Total Number Of Packages",
    content: (
      <>
        <p>
          The number of labels required for the shipment. Expresspak services
          can only contain 1 parcel in the shipment.
        </p>
      </>
    ),
  },
  {
    name: "Delivery Total Weight (Kg)",
    content: (
      <>
        <p>The total weight of the parcels in the shipment.</p>
        <ul className="pl-3">
          <li>Expresspak - max weight is 5kg per Expresspak</li>
          <li>Parcel - max weight is 30kg per Parcel</li>
          <li>Pallet - max weight is 999kg per Pallet</li>
          <li>Freight Parcel - max weight is 99kg per Freight Parcel</li>
        </ul>
      </>
    ),
  },
];

const packageDetailsEndTextContent = [
  {
    name: "Shipment Date",
    content: (
      <>
        <p>
          The date your shipment will be collected. This can also be used to
          forward date a shipment.
        </p>
      </>
    ),
  },
  {
    name: "Delivery Reference 1",
    content: (
      <>
        <p>
          Reference 1 is usually an order, or picking number provided by the
          shipper. E.g. ORD1234567.
          <br />
          Reference 2 and 3 are extra fields that can be used for any other
          reference details. All reference fields will appear on the shipping
          label. Maximum 25 characters for each field.
        </p>
      </>
    ),
  },
  {
    name: "Delivery Reference 2",
    content: (
      <>
        <p>
          Reference 1 is usually an order, or picking number provided by the
          shipper. E.g. ORD1234567.
          <br />
          Reference 2 and 3 are extra fields that can be used for any other
          reference details. All reference fields will appear on the shipping
          label. Maximum 25 characters for each field.
        </p>
      </>
    ),
  },
  {
    name: "Delivery Reference 3",
    content: (
      <>
        <p>
          Reference 1 is usually an order, or picking number provided by the
          shipper. E.g. ORD1234567.
          <br />
          Reference 2 and 3 are extra fields that can be used for any other
          reference details. All reference fields will appear on the shipping
          label. Maximum 25 characters for each field.
        </p>
      </>
    ),
  },
  {
    name: "Collect On Delivery",
    content: (
      <>
        <p>
          Select Reverse It or Swap It, if the shipment also has a parcel being
          returned on delivery.
        </p>
      </>
    ),
  },
];

const packageServiceDetailsTextContent = [
  {
    name: "Generate Customs Data",
    content: (
      <>
        <p>
          Customs data is required for international shipments. Select
          &lsquo;YES&rsquo; to add a customs invoice to your shipment manually.
          This is defaulted to &lsquo;YES&rsquo; for International By Road
          service and for shipments to ROI.
        </p>
      </>
    ),
  },
  {
    name: "Delivery Product",
    content: (
      <>
        <p>The type of shipment that is being sent.</p>
      </>
    ),
  },
  {
    name: "Delivery Service",
    content: (
      <>
        <p>The service the shipment is being sent on.</p>
      </>
    ),
  },
  {
    name: "Customs Value",
    content: (
      <>
        <p>
          The total value of the items within the parcel to be displayed on the
          invoice (not including shipping costs) &#38; values provided must be
          exclusive of VAT. Zero &lsquo;0&rsquo; values must not be used else
          parcels will be returned to the sender. For items of no commercial
          value please enter the intrinsic or cost value for customs assessment
          purposes. This will also auto populate if products are added to an
          international shipment GBP Only
        </p>
      </>
    ),
  },
  {
    name: "Contents Description",
    content: (
      <>
        <p>
          A detailed description of the overall contents of the parcel is
          required.
        </p>
        <p>
          To prevent customs delays or the parcel being returned, avoid
          generalised or vague descriptions e.g.
        </p>
        <p>
          instead of &lsquo;clothing&rsquo; state &lsquo;women&apos;s cotton
          dresses&rsquo; <br />
          instead of &lsquo;tools&rsquo; state &lsquo;hand tools&rsquo; or
          &lsquo;electric tools&rsquo;
        </p>
      </>
    ),
  },
  {
    name: "Extended Liability",
    content: (
      <>
        <p>
          Get extra protection for this shipment, up to £5000 cover for just
          £7.50. International services are charged at 1% of value of goods with
          a minimum charge of £7.50
        </p>
        <p>
          Please see our <a href="#">terms and conditions</a> for items excluded
          from carriage.
        </p>
      </>
    ),
  },
];

const businessDetails = [
  {
    name: "Importer Type",
    content: (
      <>
        <p>
          As part of the Windsor Framework, the new arrangements for sending
          parcels from Great Britain (GB) to Northern Ireland (NI) will affect
          how both consumer and business shipments are handled, and the
          information you provide when creating a label differs depending on the
          type of importer (recipient).
        </p>
        <p>
          Please indicate whether your goods will be delivered to a consumer or
          a business.
        </p>
      </>
    ),
  },
  {
    name: "At Risk of Entering EU",
    content: (
      <>
        <p>
          For parcels being sent to businesses you'll now need to submit
          declarations for these items. DPD will take care of making these
          declarations for you from the data you supply in the shipping
          manifest, however in order to do so you will need to declare whether
          goods are considered 'at risk' or 'not at risk' of onward movement to
          the European Union (EU).
        </p>
        <p>
          Please refer to the{" "}
          <a
            href="https://www.gov.uk/guidance/check-if-you-can-declare-goods-you-bring-into-northern-ireland-not-at-risk-of-moving-to-the-eu"
            target={"blank"}
          >
            Gov.uk site
          </a>{" "}
          for more information on the declaration of these categories.
        </p>
      </>
    ),
  },
  {
    name: "Product Description",
    content: (
      <>
        <p>An in-depth description of the product.</p>
      </>
    ),
  },
  {
    name: "Destination Tax ID / Reg Number",
    content: (
      <>
        <p>
          For EU destinations, please include your IOSS VAT identification
          number if applicable to your shipment. The format of an IOSS number is
          IM followed by 10 alphanumeric characters.
        </p>
        <p>An IOSS number should look like this:</p>
        <p>IM1234567891</p>
        <p>
          For Australia, please include your GST number if applicable to your
          shipment. The format of an IOSS number is ARN followed by 10 numerical
          digits.
        </p>
        <p>An Australian GST number should look like this:</p>
        <p>ARN12345678901</p>
      </>
    ),
  },
  {
    name: "GST/VAT Paid",
    content: (
      <>
        <p>
          This is required if the Destination Tax ID/Reg No has been populated.
        </p>
      </>
    ),
  },
  {
    name: "FDA Registration Number",
    content: (
      <>
        <p>
          An FDA registration number is required if food or drugs is being sent
          to the US.
        </p>
      </>
    ),
  },
];

const packageReturnDetailsStartTextContent = [
  {
    name: "Return Total Number of Packages",
    content: (
      <>
        <p>The number of parcels being returned.</p>
      </>
    ),
  },
  {
    name: "Return Total Weight (kg)",
    content: (
      <>
        <p>The total weight the shipment being returned.</p>
      </>
    ),
  },
];

const packageReturnDetailsEndTextContent = [
  {
    name: "Return Item Description",
    content: (
      <>
        <p>A description of the returned item.</p>
      </>
    ),
  },
  {
    name: "Return Reference 1",
    content: (
      <>
        <p>
          Reference 1 is usually an order, or picking number provided by the
          shipper. E.g. ORD1234567. Reference 2 and 3 are extra fields that can
          be used for any other reference details. All reference fields will
          appear on the shipping label. Maximum 25 characters for each field.
        </p>
      </>
    ),
  },
  {
    name: "Return Reference 2",
    content: (
      <>
        <p>
          Reference 1 is usually an order, or picking number provided by the
          shipper. E.g. ORD1234567. Reference 2 and 3 are extra fields that can
          be used for any other reference details. All reference fields will
          appear on the shipping label. Maximum 25 characters for each field.
        </p>
      </>
    ),
  },
  {
    name: "Return Reference 3",
    content: (
      <>
        <p>
          Reference 1 is usually an order, or picking number provided by the
          shipper. E.g. ORD1234567. Reference 2 and 3 are extra fields that can
          be used for any other reference details. All reference fields will
          appear on the shipping label. Maximum 25 characters for each field.
        </p>
      </>
    ),
  },
];

const packageReturnServiceDetailsTextContent = [
  {
    name: "Return Product",
    content: (
      <>
        <p>The type of shipment that is being returned.</p>
      </>
    ),
  },
  {
    name: "Return Service",
    content: (
      <>
        <p>The service the shipment is being returned on.</p>
      </>
    ),
  },
];

//@see: https://elekscloud-my.sharepoint.com/:w:/g/personal/orysia_fuchenko_eleks_com/Ef0lBBG-1UFJmXH_ulzmRPIBO7biVA5N0LzfiG7_kABxng?wdOrigin=TEAMS-MAGLEV.p2p_ns.rwc&wdExp=TEAMS-TREATMENT&wdhostclicktime=1728375281647&web=1
// eslint-disable-next-line import/no-anonymous-default-export
export const HELP_INFO = {
  [DELIVERY_DETAILS]: [
    {
      name: "Delivery Destination Country",
      content: (
        <>
          <p>The country of the delivery address.</p>
        </>
      ),
    },
    {
      name: "Delivery Postal / Zip Code",
      content: (
        <>
          <p>
            Enter the Postal/Zip Code here. For UK shipments only, a dropdown
            list will appear enabling you to select the delivery address
          </p>
        </>
      ),
    },
    {
      name: "Delivery Organisation / Name",
      content: (
        <>
          <p>The business name, or recipient of the shipment.</p>
        </>
      ),
    },
    {
      name: "Delivery Address Line 1",
      content: (
        <>
          <p>
            The address you are sending the shipment to. Address line 1, City,
            and the Postal/Zip Code are mandatory (except ROI) Maximum 35
            characters per address line.
          </p>
        </>
      ),
    },
    {
      name: "Delivery Address Line 2",
      content: (
        <>
          <p>
            The address you are sending the shipment to. Address line 1, City,
            and the Postal/Zip Code are mandatory (except ROI) Maximum 35
            characters per address line.
          </p>
        </>
      ),
    },
    {
      name: "Delivery City",
      content: (
        <>
          <p>
            The address you are sending the shipment to. Address line 1, City,
            and the Postal/Zip Code are mandatory (except ROI) Maximum 35
            characters per address line.
          </p>
        </>
      ),
    },
    {
      name: "Delivery County / State",
      content: (
        <>
          <p>
            The address you are sending the shipment to. Address line 1, City,
            and the Postal/Zip Code are mandatory (except ROI) Maximum 35
            characters per address line.
          </p>
        </>
      ),
    },
    {
      name: "Delivery Information",
      content: (
        <>
          <p>
            Example: Deliver to side entrance. You can set 5 default messages in
            the Shipping Defaults page. Then start typing (or double click in
            the field) to select one of the defaults.
          </p>
        </>
      ),
    },
  ],
  [PACKAGE_DETAILS]: [
    ...packageDetailsStartTextContent,
    ...packageServiceDetailsTextContent,
    ...packageDetailsEndTextContent,
  ],
  [PARCEL_DETAILS]: [
    ...packageDetailsStartTextContent,
    ...packageDetailsEndTextContent,
  ],
  [PACKAGE_SERVICE_DETAILS]: [
    ...packageServiceDetailsTextContent,
    ...businessDetails,
  ],
  [DELIVERY_CONTACT]: [
    {
      name: "Delivery Contact Name",
      content: (
        <>
          <p>The name of a person we can contact regarding the delivery.</p>
        </>
      ),
    },
    {
      name: "Delivery Contact Telephone",
      content: (
        <>
          <p>
            The telephone number of a person we can contact regarding the
            delivery.
          </p>
        </>
      ),
    },
    {
      name: "Delivery Notification Email",
      content: (
        <>
          <p>
            Enter the recipients email address to receive tracking notifications
            including their 1 hour delivery timeslot.
          </p>
        </>
      ),
    },
    {
      name: "Delivery Mobile Notification Text",
      content: (
        <>
          <p>
            Enter the recipient&apos;s mobile number to receive SMS tracking
            notifications including their 1 hour delivery timeslot.
          </p>
        </>
      ),
    },
  ],
  [RETURN_DETAILS]: [
    {
      name: "Return Short Name",
      content: (
        <>
          <p>
            A unique short name that is used to search for, or identify, the
            return address.
          </p>
        </>
      ),
    },
    {
      name: "Return Destination Country",
      content: (
        <>
          <p>The country the shipment will be returned to.</p>
        </>
      ),
    },
    {
      name: "Return Postal / ZIP Code",
      content: (
        <>
          <p>
            Enter the Postal/Zip Code here. For UK shipments only, a dropdown
            list will appear enabling you to select the delivery address.
          </p>
        </>
      ),
    },
    {
      name: "Return Organisation / Name",
      content: (
        <>
          <p>Business, or individual names, for the returned shipment.</p>
        </>
      ),
    },
    {
      name: "Return Address line 1",
      content: (
        <>
          <p>
            The address you are sending the shipment to.Address line 1, City,
            and the Postal/Zip Code are mandatory (except ROI). Maximum 35
            characters per address line.
          </p>
        </>
      ),
    },
    {
      name: "Return Address line 2",
      content: (
        <>
          <p>
            The address you are sending the shipment to.Address line 1, City,
            and the Postal/Zip Code are mandatory (except ROI). Maximum 35
            characters per address line.
          </p>
        </>
      ),
    },
    {
      name: "Return City",
      content: (
        <>
          <p>
            The address you are sending the shipment to.Address line 1, City,
            and the Postal/Zip Code are mandatory (except ROI). Maximum 35
            characters per address line.
          </p>
        </>
      ),
    },
    {
      name: "Return County / State",
      content: (
        <>
          <p>
            The address you are sending the shipment to.Address line 1, City,
            and the Postal/Zip Code are mandatory (except ROI). Maximum 35
            characters per address line.
          </p>
        </>
      ),
    },
    {
      name: "Return Delivery Information",
      content: (
        <>
          <p>
            Delivery instructions for the returned shipment, Example: Deliver to
            side entrance.
          </p>
        </>
      ),
    },
  ],
  [RETURN_CONTACT]: [
    {
      name: "Return Contact Name",
      content: (
        <>
          <p>The name of a person we can contact regarding the return.</p>
        </>
      ),
    },
    {
      name: "Return Contact Telephone",
      content: (
        <>
          <p>
            The telephone number of a person we can contact regarding the
            return.
          </p>
        </>
      ),
    },
  ],
  [PACKAGE_RETURN_DETAILS]: [
    ...packageReturnDetailsStartTextContent,
    ...packageReturnServiceDetailsTextContent,
    ...packageReturnDetailsEndTextContent,
  ],
  [PARCEL_RETURN_DETAILS]: [
    ...packageReturnDetailsStartTextContent,
    ...packageReturnDetailsEndTextContent,
  ],
  [PARCEL_RETURN_SERVICE_DETAILS]: packageReturnServiceDetailsTextContent,
  [SHIPPING_INFORMATION]: [
    {
      name: "Type of Shipment ",
      content: (
        <>
          <p>
            For goods sold as a part of a commercial transaction, choose
            &lsquo;commercial&rsquo;
            <br />
            For goods shipped as a gift or samples choose &lsquo;proforma&rsquo;
          </p>
        </>
      ),
    },
    {
      name: "Reason For Export",
      content: (
        <>
          <p>Sale - for goods sold as part of a commercial transaction</p>
          <p>Gifts - For items sent as a gift</p>
        </>
      ),
    },
    {
      name: "Terms Of Delivery",
      content: (
        <>
          <p>
            Duty and Taxes Prepaid - You as the shipper can choose to pay the
            import charges on behalf of your customer. DPD will advance the
            import charges on your behalf, then following delivery, we&apos;ll
            invoice the amount to your DPD account along with an administration
            fee. Please note this option is suitable for B2C deliveries only,
            where the consumer is the importer of record in the customs
            declaration. This option is available by arrangement only, please
            contact your account manager for more information
          </p>
        </>
      ),
    },
    {
      name: "Invoice Reference",
      content: (
        <>
          <p>A custom reference number for the invoice.</p>
        </>
      ),
    },
    {
      name: "Shipping / Freight Cost",
      content: (
        <>
          <p>
            Required for the customs process for the calculation of Duties and
            Taxes, enter the cost to ship the parcel.
          </p>
          <p>
            Regardless of the reason for export this information is required.
          </p>
        </>
      ),
    },
  ],
  [PACKAGE_CONTENT]: [
    {
      name: "Package Number",
      content: (
        <>
          <p>Defines which parcel in the shipment contains this item.</p>
        </>
      ),
    },
    {
      name: "Product Code",
      content: (
        <>
          <p>Sender SKU code for the product.</p>
        </>
      ),
    },
    {
      name: "Product URL",
      content: (
        <>
          <p>Required for Russia shipments only.</p>
        </>
      ),
    },
    {
      name: "Product Type",
      content: (
        <>
          <p>
            A brief description of what type of product the item is. (This needs
            to be in English)
          </p>
        </>
      ),
    },
    {
      name: "Detailed Product Description",
      content: (
        <>
          <p>An in-depth description of the product.</p>
        </>
      ),
    },
    {
      name: "Country Of Origin",
      content: (
        <>
          <p>
            Enter here the country of manufacture, production, design or brand
            origin where the item(s) in the parcel comes from.
          </p>
        </>
      ),
    },
    {
      name: "Commodity Code",
      content: (
        <>
          <p>
            A valid 8 digit commodity code must be supplied for customs
            purposes. Our commodity code tool can help you find the correct
            code. Search for the code by entering the first 4 digits or the type
            of goods you want to send and select the correct code from the list
            presented.
          </p>
          <p>
            Only UK valid export codes are permitted and the goods must not be
            restricted or prohibited.
          </p>
          <p>
            For more support in classifying products visit{" "}
            <a href="https://www.gov.uk/trade-tariff">
              https://www.gov.uk/trade-tariff
            </a>
          </p>
          <p>
            DPD accepts no liability for any inaccuracy of a duty calculation or
            a delay or return of a parcel as a consequence of the code supplied.
          </p>
        </>
      ),
    },
    {
      name: "Product Unit Weight (Kg)",
      content: (
        <>
          <p>
            Weight of singular product. The total product weight must not exceed
            the total parcel weight. Zero &lsquo;0&rsquo; values are not
            accepted and will fail importing. Format is to 2 decimal places i.e.
            25.99
          </p>
        </>
      ),
    },
    {
      name: "Product Quantity",
      content: (
        <>
          <p>
            The quantity of the product being sent. Shipments cannot be created
            with a &lsquo;0&rsquo; value in this field.
          </p>
        </>
      ),
    },
    {
      name: "Product Unit Value",
      content: (
        <>
          <p>Enter the value of this individual item in Pound Sterling.</p>
          <p>
            The value provided must be exclusive of VAT and any customs duty
            calculation.
          </p>
          <p>
            If goods are of no commercial value, a cost or intrinsic value must
            be supplied for customs assessment purposes.
          </p>
        </>
      ),
    },
  ],
  [EXPORTER_DETAILS]: [
    {
      name: "Exporter's Organisation / Name",
      content: (
        <>
          <p>
            The customs invoice address of the business sending the shipment.
            This can be set as a default in Invoice Settings which can be found
            under My Account Details in MyDPD.
          </p>
        </>
      ),
    },
    {
      name: "Exporter's Address Line 1",
      content: (
        <>
          <p>
            The customs invoice address of the business sending the shipment.
            This can be set as a default in Invoice Settings which can be found
            under My Account Details in MyDPD.
          </p>
        </>
      ),
    },
    {
      name: "Exporter's Address Line 2",
      content: (
        <>
          <p>
            The customs invoice address of the business sending the shipment.
            This can be set as a default in Invoice Settings which can be found
            under My Account Details in MyDPD.
          </p>
        </>
      ),
    },
    {
      name: "Exporter's City",
      content: (
        <>
          <p>
            The customs invoice address of the business sending the shipment.
            This can be set as a default in Invoice Settings which can be found
            under My Account Details in MyDPD.
          </p>
        </>
      ),
    },
    {
      name: "Exporter's County / State",
      content: (
        <>
          <p>
            The customs invoice address of the business sending the shipment.
            This can be set as a default in Invoice Settings which can be found
            under My Account Details in MyDPD.
          </p>
        </>
      ),
    },
    {
      name: "Exporter's Postal / Zip Code",
      content: (
        <>
          <p>
            The customs invoice address of the business sending the shipment.
            This can be set as a default in Invoice Settings which can be found
            under My Account Details in MyDPD.
          </p>
        </>
      ),
    },
    {
      name: "Exporter's Destination Country",
      content: (
        <>
          <p>
            The customs invoice address of the business sending the shipment.
            This can be set as a default in Invoice Settings which can be found
            under My Account Details in MyDPD.
          </p>
        </>
      ),
    },
    {
      name: "Exporter's Contact Name",
      content: (
        <>
          <p>
            The customs invoice address of the business sending the shipment.
            This can be set as a default in Invoice Settings which can be found
            under My Account Details in MyDPD.
          </p>
        </>
      ),
    },
    {
      name: "Exporter's Contact Telephone",
      content: (
        <>
          <p>
            The customs invoice address of the business sending the shipment.
            This can be set as a default in Invoice Settings which can be found
            under My Account Details in MyDPD.
          </p>
        </>
      ),
    },
    {
      name: "Exporter's EORI Number",
      content: (
        <>
          <p>
            Enter your GB EORI here. The format of your EORI is GB followed by
            12 numerical digits.
          </p>
          <p>
            Please note EU EORIs cannot be accepted in this field, only GB
            registered EORIs must be entered to avoid delays or your parcel
            being returned.
          </p>
          <p>An EORI number should look like this:</p>
          <p>GB123456789000.</p>
        </>
      ),
    },
    {
      name: "Destination Tax ID / Reg Number",
      content: (
        <>
          <p>
            For EU destinations, please include your IOSS VAT identification
            number if applicable to your shipment. The format of an IOSS number
            is IM followed by 10 alphanumeric characters.
          </p>
          <p>An IOSS number should look like this:</p>
          <p>IM1234567891</p>
          <p>
            For Australia, please include your GST number if applicable to your
            shipment. The format of an IOSS number is ARN followed by 10
            numerical digits.
          </p>
          <p>An Australian GST number should look like this:</p>
          <p>ARN12345678901</p>
        </>
      ),
    },
    {
      name: "Exporter's VAT Number",
      content: (
        <>
          <p>
            Enter your GB VAT here. The format of your VAT is GB followed by 9
            numerical digits.
          </p>
          <p>
            Please note EU VAT numbers cannot be accepted in this field, only GB
            registered VAT numbers must be entered to avoid delays or your
            parcel being returned.
          </p>
          <p>A VAT number should look like this:</p>
          <p>GB123456789.</p>
        </>
      ),
    },
    {
      name: "GST/VAT Paid",
      content: (
        <>
          <p>
            This is required if the Destination Tax ID/Reg No has been
            populated.
          </p>
        </>
      ),
    },
    {
      name: "FDA Registration Number",
      content: (
        <>
          <p>
            An FDA registration number is required if food or drugs is being
            sent to the US.
          </p>
        </>
      ),
    },
  ],
  [IMPORTER_DETAILS]: [
    {
      name: "Importer's Organisation / Name",
      content: (
        <>
          <p>
            The customs invoice address of the business, or consumer, receiving
            the shipment. MyDPD will automatically populate these fields with
            the delivery address, which can be changed if the importer invoice
            details are different from the delivery address. Populating a
            different importer is not available to all countries. Please speak
            to your account manager for further information.
          </p>
        </>
      ),
    },
    {
      name: "Importer's Address Line 1",
      content: (
        <>
          <p>
            The customs invoice address of the business, or consumer, receiving
            the shipment. MyDPD will automatically populate these fields with
            the delivery address, which can be changed if the importer invoice
            details are different from the delivery address. Populating a
            different importer is not available to all countries. Please speak
            to your account manager for further information.
          </p>
        </>
      ),
    },
    {
      name: "Importer's Address Line 2",
      content: (
        <>
          <p>
            The customs invoice address of the business, or consumer, receiving
            the shipment. MyDPD will automatically populate these fields with
            the delivery address, which can be changed if the importer invoice
            details are different from the delivery address. Populating a
            different importer is not available to all countries. Please speak
            to your account manager for further information.
          </p>
        </>
      ),
    },
    {
      name: "Importer's City",
      content: (
        <>
          <p>
            The customs invoice address of the business, or consumer, receiving
            the shipment. MyDPD will automatically populate these fields with
            the delivery address, which can be changed if the importer invoice
            details are different from the delivery address. Populating a
            different importer is not available to all countries. Please speak
            to your account manager for further information.
          </p>
        </>
      ),
    },
    {
      name: "Importer's County / State",
      content: (
        <>
          <p>
            The customs invoice address of the business, or consumer, receiving
            the shipment. MyDPD will automatically populate these fields with
            the delivery address, which can be changed if the importer invoice
            details are different from the delivery address. Populating a
            different importer is not available to all countries. Please speak
            to your account manager for further information.
          </p>
        </>
      ),
    },
    {
      name: "Importer's Destination Country",
      content: (
        <>
          <p>
            The customs invoice address of the business, or consumer, receiving
            the shipment. MyDPD will automatically populate these fields with
            the delivery address, which can be changed if the importer invoice
            details are different from the delivery address. Populating a
            different importer is not available to all countries. Please speak
            to your account manager for further information.
          </p>
        </>
      ),
    },
    {
      name: "Importer's Postal / Zip code ",
      content: (
        <>
          <p>
            The customs invoice address of the business, or consumer, receiving
            the shipment. MyDPD will automatically populate these fields with
            the delivery address, which can be changed if the importer invoice
            details are different from the delivery address. Populating a
            different importer is not available to all countries. Please speak
            to your account manager for further information.
          </p>
        </>
      ),
    },
    {
      name: "Importer's Contact Name",
      content: (
        <>
          <p>
            The customs invoice address of the business, or consumer, receiving
            the shipment. MyDPD will automatically populate these fields with
            the delivery address, which can be changed if the importer invoice
            details are different from the delivery address. Populating a
            different importer is not available to all countries. Please speak
            to your account manager for further information.
          </p>
        </>
      ),
    },
    {
      name: "Importer's Contact Telephone",
      content: (
        <>
          <p>The invoice receivers contact telephone number.</p>
        </>
      ),
    },
    {
      name: "Importer's Notification Email",
      content: (
        <>
          <p>The invoice receivers contact email.</p>
        </>
      ),
    },
    {
      name: "Importer's EORI Number",
      content: (
        <>
          <p>
            For EU shipments going to a business (B2B), the business&apos; EU
            EORI number must be entered here.
          </p>
          <p>
            The format of an EU EORI is the country code of the issuing Member
            State, followed by a code or number that is unique in the Member
            State.
          </p>
          <p>
            EORI formats differ across Europe, so please check this is valid
            before sending your parcel to avoid delays or your parcel being
            returned.
          </p>
          <p>
            This information is required for Customs clearance of B2B parcels.
          </p>
        </>
      ),
    },
    {
      name: "Importer's VAT Number",
      content: (
        <>
          <p>
            For EU shipments going to a business (B2B), the business&apos; EU
            VAT number must be entered here.
          </p>
          <p>
            The format of an EU VAT is the country code of the issuing Member
            State, followed be a code or number that is unique in the Member
            State.
          </p>
          <p>
            VAT formats differ across Europe, so please check this is valid
            before sending your parcel to avoid delays or your parcel being
            returned.
          </p>
          <p>
            This information is required for Customs clearance of B2B parcels.
          </p>
        </>
      ),
    },
    {
      name: "Importer's PID Number",
      content: (
        <>
          <p>
            Some destinations require a Personal Identification Number for the
            receiver of the goods of a B2C shipment in order for Customs to
            clear the shipment.
          </p>
          <p>
            Requirements and formats vary by destination, however examples
            include a DNI or NIE for Spain, a Norwegian National Identity Number
            for Norway, an Italian Codice Fiscale or a KYC number for India.
          </p>
          <p>
            Please check if this is required before sending your parcel to avoid
            delays or your parcel being returned.
          </p>
        </>
      ),
    },
  ],
};

export const EXPORTER_UKIMS_INFO = {
  name: "Exporter UKIMS Number",
  content: (
    <>
      <p>
        The UK Internal Market Scheme (UKIMS) is an authorisation that allows
        you to declare the goods ‘not at risk’ if they are brought into Northern
        Ireland for sale or final use by end consumers in Northern Ireland, and
        they meet all other criteria to move under UKIMS as set out in the
        Government guidance.
      </p>
      <p>
        If you or the business you are sending on behalf of, holds a UKIMS
        authorisation, please enter it here.
      </p>
    </>
  ),
};

export const IMPORTER_UKIMS_INFO = {
  name: "Importer UKIMS Number",
  content: (
    <>
      <p>
        The UK Internal Market Scheme (UKIMS) is an authorisation that allows
        you to declare the goods ‘not at risk’ if they are brought into Northern
        Ireland for sale or for final use by end consumers in Northern Ireland,
        and they meet all other criteria to move under UKIMS as set out in the
        Government guidance.
      </p>
      <p>
        If the receiving business is the holder of the UKIMS authorisation for
        this movement, please enter it here.
      </p>
    </>
  ),
};

export const FDA_REGISTRATION_NUMBER = {
  name: "FDA Registration Number",
  content: (
    <>
      <p>
        An FDA registration number is required if food or drugs is being sent to
        the US.
      </p>
    </>
  ),
};

export const REPLACED_FIELDS = [
  "Destination Tax ID / Reg Number",
  "GST/VAT Paid",
  "FDA Registration Number",
];
