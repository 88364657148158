import { cloneDeep, find, get } from "lodash";

import PackageContent from "~/components/PackageContent/PackageContent";
import { ProfileCard } from "~/components/ProfileCard";
import { ShipmentEntity } from "~/constants/forms";
import withReturnDetails from "~/pages/Shipment/hocs/withReturnDetails";
import withShippingInformation from "~/pages/Shipment/hocs/withShippingInformation";

import DeliveryContact from "../../components/DeliveryContact/DeliveryContact";
import DeliveryDetails from "../../components/DeliveryDetails/DeliveryDetails";
import ShipmentDetails from "../../components/ShipmentDetails/ShipmentDetails";
import ReturnShipmentDetails from "../../components/ReturnShipmentDetails/ReturnShipmentDetails";
import PackageDetails from "../../components/PackageDetails/PackageDetails";
import ReturnContact from "../../components/ReturnContact/ReturnContact";
import ReturnDetails from "../../components/ReturnDetails/ReturnDetails";
import ReturnPackageDetails from "../../components/ReturnPackageDetails/ReturnPackageDetails";
import ShipmentReview from "../../components/ShipmentReview/ShipmentReview";
import ShippingInformation from "../../components/ShippingInformation/ShippingInformation";

import { ServiceModels, SettingsModels } from "~/models";
import { takeRight } from "lodash/array";

const insertAtSecondPlace = (data, newItem) => {
  const restSections = takeRight(data, data.length - 1);
  const updatedRestSections = restSections.map(item => ({
    ...item,
    order: ++item.order,
  }));
  return [data[0], newItem, ...updatedRestSections];
};

const insertPackageContent = views => {
  const result = cloneDeep(views);
  result.totalSteps = ++result.totalSteps;
  result.data.splice(result.data.length - 1, 0, {
    section: PackageContent,
    order: result.totalSteps - 1,
    columns: 12,
    padding: "p-0",
  });
  const lastIndex = result.data.length - 1;
  result.data[lastIndex].order = ++result.data[lastIndex].order;

  return result;
};

const insertProfileCard = views => {
  const result = cloneDeep(views);
  result.data.unshift({
    section: ProfileCard,
    columns: 12,
    padding: "p-0",
  });

  return result;
};

const insertShippingInformation = views => {
  const result = cloneDeep(views);
  const item = {
    section: ShippingInformation(withShippingInformation),
    order: 2,
    padding: "p-0",
  };
  result.totalSteps = ++result.totalSteps;
  // always insert new section at the 2-nd position
  result.data = insertAtSecondPlace(result.data, item);

  return result;
};
const insertReturnShipmentDetails = views => {
  const result = cloneDeep(views);
  const item = {
    section: ReturnShipmentDetails(withReturnDetails),
    order: 2,
    padding: " p-0",
  };
  result.totalSteps = ++result.totalSteps;
  // always insert new section at the 2-nd position
  result.data = insertAtSecondPlace(result.data, item);

  return result;
};

const getNewSections = ({
  isSwapItOrReverseItShipmentType,
  allowedFields = {},
  isHiddenProfile,
}) => {
  let views = {
    data: [
      { section: ShipmentDetails, order: 1, padding: "p-0" },
      { section: ShipmentReview, order: 2, padding: "p-0" },
    ],
    totalSteps: 2,
  };

  if (
    allowedFields.invoiceDetailsSection ||
    allowedFields.exporterImporterDetails
  ) {
    views = insertShippingInformation(views);
  }

  if (
    isSwapItOrReverseItShipmentType &&
    allowedFields[ShipmentEntity.SHIPMENT_TYPE]
  ) {
    views = insertReturnShipmentDetails(views);
  }

  if (allowedFields.productDetailsData) {
    views = insertPackageContent(views);
  }

  if (!isHiddenProfile) {
    views = insertProfileCard(views);
  }

  return views;
};

const getOldSections = ({
  generateCustomsData,
  isSwapItOrReverseItShipmentType,
  selectedService,
  allowedFields = {},
  isHiddenProfile,
}) => {
  const isNotifyRequired = ServiceModels.isFlagRequired(
    selectedService,
    "notifyRequired"
  );
  let views;

  if (generateCustomsData && !isSwapItOrReverseItShipmentType) {
    views = {
      data: [
        { section: DeliveryDetails, order: 1, padding: "pr-md-2 p-0" },
        { section: PackageDetails, order: 2, padding: "pl-md-2 p-0" },
        { section: DeliveryContact, columns: 12, order: 3, padding: "p-0" },
        {
          section: ShippingInformation(withShippingInformation),
          order: 4,
          columns: 12,
          padding: "p-0",
        },
        { section: ShipmentReview, order: 5, columns: 12, padding: "p-0" },
      ],
      totalSteps: 5,
    };
  } else if (
    !generateCustomsData &&
    isSwapItOrReverseItShipmentType &&
    allowedFields[ShipmentEntity.SHIPMENT_TYPE]
  ) {
    views = {
      data: [
        {
          section: DeliveryDetails,
          order: 1,
          padding: "pr-md-2 p-0",
        },
        {
          section: PackageDetails,
          order: 2,
          padding: "pl-md-2 p-0",
        },
        {
          section: DeliveryContact,
          columns: 12,
          order: 3,
          padding: "p-0",
        },
        {
          section: ReturnDetails(withReturnDetails),
          order: 4,
          padding: "pr-md-2 p-0",
        },
        {
          section: [
            {
              section: ReturnPackageDetails,
              order: 5,
            },
            { section: ReturnContact, order: 6 },
          ],
          order: 5,
          padding: "pl-md-2 p-0",
        },
        {
          section: ShipmentReview,
          columns: 12,
          order: 7,
          padding: "p-0",
        },
      ],
      totalSteps: 7,
    };
  } else if (
    generateCustomsData &&
    isSwapItOrReverseItShipmentType &&
    allowedFields[ShipmentEntity.SHIPMENT_TYPE]
  ) {
    views = {
      data: [
        {
          section: DeliveryDetails,
          order: 1,
          padding: "pr-md-2 p-0",
        },
        {
          section: PackageDetails,
          order: 2,
          padding: "pl-md-2 p-0",
        },
        {
          section: DeliveryContact,
          columns: 12,
          order: 3,
          padding: "p-0",
        },
        {
          section: ShippingInformation(withShippingInformation),
          columns: 12,
          order: 7,
          padding: "p-0",
        },
        {
          section: ReturnDetails(withReturnDetails),
          order: 4,
          padding: "pr-md-2 p-0",
        },
        {
          section: [
            {
              section: ReturnPackageDetails,
              order: 5,
            },
            { section: ReturnContact, order: 6 },
          ],
          order: 5,
          padding: "pl-md-2 p-0",
        },
        {
          section: ShipmentReview,
          columns: 12,
          order: 8,
          padding: "p-0",
        },
      ],
      totalSteps: 8,
    };
  } else {
    views = {
      data: [
        { section: DeliveryDetails, order: 1, padding: "pr-md-2 p-0" },
        { section: PackageDetails, order: 2, padding: "pl-md-2 p-0" },
        {
          section: DeliveryContact,
          order: 3,
          columns:
            isNotifyRequired || allowedFields.productDetailsData ? 12 : 6,
          padding:
            isNotifyRequired || allowedFields.productDetailsData
              ? "p-0"
              : "pr-md-2 p-0",
        },
        {
          section: ShipmentReview,
          order: 4,
          columns:
            isNotifyRequired || allowedFields.productDetailsData ? 12 : 6,
          padding:
            isNotifyRequired || allowedFields.productDetailsData
              ? "p-0"
              : "pl-md-2 p-0",
        },
      ],
      totalSteps: 4,
    };
  }

  if (allowedFields.productDetailsData) {
    views = insertPackageContent(views);
  }

  if (!isHiddenProfile) {
    views = insertProfileCard(views);
  }

  return views;
};

export const getSections = ({
  generateCustomsData,
  isSwapItOrReverseItShipmentType,
  selectedService,
  allowedFields = {},
  isHiddenProfile,
  customer,
}) =>
  SettingsModels.isNewVersion(customer.shippingVersion)
    ? getNewSections({
        isSwapItOrReverseItShipmentType,
        allowedFields,
        isHiddenProfile,
      })
    : getOldSections({
        generateCustomsData,
        isSwapItOrReverseItShipmentType,
        selectedService,
        allowedFields,
        isHiddenProfile,
      });

export const getPackageContentOrder = props => {
  const sections = get(getSections(props), "data");
  const packageContentSection = find(sections, { section: PackageContent });

  return get(packageContentSection, "order");
};
