import { useCallback, useEffect, useRef } from "react";

import { isEmpty, startsWith } from "lodash";
import PropTypes from "prop-types";

import { FormControl } from "@dpdgroupuk/mydpd-ui";

import { GB, UNITED_KINGDOM } from "~/constants/strings";

const CountriesAutocomplete = ({
  id,
  label,
  helperText,
  onSelectionChange,
  countries = [],
  input,
  required,
  disabled,
  selectedCountry,
  meta,
  onCountryChange,
}) => {
  const countryRef = useRef(selectedCountry);
  const typeaheadRef = useRef(null);

  useEffect(() => {
    countryRef.current = selectedCountry;
  }, [selectedCountry]);

  const onBlur = useCallback(
    event => {
      const blurValue = event.target.value?.toUpperCase();

      input.onBlur(blurValue);

      if (isEmpty(blurValue)) {
        typeaheadRef?.current?.setState({
          text: UNITED_KINGDOM,
        });
        onCountryChange({ value: GB });
      } else {
        const firstFoundCountry = !isEmpty(blurValue)
          ? countries.find(({ label }) =>
              startsWith(label.toUpperCase(), blurValue)
            )
          : {};

        const isDifferentCountrySelected = firstFoundCountry?.value
          ? countryRef.current?.countryKey !== firstFoundCountry?.value
          : countryRef.current?.countryKey !== GB;

        if (!isEmpty(firstFoundCountry)) {
          typeaheadRef?.current?.setState({
            text: firstFoundCountry?.label?.toUpperCase(),
          });
          onCountryChange(firstFoundCountry);
        } else {
          typeaheadRef?.current?.setState({
            text: UNITED_KINGDOM,
          });
          onCountryChange({ value: GB });
        }

        if (onSelectionChange && isDifferentCountrySelected) {
          onSelectionChange(firstFoundCountry);
        }
      }
    },
    [countries, onSelectionChange, input, onCountryChange, countryRef]
  );

  const filterBy = useCallback(
    (option, autocompleteProps) =>
      startsWith(
        option.label.toUpperCase(),
        autocompleteProps?.text.toUpperCase()
      ),
    []
  );

  const handleSelectionChange = useCallback(
    selection => {
      typeaheadRef.current?.blur(selection.label);
    },
    [onBlur]
  );

  const onKeyDown = useCallback(
    event => {
      if (event.code === "Enter") {
        onBlur(event);
      }
    },
    [onBlur]
  );

  return (
    <FormControl.Autocomplete
      id={id}
      label={label}
      helperText={helperText}
      options={countries}
      labelKey={option => option.label.toUpperCase()}
      optionLabelMapper={option => option.label.toUpperCase()}
      onSelectionChange={handleSelectionChange}
      async={false}
      paginate={false}
      input={{
        ...input,
        value: selectedCountry?.countryName?.toUpperCase() || input.value,
        onBlur,
        onKeyDown,
      }}
      maxLength={35}
      type="dropdown"
      required={required}
      disabled={disabled}
      filterBy={filterBy}
      highlightOnlyResult
      meta={meta}
      normalize={value => value?.toUpperCase()}
      typeaheadRef={typeaheadRef}
    />
  );
};

CountriesAutocomplete.propTypes = {
  id: PropTypes.string,
  label: PropTypes.string,
  helperText: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  onSelectionChange: PropTypes.func,
  countries: PropTypes.array,
  input: PropTypes.object,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  selectedCountry: PropTypes.object,
  meta: PropTypes.object,
  onCountryChange: PropTypes.func,
};

export default CountriesAutocomplete;
