import { get } from "lodash";
import { getFormSyncErrors, getFormValues } from "redux-form";
import { createSelector } from "reselect";

import {
  EDIT_EXPORTER_FORM,
  ExporterDetailsEntity,
  Fields,
  ShipmentEntity,
} from "~/constants/forms";
import { InvoiceModels, ServiceModels, SettingsModels } from "~/models";
import { ReferenceSelectors, UmsSelectors } from "~/redux";
import { getValue } from "~/utils/object";

export const getEditExporterFormState = state =>
  get(state, `form.${EDIT_EXPORTER_FORM}`, {});

export const getEditExporterFormErrors = state =>
  getFormSyncErrors(EDIT_EXPORTER_FORM)(state) || {};

export const getEditExporterFormValues = state =>
  getFormValues(EDIT_EXPORTER_FORM)(state) || {};

export const getExporterSelectedCountry = createSelector(
  ReferenceSelectors.getCountries,
  getEditExporterFormValues,
  (countries, editExporterFormValues) =>
    countries.find(
      ({ countryKey }) =>
        countryKey ===
        get(editExporterFormValues, ExporterDetailsEntity.ADDRESS.COUNTRY_CODE)
    )
);

export const getExporterModalRequiredFields = selectedService =>
  createSelector(
    getEditExporterFormValues,
    getExporterSelectedCountry,
    UmsSelectors.getCustomer,
    (editExporterFormValues, selectedCountry, { shippingVersion }) => {
      const requiredFields = InvoiceModels.getExporterRequiredFields({
        formValues: editExporterFormValues,
        paths: {
          [Fields.DESTINATION_TAX_ID_REG_NO]:
            ExporterDetailsEntity.DESTINATION_TAX_ID_REG_NO,
          [Fields.COUNTRY_CODE]: ExporterDetailsEntity.ADDRESS.COUNTRY_CODE,
        },
        selectedCountry,
        selectedService,
        shippingVersion,
      });

      return {
        [ExporterDetailsEntity.ADDRESS.COUNTRY_CODE]:
          requiredFields[Fields.COUNTRY_CODE],
        [ExporterDetailsEntity.ADDRESS.POSTCODE]:
          requiredFields[Fields.POSTCODE],
        [ExporterDetailsEntity.ADDRESS.STREET]: requiredFields[Fields.STREET],
        [ExporterDetailsEntity.ADDRESS.TOWN]: requiredFields[Fields.TOWN],
        [ExporterDetailsEntity.EORI_NUMBER]: requiredFields[Fields.EORI_NUMBER],
        [ExporterDetailsEntity.FDA_NUMBER]: requiredFields[Fields.FDA_NUMBER],
        [ExporterDetailsEntity.GST_VAT_PAID]:
          requiredFields[Fields.GST_VAT_PAID],
        [ExporterDetailsEntity.DESTINATION_TAX_ID_REG_NO]:
          requiredFields[Fields.DESTINATION_TAX_ID_REG_NO],
      };
    }
  );

export const isVisibleTaxRequiredFields = (
  selectedService,
  createShipmentValues
) =>
  createSelector(
    getEditExporterFormValues,
    UmsSelectors.getCustomer,
    ({ destinationTaxId = "" }, { shippingVersion }) => {
      const { fdaRegistrationNumber, ...restFields } =
        ServiceModels.isVisibleExporterTaxRequiredFields(
          selectedService,
          destinationTaxId,
          shippingVersion
        );

      return {
        ...restFields,
        [Fields.FDA_NUMBER]:
          fdaRegistrationNumber &&
          !SettingsModels.isNewVersion(shippingVersion),
        [Fields.UKIMS_NUMBER]: InvoiceModels.isVisibleUkims(
          {
            isBusiness: getValue(
              createShipmentValues,
              ShipmentEntity.INVOICE.IMPORTER_DETAILS.IS_BUSINESS,
              false
            ),
            atRisk: getValue(
              createShipmentValues,
              ShipmentEntity.INVOICE.IMPORTER_DETAILS.AT_RISK,
              false
            ),
            countryCode: getValue(
              createShipmentValues,
              ShipmentEntity.OUTBOUND_CONSIGNMENT.DELIVERY_DETAILS.ADDRESS
                .COUNTRY_CODE,
              ""
            ),
            postcode: getValue(
              createShipmentValues,
              ShipmentEntity.OUTBOUND_CONSIGNMENT.DELIVERY_DETAILS.ADDRESS
                .POSTCODE,
              ""
            ),
          },
          shippingVersion
        ),
      };
    }
  );
