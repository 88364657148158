import { omit, reduce } from "lodash";
import find from "lodash/find";
import get from "lodash/get";
import isNull from "lodash/isNull";

import {
  ADDRESS,
  CONTACT_DETAILS,
  ExporterDetailsEntity,
  Fields,
  NOTIFICATION_DETAILS,
  ShipmentEntity,
} from "~/constants/forms";
import * as S from "~/constants/strings";
import { ShipmentModels } from "~/models/shipment";
import { separateThousandWithComma } from "~/utils/number";
import { getValue } from "~/utils/object";

export const createExporterInvoiceFirstColumn = (
  exporterDetails,
  countries,
  touchedErrorFields
) => {
  const country = countries.find(
    country => country.value === exporterDetails?.address?.countryCode
  );

  return [
    {
      name: S.CONTACT_NAME,
      value: exporterDetails?.contactDetails?.contactName,
      invalid: find(touchedErrorFields, v =>
        v.includes(`${CONTACT_DETAILS}.${Fields.CONTACT_NAME}`)
      ),
    },
    {
      name: S.ORGANISATION_OR_NAME,
      value: get(exporterDetails, `${ADDRESS}.${Fields.ORGANISATION}`),
      invalid: find(touchedErrorFields, v =>
        v.includes(`${ADDRESS}.${Fields.ORGANISATION}`)
      ),
    },
    {
      name: S.ADDRESS_LINE_1,
      value: get(exporterDetails, `${ADDRESS}.${Fields.STREET}`),
      invalid: find(touchedErrorFields, v =>
        v.includes(`${ADDRESS}.${Fields.STREET}`)
      ),
    },
    {
      name: S.ADDRESS_LINE_2,
      value: get(exporterDetails, `${ADDRESS}.${Fields.LOCALITY}`),
      invalid: find(touchedErrorFields, v =>
        v.includes(`${ADDRESS}.${Fields.LOCALITY}`)
      ),
    },
    {
      name: S.CITY,
      value: get(exporterDetails, `${ADDRESS}.${Fields.TOWN}`),
      invalid: find(touchedErrorFields, v =>
        v.includes(`${ADDRESS}.${Fields.TOWN}`)
      ),
    },
    {
      name: S.COUNTY_STATE,
      value: get(exporterDetails, `${ADDRESS}.${Fields.COUNTY}`),
      invalid: find(touchedErrorFields, v =>
        v.includes(`${ADDRESS}.${Fields.COUNTY}`)
      ),
    },
    {
      name: S.POSTCODE,
      value: get(exporterDetails, `${ADDRESS}.${Fields.POSTCODE}`),
      invalid: find(touchedErrorFields, v =>
        v.includes(`${ADDRESS}.${Fields.POSTCODE}`)
      ),
    },
    {
      name: S.COUNTRY,
      value: country && country.label,
    },
  ];
};

export const createExporterInvoiceThirdColumn = (
  exporterDetails,
  isVisibleTaxRequiredFields,
  touchedErrorFields
) => {
  const omittedFields = [ADDRESS, Fields.GST_VAT_PAID, CONTACT_DETAILS];

  if (!isVisibleTaxRequiredFields[Fields.DESTINATION_TAX_ID_REG_NO]) {
    omittedFields.push(Fields.DESTINATION_TAX_ID_REG_NO);
  }

  if (!isVisibleTaxRequiredFields[Fields.FDA_NUMBER]) {
    omittedFields.push(Fields.FDA_NUMBER);
  }

  if (!isVisibleTaxRequiredFields[Fields.UKIMS_NUMBER]) {
    omittedFields.push(Fields.UKIMS_NUMBER);
  }

  const values = {
    ...exporterDetails?.contactDetails,
    ...omit(exporterDetails, omittedFields),
  };
  const result = [];

  // NOTE: use for...in, because the method reduce changes the order of the fields
  for (let key in S.EXPORTER_MAP) {
    const value = getValue(values, key, "");

    if (value || isVisibleTaxRequiredFields[key]) {
      result.push({
        name: S.EXPORTER_MAP[key],
        value,
        invalid: !!find(touchedErrorFields, v => v.includes(key)),
      });
    }
  }

  return result;
};

export const mapExporterInvoiceDetails = (
  exporterDetails = {},
  isVisibleTaxRequiredFields,
  countries,
  touchedErrorFields
) => {
  const firstColumn = createExporterInvoiceFirstColumn(
    exporterDetails,
    countries,
    touchedErrorFields
  );

  const gstVatPaid = getValue(
    exporterDetails,
    ExporterDetailsEntity.GST_VAT_PAID,
    null
  );
  const secondColumn = isVisibleTaxRequiredFields[Fields.GST_VAT_PAID]
    ? [
        {
          name: S.GST_VAT_PAID,
          value: isNull(gstVatPaid) ? "" : S.BOOLEAN_TO_STRING[gstVatPaid],
          invalid: touchedErrorFields.includes(
            ShipmentEntity.INVOICE.EXPORTER_DETAILS.GST_VAT_PAID
          ),
        },
      ]
    : [];

  const thirdColumn = createExporterInvoiceThirdColumn(
    exporterDetails,
    isVisibleTaxRequiredFields,
    touchedErrorFields
  );

  return [firstColumn, secondColumn, thirdColumn];
};

export const createDetailsFirstColumn = (
  details,
  country,
  touchedErrorFields
) => [
  {
    name: S.ORGANISATION_OR_NAME,
    value: get(details, `${ADDRESS}.${Fields.ORGANISATION}`),
    invalid: find(touchedErrorFields, v =>
      v.includes(`${ADDRESS}.${Fields.ORGANISATION}`)
    ),
  },
  {
    name: S.ADDRESS_LINE_1,
    value: get(details, `${ADDRESS}.${Fields.STREET}`),
    invalid: find(touchedErrorFields, v =>
      v.includes(`${ADDRESS}.${Fields.STREET}`)
    ),
  },
  {
    name: S.ADDRESS_LINE_2,
    value: get(details, `${ADDRESS}.${Fields.LOCALITY}`),
    invalid: find(touchedErrorFields, v =>
      v.includes(`${ADDRESS}.${Fields.LOCALITY}`)
    ),
  },
  {
    name: S.CITY,
    value: get(details, `${ADDRESS}.${Fields.TOWN}`),
    invalid: find(touchedErrorFields, v =>
      v.includes(`${ADDRESS}.${Fields.TOWN}`)
    ),
  },
  {
    name: S.COUNTY_STATE,
    value: get(details, `${ADDRESS}.${Fields.COUNTY}`),
    invalid: find(touchedErrorFields, v =>
      v.includes(`${ADDRESS}.${Fields.COUNTY}`)
    ),
  },
  {
    name: S.POSTCODE,
    value: get(details, `${ADDRESS}.${Fields.POSTCODE}`),
    invalid: find(touchedErrorFields, v =>
      v.includes(`${ADDRESS}.${Fields.POSTCODE}`)
    ),
  },
  {
    name: S.COUNTRY,
    value: country && country.label,
    invalid: find(touchedErrorFields, v =>
      v.includes(`${ADDRESS}.${Fields.COUNTRY_CODE}`)
    ),
  },
];

export const createImportInvoiceThirdColumn = (
  importerDetails,
  hideOptions,
  isVisibleUkims,
  touchedErrorFields
) => {
  const values = {
    ...importerDetails?.contactDetails,
    ...importerDetails?.notificationDetails,
    ...(!hideOptions &&
      (!getValue(importerDetails, Fields.IS_BUSINESS)
        ? {
            [Fields.PID_NUMBER]: getValue(
              importerDetails,
              Fields.PID_NUMBER,
              ""
            ),
          }
        : {
            [Fields.EORI_NUMBER]: getValue(
              importerDetails,
              Fields.EORI_NUMBER,
              ""
            ),
            [Fields.VAT_NUMBER]: getValue(
              importerDetails,
              Fields.VAT_NUMBER,
              ""
            ),
            ...(isVisibleUkims && {
              [Fields.UKIMS_NUMBER]: getValue(
                importerDetails,
                Fields.UKIMS_NUMBER,
                ""
              ),
            }),
          })),
  };
  return reduce(
    values,
    (acc, value, key) => {
      acc.push({
        name: S.IMPORTER_MAP[key],
        value,
        invalid: !!find(touchedErrorFields, v => v.includes(key)),
      });
      return acc;
    },
    []
  );
};

export const mapImporterInvoiceDetails = (
  importerDetails,
  hideOptions,
  isVisibleUkims,
  countries,
  touchedErrorFields
) => {
  const firstColumn = createDetailsFirstColumn(
    importerDetails,
    countries.find(
      country =>
        country.value ===
        get(importerDetails, `${ADDRESS}.${Fields.COUNTRY_CODE}`)
    ),
    touchedErrorFields
  );
  const thirdColumn = createImportInvoiceThirdColumn(
    importerDetails,
    hideOptions,
    isVisibleUkims,
    touchedErrorFields
  );

  return [firstColumn, [], thirdColumn];
};

export const createDetailsThirdColumn = (
  deliveryDetails,
  touchedErrorFields,
  section
) => {
  const data = [];

  Object.entries({
    [Fields.CONTACT_NAME]: get(
      deliveryDetails,
      `${CONTACT_DETAILS}.${Fields.CONTACT_NAME}`
    ),
    [Fields.TELEPHONE]: get(
      deliveryDetails,
      `${CONTACT_DETAILS}.${Fields.TELEPHONE}`
    ),
    [Fields.EMAIL]: get(
      deliveryDetails,
      `${NOTIFICATION_DETAILS}.${Fields.EMAIL}`
    ),
    [Fields.MOBILE]: get(
      deliveryDetails,
      `${NOTIFICATION_DETAILS}.${Fields.MOBILE}`
    ),
  }).forEach(([key, value]) => {
    data.push({
      name: S.DELIVERY_CONTACT_MAP[key],
      value,
      invalid: !!find(
        touchedErrorFields,
        v => v.includes(key) && v.includes(section)
      ),
    });
  });
  return data;
};

export const createReturnDetailsThirdColumn = (
  deliveryDetails,
  touchedErrorFields,
  section
) => {
  const data = [];

  Object.entries({
    [Fields.CONTACT_NAME]: get(
      deliveryDetails,
      `${CONTACT_DETAILS}.${Fields.CONTACT_NAME}`
    ),
    [Fields.TELEPHONE]: get(
      deliveryDetails,
      `${CONTACT_DETAILS}.${Fields.TELEPHONE}`
    ),
  }).forEach(([key, value]) => {
    data.push({
      name: S.DELIVERY_CONTACT_MAP[key],
      value,
      invalid: !!find(
        touchedErrorFields,
        v => v.includes(key) && v.includes(section)
      ),
    });
  });
  return data;
};

export const mapDetails = (
  deliveryDetails,
  countries,
  deliveryInstructions,
  touchedErrorFields,
  section
) => {
  const firstColumn = createDetailsFirstColumn(
    deliveryDetails,
    countries.find(
      country =>
        country.value ===
        get(deliveryDetails, `${ADDRESS}.${Fields.COUNTRY_CODE}`)
    ),
    touchedErrorFields
  );
  const thirdColumn =
    section === "outboundConsignment"
      ? createDetailsThirdColumn(deliveryDetails, touchedErrorFields, section)
      : createReturnDetailsThirdColumn(
          deliveryDetails,
          touchedErrorFields,
          section
        );
  return [
    firstColumn,
    [
      {
        name: S.DELIVERY_INFORMATION,
        value: deliveryInstructions,
      },
    ],
    thirdColumn,
  ];
};

export const createPackageDetailsFirstColumn = (
  values,
  selectedService,
  touchedErrorFields
) => {
  const service = get(selectedService, "service.serviceDesc");
  const product = get(selectedService, "product.productDesc");

  const productService = {
    ...(product && { name: product }),
    ...(service && { value: service }),
  };

  return [
    {
      ...{
        name: S.TOTAL_PACKAGES_AND_WEIGHT,
        value:
          values.numberOfParcels &&
          values.totalWeight &&
          `${values.numberOfParcels} ${product ? ` X ${product}` : ""} - ${
            values.totalWeight
          }KG `,
        invalid:
          find(touchedErrorFields, v => v.includes(Fields.TOTAL_WEIGHT)) ||
          find(touchedErrorFields, v => v.includes(Fields.NUMBER_OF_PARCELS)),
      },
    },
    {
      ...(product || service
        ? {
            name: `${S.PRODUCT} & ${S.SERVICE}`,
            value: `${productService.name} - ${productService.value}`,
          }
        : {
            name: `${S.PRODUCT} & ${S.SERVICE}`,
            value: "",
            invalid: find(touchedErrorFields, v =>
              v.includes(Fields.NETWORK_CODE)
            ),
          }),
    },
    {
      name: S.YOUR_REFERENCE,
      value: values.shippingRef1,
      invalid: find(touchedErrorFields, v => v.includes(Fields.SHIPPING_REF_1)),
    },
    {
      value: values.shippingRef2,
      invalid: find(touchedErrorFields, v => v.includes(Fields.SHIPPING_REF_2)),
    },
    {
      value: values.shippingRef3,
      invalid: find(touchedErrorFields, v => v.includes(Fields.SHIPPING_REF_3)),
    },
    {
      name: S.SHIPMENT_DATE,
      value: values.shipmentDate,
    },
  ];
};

export const mapValuesForProductDetails = (
  packageDetails,
  products,
  shippingFreightCost,
  parcelsTotalValue
) => {
  const currencyLabel = ShipmentModels.getCurrencyLabel(
    packageDetails.currency || S.GBP
  );
  return [
    {
      name: S.TOTAL_DECLARED_PRODUCTS,
      value: packageDetails.numberOfParcels,
    },
    {
      name: S.TOTAL_PRODUCT_UNITS,
      value: products.length,
    },
    {
      name: S.TOTAL_DECLARED_VALUE,
      value: `${currencyLabel} ${separateThousandWithComma(parcelsTotalValue)}`,
    },
    {
      name: S.SHIPPING_FREIGHT_COST,
      value: `${currencyLabel} ${separateThousandWithComma(
        shippingFreightCost
      )}`,
    },
  ];
};

export const createShipmentDetailsThirdColumn = (
  shipmentType,
  allowedFields,
  packageDetails,
  products,
  shippingFreightCost,
  parcelsTotalValue,
  touchedErrorFields
) => {
  const data = [];
  allowedFields[ShipmentEntity.SHIPMENT_TYPE] &&
    data.push({
      name: S.COLLECT_DELIVERY,
      value: ShipmentModels.getShipmentTypeLabel(shipmentType),
    });
  allowedFields.productDetailsData &&
    data.push(
      ...mapValuesForProductDetails(
        packageDetails,
        products,
        shippingFreightCost,
        parcelsTotalValue,
        touchedErrorFields
      )
    );
  return data;
};

export const mapShipmentDetails = (
  packageDetails,
  selectedService,
  shipmentType,
  allowedFields,
  products,
  shippingFreightCost,
  parcelsTotalValue,
  touchedErrorFields
) => {
  const firstColumn = createPackageDetailsFirstColumn(
    packageDetails,
    selectedService,
    touchedErrorFields
  );
  const thirdColumn = createShipmentDetailsThirdColumn(
    shipmentType,
    allowedFields,
    packageDetails,
    products,
    shippingFreightCost,
    parcelsTotalValue,
    touchedErrorFields
  );

  return [
    firstColumn,
    [
      {
        name: S.EXTENDED_LIABILITY,
        value:
          S.BOOLEAN_TO_STRING[
            getValue(packageDetails, Fields.LIABILITY, false)
          ],
      },
    ],
    thirdColumn,
  ];
};

export const mapReturnPackageReview = (
  packageDetails,
  selectedService,
  touchedErrorFields
) => {
  const firstColumn = createPackageDetailsFirstColumn(
    packageDetails,
    selectedService,
    touchedErrorFields
  );

  const thirdColumn = getValue(packageDetails, Fields.DELIVERY_DESCRIPTION, "")
    ? [
        {
          name: S.ITEM_DESCRIPTION,
          value: getValue(packageDetails, Fields.DELIVERY_DESCRIPTION, ""),
        },
      ]
    : [];
  return [firstColumn, [], thirdColumn];
};
