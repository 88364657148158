import Joi from "joi";

import { joiExtension } from "@dpdgroupuk/mydpd-express-helpers/utils";

import { CONTACT_DETAILS, Fields } from "~/constants/forms";
import * as S from "~/constants/strings";
import { formatMessage } from "~/utils/string";

import {
  atRisk,
  contactName,
  countryCode,
  county,
  email,
  importerEoriNumber,
  importerTelephone,
  isBusiness,
  locality,
  organisation,
  pidNumber,
  postcode,
  street,
  town,
  ukimsNumber,
  vatNumber,
} from "./commonSchema";
import { SettingsModels } from "~/models";

const baseImporterDetails = {
  address: Joi.object({
    organisation,
    street,
    locality,
    town,
    county,
    postcode,
    countryCode: countryCode.label(S.IMPORTER_COUNTRY),
  }),
  [Fields.VAT_NUMBER]: vatNumber.label(
    formatMessage(S.$_$_NUMBER, S.IMPORTERS, S.VAT)
  ),
  [CONTACT_DETAILS]: Joi.object({
    telephone: importerTelephone,
    contactName,
    email: email.error(
      joiExtension.messages({
        "any.required": formatMessage(
          S.$_MUST_BE_ENTERED,
          S.IMPORTER_EMAIL_OR_IMPORTER_TELEPHONE
        ),
      })
    ),
  }),
  [Fields.PID_NUMBER]: pidNumber,
  [Fields.EORI_NUMBER]: importerEoriNumber,
  [Fields.IS_BUSINESS]: isBusiness,
};

const oldImporterDetails = Joi.object().keys(baseImporterDetails);

const newImporterDetails = Joi.object().keys({
  ...baseImporterDetails,
  [Fields.AT_RISK]: atRisk,
  [Fields.UKIMS_NUMBER]: ukimsNumber
    .label(formatMessage(S.$_$_NUMBER, S.IMPORTERS, S.UKIMS))
    .error(
      joiExtension.messages({
        "string.length": formatMessage(
          S.$_LENGTH_MUST_BE_$_LONG,
          formatMessage(S.$_$_NUMBER, S.IMPORTERS, S.UKIMS),
          32
        ),
      })
    ),
});

export const importerDetailsSchema = ({ shippingVersion }) =>
  SettingsModels.isNewVersion(shippingVersion)
    ? newImporterDetails
    : oldImporterDetails;
