const Edit = () => (
  <svg width="10" height="10" viewBox="0 0 16 16" fill="none">
    <mask
      id="editMask1"
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      width="16"
      height="16"
    >
      <rect width="16" height="16" fill="#C4C4C4" />
    </mask>
    <g mask="url(#editMask1)">
      <path
        d="M10.1758 2.67679L13.4018 5.88389L3.6528 15.5791L0.393015 15.9955C0.340023 16.0042 0.285701 16.0002 0.234557 15.9839C0.183413 15.9676 0.136921 15.9394 0.0989397 15.9016C0.060958 15.8639 0.0325815 15.8177 0.0161645 15.7668C-0.000252579 15.716 -0.0042367 15.662 0.0045428 15.6093L0.413283 12.3686L10.1758 2.67679ZM15.9184 2.83799L13.1552 0.101041C13.1237 0.0690549 13.0861 0.0436402 13.0447 0.0262891C13.0032 0.00893793 12.9586 0 12.9136 0C12.8686 0 12.8241 0.00893793 12.7826 0.0262891C12.7411 0.0436402 12.7036 0.0690549 12.6721 0.101041L11.1317 1.7197L14.3577 4.9268L15.9116 3.29806C15.9696 3.23493 16.0012 3.15218 16 3.06669C15.9987 2.9812 15.9647 2.89941 15.9049 2.83799H15.9184Z"
        fill="#438DED"
      />
    </g>
  </svg>
);

export default Edit;
