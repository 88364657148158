import PropTypes from "prop-types";
import { propTypes } from "redux-form";

import { Step } from "@dpdgroupuk/mydpd-ui";

import ReturnContactContent from "~/components/ReturnContactContent";
import { RETURN_CONTACT } from "~/constants/forms";
import { HELP_INFO } from "~/constants/info";
import * as S from "~/constants/strings";

const ReturnContact = props => (
  <Step
    totalSteps={props.totalSteps}
    step={props.step}
    withStepCounter
    complete={!props.pristine && props.isValidReturnContactSection}
    help={HELP_INFO[RETURN_CONTACT]}
    title={S.RETURN_CONTACT_TITLE}
    helpModalTitle={S.RETURN_CONTACT_TITLE}
  >
    <ReturnContactContent {...props} />
  </Step>
);

ReturnContact.propTypes = {
  isValidReturnContactSection: PropTypes.bool,
  ...propTypes,
};

export default ReturnContact;
