import classNames from "classnames";
import PropTypes from "prop-types";

import styles from "./KeyValueList.module.scss";
import { View } from "~/components/Icon";
import ActionLink from "~/components/ActionLink";
import { isObject } from "lodash";

const KeyValueList = ({
  data = [],
  variant,
  className,
  onActionClick,
  classes,
}) => (
  <ul
    className={classNames(
      className,
      styles.list,
      variant === "vertical" && styles.vertical,
      variant === "twoColumns" && styles.twoColumns
    )}
  >
    {data.map(({ name, value, invalid }, index) => (
      <li key={index}>
        {name && <span className={styles.name}>{name}</span>}
        {isObject(value) ? (
          <>
            {value.data.map((item, index) => (
              <span
                className={classNames(
                  styles.value,
                  !!invalid && styles.invalid
                )}
                key={index}
              >
                {item}
              </span>
            ))}
            {!!value.count && (
              <ActionLink
                text={`+${value.count} more`}
                icon={View}
                onClick={onActionClick}
                classes={classes}
              />
            )}
          </>
        ) : (
          <span
            className={classNames(styles.value, !!invalid && styles.invalid)}
          >
            {value || (invalid ? "Required" : "")}
          </span>
        )}
      </li>
    ))}
  </ul>
);

KeyValueList.propTypes = {
  className: PropTypes.string,
  variant: PropTypes.oneOf(["vertical", "twoColumns"]),
  data: PropTypes.array,
  onActionClick: PropTypes.func,
  classes: PropTypes.object,
};

export default KeyValueList;
