import set from "lodash/set";
import { createSelector } from "reselect";

import { ShipmentEntity } from "~/constants/forms";
import { ShipmentModels } from "~/models";
import { ShipmentSelectors } from "~/pages/Shipment/redux";
import { ProfilesSelectors, ReferenceSelectors, UmsSelectors } from "~/redux";

export const getDisabledFields = createSelector(
  UmsSelectors.getAdvancedCustomerConfiguration,
  ShipmentSelectors.getCommonDisabledFields,
  ({ noManualAddressEntry, reference1ReadOnly }, commonFields) => ({
    ...commonFields,
    [ShipmentEntity.OUTBOUND_CONSIGNMENT.DELIVERY_DETAILS.ADDRESS.COUNTRY_CODE]:
      noManualAddressEntry,
    [ShipmentEntity.OUTBOUND_CONSIGNMENT.DELIVERY_DETAILS.ADDRESS.POSTCODE]:
      noManualAddressEntry,
    [ShipmentEntity.OUTBOUND_CONSIGNMENT.SHIPPING_REF_1]: reference1ReadOnly,
  })
);

export const getAllowedFields = createSelector(
  ShipmentSelectors.getCustomsFieldsConfig,
  ShipmentSelectors.getShipmentRequiredFields,
  ShipmentSelectors.isVisibleDestinationTaxIdRegNo,
  ShipmentSelectors.getShipmentFormValues,
  ShipmentSelectors.isCustomsFieldsVisible,
  UmsSelectors.getSecuritySettings,
  ReferenceSelectors.getActiveOutboundService,
  ShipmentSelectors.getShipmentTypes,
  UmsSelectors.getPreferences,
  ProfilesSelectors.getActiveProfile,
  ShipmentSelectors.isShippingInformationVisible,
  UmsSelectors.getCustomer,
  (
    { deliveryDescriptionHidden },
    requiredFields,
    isAllowedDestinationTaxIdRegNo,
    formValues,
    isCustomsFieldsAllowed,
    securitySettings,
    selectedService,
    shipmentTypes,
    preferences,
    profile,
    isInvoiceDetailsSectionVisible,
    customer
  ) => {
    const allowedFields = ShipmentModels.getCommonAllowedFields(
      { deliveryDescriptionHidden },
      requiredFields,
      isAllowedDestinationTaxIdRegNo,
      formValues,
      isCustomsFieldsAllowed,
      securitySettings,
      selectedService,
      shipmentTypes,
      preferences,
      profile,
      isInvoiceDetailsSectionVisible,
      customer
    );

    set(allowedFields, "searchAddress.OUTBOUND_CONSIGNMENT", true);

    return allowedFields;
  }
);
