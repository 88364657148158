import { get, has, isEmpty, merge, reduce, set, values } from "lodash";

import * as StringUtil from "@dpdgroupuk/mydpd-app/lib/utils/string";

import {
  ADDRESS,
  CONTACT_DETAILS,
  DELIVERY_DETAILS,
  Fields,
  IMPORTER_DETAILS,
  INVOICE,
  NOTIFICATION_DETAILS,
  OUTBOUND_CONSIGNMENT,
  ShipmentEntity,
} from "~/constants/forms";
import * as S from "~/constants/strings";
import { getValue } from "~/utils/object";
import { AddressModels } from "~/models";

export const isUserAbleToAddOrEditAddressbook = advancedCustomerConfiguration =>
  !get(advancedCustomerConfiguration, "readOnlyAddressBook", false);

export const isAddressbookReadonly = (addressBook, user) => {
  if (!addressBook) return false;

  if (get(addressBook, "readOnly")) return true;

  const ownerRecord = get(addressBook, "userId");
  const uid = get(user, "userId");

  return ownerRecord !== uid;
};

const dummyAddressbook = {
  groups: [],
  isValid: true,
  isDefault: false,
  readOnly: false,
};

const replaceShipmentPathToAddressbook = field =>
  field
    .replace(`${OUTBOUND_CONSIGNMENT}.${DELIVERY_DETAILS}.`, "")
    .replace(`${OUTBOUND_CONSIGNMENT}.`, "")
    .replace(`${INVOICE}.${IMPORTER_DETAILS}.`, "")
    .replace("pidNumber", "pid");

export const getAddressBookQuery = (
  addressBook,
  formValues,
  searchAddressDetailsValues
) => {
  const isBusiness = getValue(
    formValues,
    ShipmentEntity.INVOICE.IMPORTER_DETAILS.IS_BUSINESS,
    getValue(addressBook, Fields.IS_BUSINESS, false)
  );
  const isVatNumberAvailable =
    isBusiness &&
    has(formValues, ShipmentEntity.INVOICE.IMPORTER_DETAILS.VAT_NUMBER);
  const isEoriNumberAvailable =
    isBusiness &&
    has(formValues, ShipmentEntity.INVOICE.IMPORTER_DETAILS.EORI_NUMBER);
  const isUkimsNumberAvailable =
    isBusiness &&
    has(formValues, ShipmentEntity.INVOICE.IMPORTER_DETAILS.UKIMS_NUMBER);
  const isPidNumberAvailable =
    !isBusiness &&
    has(formValues, ShipmentEntity.INVOICE.IMPORTER_DETAILS.PID_NUMBER);

  const addressBookFields = [
    ...values(ShipmentEntity.OUTBOUND_CONSIGNMENT.DELIVERY_DETAILS.ADDRESS),
    ...values(
      ShipmentEntity.OUTBOUND_CONSIGNMENT.DELIVERY_DETAILS.CONTACT_DETAILS
    ),
    ...values(
      ShipmentEntity.OUTBOUND_CONSIGNMENT.DELIVERY_DETAILS.NOTIFICATION_DETAILS
    ),
    ShipmentEntity.OUTBOUND_CONSIGNMENT.DELIVERY_INSTRUCTION,
    ShipmentEntity.OUTBOUND_CONSIGNMENT.SHIPPING_REF_1,
    ShipmentEntity.OUTBOUND_CONSIGNMENT.SHIPPING_REF_2,
    ShipmentEntity.OUTBOUND_CONSIGNMENT.SHIPPING_REF_3,
    ...(isVatNumberAvailable
      ? [ShipmentEntity.INVOICE.IMPORTER_DETAILS.VAT_NUMBER]
      : []),
    ...(isEoriNumberAvailable
      ? [ShipmentEntity.INVOICE.IMPORTER_DETAILS.EORI_NUMBER]
      : []),
    ...(isUkimsNumberAvailable
      ? [ShipmentEntity.INVOICE.IMPORTER_DETAILS.UKIMS_NUMBER]
      : []),
    ...(isPidNumberAvailable
      ? [ShipmentEntity.INVOICE.IMPORTER_DETAILS.PID_NUMBER]
      : []),
  ];

  const newRecord = reduce(
    addressBookFields,
    (acc, field) => {
      set(
        acc,
        replaceShipmentPathToAddressbook(field),
        get(formValues, field, "")
      );
      return acc;
    },
    {
      addressBookType: getValue(addressBook, Fields.ADDRESS_TYPE, "0"),
      isBusiness,
      atRisk: getValue(
        formValues,
        ShipmentEntity.INVOICE.IMPORTER_DETAILS.AT_RISK,
        false
      ),
    }
  );

  if (newRecord.address.countryCode === S.GB) {
    set(
      newRecord,
      "address.postcode",
      StringUtil.addSpaceToUKPostcode(
        getValue(newRecord, "address.postcode", "")
      )
    );
  }

  if (
    searchAddressDetailsValues.searchCriteriaField === "shortName" &&
    searchAddressDetailsValues.searchCriteriaValue
  ) {
    set(newRecord, "shortName", searchAddressDetailsValues.searchCriteriaValue);
  }

  if (!addressBook) {
    merge(newRecord, dummyAddressbook);
  }

  return newRecord;
};

export const addValuesFromAddressBook = ({
  formSection,
  profile,
  address,
  deliveryInstructions,
  contactDetails,
  notificationDetails,
  shippingRef1,
  shippingRef2,
  shippingRef3,
  addressBookId,
}) => ({
  [formSection]: {
    ...(addressBookId && { addressBookId }),
    [Fields.DELIVERY_INSTRUCTION]:
      get(profile, "defaultInformation") || deliveryInstructions,
    [DELIVERY_DETAILS]: {
      [ADDRESS]: AddressModels.getAddressField(address),
      ...(contactDetails && {
        [CONTACT_DETAILS]: {
          [Fields.CONTACT_NAME]: contactDetails?.contactName,
          [Fields.TELEPHONE]: contactDetails?.telephone,
        },
      }),
      ...(notificationDetails && {
        [NOTIFICATION_DETAILS]: {
          [Fields.EMAIL]: notificationDetails?.email,
          [Fields.MOBILE]: notificationDetails?.mobile,
        },
      }),
    },
    ...(!isEmpty(shippingRef1)
      ? {
          [Fields.SHIPPING_REF_1]: shippingRef1,
          [Fields.SHIPPING_REF_2]: shippingRef2,
          [Fields.SHIPPING_REF_3]: shippingRef3,
        }
      : {}),
  },
});
