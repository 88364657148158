import { useCallback, useEffect, useRef } from "react";

import { PropTypes } from "prop-types";
import { Col, Row } from "react-bootstrap";
import { Field, propTypes } from "redux-form";

import { FormControl } from "@dpdgroupuk/mydpd-ui";

import BaseAddressForm from "~/components/BaseAddressForm";
import {
  DELIVERY_DETAILS_SEARCH_FORM,
  Fields,
  OUTBOUND_CONSIGNMENT_UPPERCASE,
} from "~/constants/forms";
import * as S from "~/constants/strings";

const DeliveryDetailsContent = props => {
  const { autofocusCustomerRef1 } = props;

  const customerRefInputRef = useRef();
  const isScanRefAllowed = props.allowedFields[Fields.CUSTOMER_REF_1];

  // @see https://it.dpduk.live/version/customer-shipping/sprint-2.10/diag_P4UctkGGAqCIeGQ_.html?id=1672219117613
  useEffect(() => {
    if (isScanRefAllowed && autofocusCustomerRef1) {
      customerRefInputRef.current.focus();
    }
  }, [isScanRefAllowed, autofocusCustomerRef1]);

  const onKeyDown = useCallback(
    async event => {
      if (event.code === "Enter") {
        await props.onBlurScanReference(
          event,
          customerRefInputRef.current.value
        );
      }
    },
    [props.onBlurScanReference, customerRefInputRef?.current?.value]
  );

  return (
    <>
      {props.allowedFields[Fields.CUSTOMER_REF_1] && (
        <Row>
          <Col>
            <Field
              component={FormControl.Input}
              label={S.SCAN_REFERENCE}
              name={Fields.CUSTOMER_REF_1}
              helperText={S.MAX_25_CHARACTERS}
              maxLength={25}
              onBlur={props.onBlurScanReference}
              disabled={props.disabledFields[Fields.CUSTOMER_REF_1]}
              required={props.requiredFields[Fields.CUSTOMER_REF_1]}
              inputRef={customerRefInputRef}
              onKeyDown={onKeyDown}
            />
          </Col>
        </Row>
      )}
      <BaseAddressForm
        {...props}
        shipmentSection={OUTBOUND_CONSIGNMENT_UPPERCASE}
        searchForm={DELIVERY_DETAILS_SEARCH_FORM}
        addressbookType={S.ADDRESS_BOOK_TYPES.DELIVERY}
        onCountrySelectionChange={props.onOutboundCountryChange}
      />
    </>
  );
};

DeliveryDetailsContent.propTypes = {
  onOutboundCountryChange: PropTypes.func,
  ...propTypes,
};

export default DeliveryDetailsContent;
