import classNames from "classnames";
import PropTypes from "prop-types";
import { Col, Row } from "react-bootstrap";

import KeyValueList from "~/components/KeyValueList";
import ReviewCard from "~/components/ReviewCard";

const DetailsCard = ({
  title,
  action,
  className,
  data: [firstColumn, secondColumn, thirdColumn],
  onActionClick,
  classes,
}) => (
  <ReviewCard
    title={title}
    classes={{
      card: classNames(className),
    }}
    action={action}
    rounded
  >
    <Row>
      <Col md={6}>
        <KeyValueList variant="vertical" data={firstColumn} />
        <KeyValueList variant="vertical" data={secondColumn} />
      </Col>
      <Col md={6}>
        <KeyValueList
          variant="vertical"
          data={thirdColumn}
          onActionClick={onActionClick}
          classes={classes}
        />
      </Col>
    </Row>
  </ReviewCard>
);
DetailsCard.propTypes = {
  className: PropTypes.string,
  errors: PropTypes.object,
  classes: PropTypes.object,
  action: PropTypes.node,
  data: PropTypes.array,
  title: PropTypes.string,
  variant: PropTypes.string,
  onActionClick: PropTypes.func,
};

export default DetailsCard;
