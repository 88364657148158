import { combineReducers } from "redux";
import { reducer as form } from "redux-form";

import { combineAppReducer } from "@dpdgroupuk/mydpd-app";

import {
  ADD_EXTRA_LABEL_FORM,
  CREATE_SHIPMENT_FORM,
  EDIT_EXPORTER_FORM,
  EDIT_FAILED_SHIPMENT_FORM,
  EDIT_IMPORTER_FORM,
  EDIT_PRODUCT_FORM,
  EDIT_SHIPMENT_FORM,
  TOOLS_FORM,
  IMPORTS_FORM,
  SCAN_SHIPMENT_FORM,
  PRODUCT_DESCRIPTIONS_FORM,
} from "~/constants/forms";
import { ImporterReducer, ManifestReducer } from "~/features";
import { CustomsModels, LiabilityModels, ShipmentModels } from "~/models";
import { ContainerReducer } from "~/pages/Container/redux";
import { FailedImportsReducer } from "~/pages/FailedImports/redux";
import { ImportsReducer } from "~/pages/Imports/redux";
import { ScanningReducer } from "~/pages/Scanning/redux";
import { ShipmentReducer } from "~/pages/Shipment/redux";
import { ShipmentReviewReducer } from "~/pages/ShipmentReview/redux";

import { AddressBookReducer } from "./addressBook";
import { ProductBookReducer } from "./productBook";
import { ProfilesReducer } from "./profile";
import {
  floatRounding,
  roundExtraTotalWeight,
  touchFields,
  toUppercase,
  updateImporterDetails,
  updateReturnPackageDetails,
} from "./reduxForm/plugins";
import { ReferenceReducer } from "./references";
import { TemplateReducer } from "./template";
import { UmsReducer } from "./ums";
import { ToolsReducer } from "~/pages/Tools/redux";

const combinePlugins = plugins => (state, action) =>
  plugins.reduce((acc, curr) => curr(acc, action), state);

export default combineReducers({
  form: form.plugin({
    [CREATE_SHIPMENT_FORM]: combinePlugins([
      toUppercase,
      floatRounding([
        ShipmentModels.isShippingCost,
        CustomsModels.isCustomsValue,
        LiabilityModels.isLiabilityValue,
      ]),
      updateImporterDetails,
      updateReturnPackageDetails,
      touchFields,
    ]),
    [EDIT_SHIPMENT_FORM]: combinePlugins([
      toUppercase,
      floatRounding([
        ShipmentModels.isShippingCost,
        CustomsModels.isCustomsValue,
        LiabilityModels.isLiabilityValue,
      ]),
      updateImporterDetails,
      updateReturnPackageDetails,
      touchFields,
    ]),
    [EDIT_IMPORTER_FORM]: combinePlugins([toUppercase, touchFields]),
    [EDIT_EXPORTER_FORM]: combinePlugins([toUppercase, touchFields]),
    [EDIT_PRODUCT_FORM]: combinePlugins([
      touchFields,
      toUppercase,
      floatRounding([
        ShipmentModels.isProductUnitValue,
        ShipmentModels.isProductUnitWeight,
      ]),
    ]),
    [IMPORTS_FORM]: combinePlugins([touchFields]),
    [EDIT_FAILED_SHIPMENT_FORM]: combinePlugins([
      toUppercase,
      floatRounding([
        ShipmentModels.isShippingCost,
        CustomsModels.isCustomsValue,
        LiabilityModels.isLiabilityValue,
      ]),
      updateReturnPackageDetails,
      touchFields,
    ]),
    [ADD_EXTRA_LABEL_FORM]: combinePlugins([
      roundExtraTotalWeight,
      touchFields,
    ]),
    [SCAN_SHIPMENT_FORM]: combinePlugins([
      toUppercase,
      floatRounding([
        ShipmentModels.isShippingCost,
        CustomsModels.isCustomsValue,
        LiabilityModels.isLiabilityValue,
      ]),
      updateImporterDetails,
      updateReturnPackageDetails,
      touchFields,
    ]),
    [TOOLS_FORM]: combinePlugins([toUppercase, touchFields]),
    [PRODUCT_DESCRIPTIONS_FORM]: combinePlugins([toUppercase, touchFields]),
  }),
  ui: combineReducers({
    create: ShipmentReducer,
    review: ShipmentReviewReducer,
    imports: ImportsReducer,
    failedImports: FailedImportsReducer,
    scanning: ScanningReducer,
    container: ContainerReducer,
    tools: ToolsReducer,
  }),
  app: combineAppReducer({
    references: ReferenceReducer,
    addressBook: AddressBookReducer,
    productBook: ProductBookReducer,
    profiles: ProfilesReducer,
    template: TemplateReducer,
    ums: UmsReducer,
    importer: ImporterReducer,
    manifest: ManifestReducer,
  }),
});
