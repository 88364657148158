import { propTypes } from "redux-form";

import BaseAddressForm from "~/components/BaseAddressForm";
import {
  INBOUND_CONSIGNMENT_UPPERCASE,
  RETURN_DETAILS_SEARCH_FORM,
} from "~/constants/forms";

import * as S from "~/constants/strings";

const ReturnDetailsContent = props => {
  return (
    <BaseAddressForm
      {...props}
      selectedCountry={props.selectedReturnCountry}
      countries={props.returnCountries}
      shipmentSection={INBOUND_CONSIGNMENT_UPPERCASE}
      searchForm={RETURN_DETAILS_SEARCH_FORM}
      addressbookType={S.ADDRESS_BOOK_TYPES.RETURN}
    />
  );
};

ReturnDetailsContent.propTypes = {
  ...propTypes,
};

export default ReturnDetailsContent;
