import { useMemo, useState } from "react";
import { PropTypes } from "prop-types";
import { Col, Row } from "react-bootstrap";
import { compose } from "recompose";
import { propTypes } from "redux-form";

import { Step } from "@dpdgroupuk/mydpd-ui";

import ActionLink from "~/components/ActionLink";
import EditExporterModal from "~/components/EditExporterModal";
import EditImporterModal from "~/components/EditImporterModal";
import { Edit } from "~/components/Icon";
import DetailsCard from "~/components/ReviewCard/cards/DetailsCard";
import ReviewCard from "~/components/ReviewCard";
import {
  EXPORTER_DETAILS,
  IMPORTER_DETAILS,
  INVOICE,
  SHIPPING_INFORMATION,
} from "~/constants/forms";
import { FDA_REGISTRATION_NUMBER, HELP_INFO } from "~/constants/info";
import * as S from "~/constants/strings";
import { InvoiceModels, SettingsModels, ShipmentModels } from "~/models";

import styles from "./ShippingInformation.module.scss";
import { formatMessage } from "~/utils/string";
import { getValue } from "~/utils/object";
import NewInvoiceDetails from "~/components/InvoiceDetails/NewInvoiceDetails";
import OldInvoiceDetails from "~/components/InvoiceDetails/OldInvoiceDetails";

const ShippingInformation = props => {
  const {
    createShipmentValues,
    countries,
    createShipmentSyncErrors,
    selectedService,
    saveInvoiceExporterDetails,
    saveInvoiceImporterDetails,
    disabledFields,
    exporterInvoiceDataCard,
    importerInvoiceDataCard,
    selectedAddressBook,
    touchExporterFields,
    touchImporterFields,
    allowedFields,
    selectedDeliveryCountry,
    generateCustomsData,
    customer,
    onImporterTypeChange,
    customerPrefs,
    exportReasons,
    selectedCountry,
    preferences,
    profile,
    requiredFields,
    onFieldEntry,
  } = props;

  const isNewVersion = useMemo(
    () => SettingsModels.isNewVersion(customer.shippingVersion),
    [customer.shippingVersion]
  );

  const [editExporterModalOpen, setEditExporterModalOpen] = useState();
  const [editImporterModalOpen, setEditImporterModalOpen] = useState();

  const isValidInvoiceSection = useMemo(
    () => ShipmentModels.isValidInvoiceSection(createShipmentSyncErrors),
    [createShipmentSyncErrors]
  );
  const editExporterInitialValues = useMemo(
    () =>
      ShipmentModels.getInitialInvoiceExporterDetails(
        createShipmentValues,
        customer.shippingVersion
      ),
    [createShipmentValues]
  );
  const editImporterInitialValues = useMemo(
    () => ShipmentModels.getInvoiceImporterDetails(createShipmentValues),
    [createShipmentValues]
  );
  const { title, helpInfo } = useMemo(
    () =>
      isNewVersion
        ? {
            title: S.CUSTOMS_DETAILS,
            helpInfo: [
              ...HELP_INFO[SHIPPING_INFORMATION],
              FDA_REGISTRATION_NUMBER,
            ],
          }
        : {
            title: S.SHIPPING_INFORMATION_TITLE,
            helpInfo: HELP_INFO[SHIPPING_INFORMATION],
          },
    [isNewVersion]
  );
  const shippingCostLabel = useMemo(
    () =>
      formatMessage(
        S.SHIPPING_COST_$,
        getValue(createShipmentValues, "outboundConsignment.currency", S.GBP)
      ),
    [getValue(createShipmentValues, "outboundConsignment.currency", S.GBP)]
  );

  const shippingInfoDefaults = useMemo(
    () =>
      InvoiceModels.getShipmentInfoDefault({
        customerPrefs,
        exportReasons,
        selectedCountry,
        selectedService,
        preferences,
        shipment: createShipmentValues,
        profile,
      }),
    [
      customerPrefs,
      exportReasons,
      selectedCountry,
      selectedService,
      preferences,
      createShipmentValues,
      profile,
    ]
  );

  return (
    <Step
      totalSteps={props.totalSteps}
      step={props.step}
      withStepCounter
      complete={!props.pristine && isValidInvoiceSection}
      help={helpInfo}
      title={title}
      helpModalTitle={S.SHIPPING_INFORMATION_TITLE}
    >
      <Row>
        {isNewVersion ? (
          allowedFields.invoiceDetailsSection && (
            <Col md={6} className="mb-3">
              <ReviewCard title={S.INVOICE_DETAILS} rounded isTitleWithGap>
                <NewInvoiceDetails
                  shippingCostLabel={shippingCostLabel}
                  shippingInfoDefaults={shippingInfoDefaults}
                  disabledFields={disabledFields}
                  requiredFields={requiredFields}
                  onFieldEntry={onFieldEntry}
                  allowedFields={allowedFields}
                />
              </ReviewCard>
            </Col>
          )
        ) : (
          <Col md={6} className="mb-3">
            <Col className="px-0 pr-md-4">
              <OldInvoiceDetails
                shippingCostLabel={shippingCostLabel}
                shippingInfoDefaults={shippingInfoDefaults}
                disabledFields={disabledFields}
                requiredFields={requiredFields}
                onFieldEntry={onFieldEntry}
              />
            </Col>
          </Col>
        )}
        {allowedFields.exporterImporterDetails && (
          <Col
            md={isNewVersion && !allowedFields.invoiceDetailsSection ? 12 : 6}
          >
            <Row>
              <Col
                xs={
                  isNewVersion && !allowedFields.invoiceDetailsSection ? 6 : 12
                }
                className={styles.col}
                id={`${INVOICE}.${EXPORTER_DETAILS}`}
              >
                <DetailsCard
                  action={
                    allowedFields.editExporter ? (
                      <ActionLink
                        text={S.EDIT}
                        icon={Edit}
                        onClick={() => setEditExporterModalOpen(true)}
                      />
                    ) : null
                  }
                  title={S.EXPORTER_INVOICE_DETAILS}
                  data={exporterInvoiceDataCard}
                />
              </Col>
              <Col
                xs={
                  isNewVersion && !allowedFields.invoiceDetailsSection ? 6 : 12
                }
                className={styles.col}
                id={`${INVOICE}.${IMPORTER_DETAILS}`}
              >
                <DetailsCard
                  action={
                    allowedFields.editImporter ? (
                      <ActionLink
                        text={S.EDIT}
                        icon={Edit}
                        onClick={() => setEditImporterModalOpen(true)}
                      />
                    ) : null
                  }
                  title={S.IMPORTER_INVOICE_DETAILS}
                  data={importerInvoiceDataCard}
                />
              </Col>
            </Row>
          </Col>
        )}
      </Row>
      {editExporterModalOpen && (
        <EditExporterModal
          open={editExporterModalOpen}
          onHide={() => setEditExporterModalOpen(false)}
          saveInvoiceExporterDetails={saveInvoiceExporterDetails}
          editExporterInitialValues={editExporterInitialValues}
          countries={countries}
          createShipmentValues={createShipmentValues}
          shippingVersion={customer.shippingVersion}
          selectedService={selectedService}
          onLoad={touchExporterFields}
          disabled={disabledFields.invoiceDetails}
        />
      )}
      {editImporterModalOpen && (
        <EditImporterModal
          open={editImporterModalOpen}
          onHide={() => setEditImporterModalOpen(false)}
          saveInvoiceImporterDetails={saveInvoiceImporterDetails}
          onImporterTypeChange={onImporterTypeChange}
          editImporterInitialValues={editImporterInitialValues}
          countries={countries}
          createShipmentValues={createShipmentValues}
          shippingVersion={customer.shippingVersion}
          selectedService={selectedService}
          selectedAddressBook={selectedAddressBook}
          onLoad={touchImporterFields}
          selectedDeliveryCountry={selectedDeliveryCountry}
          generateCustomsData={generateCustomsData}
          disabled={disabledFields.invoiceDetails}
        />
      )}
    </Step>
  );
};

ShippingInformation.propTypes = {
  ...propTypes,
  disabledFields: PropTypes.object,
  selectedDeliveryCountry: PropTypes.object,
  customer: PropTypes.object,
  allowedFields: PropTypes.object,
  exporterInvoiceDataCard: PropTypes.array,
  importerInvoiceDataCard: PropTypes.array,
  touchExporterFields: PropTypes.func,
  touchImporterFields: PropTypes.func,
  onImporterTypeChange: PropTypes.func,
  generateCustomsData: PropTypes.bool,
};

ShippingInformation.defaultProps = {
  touchExporterFields: () => {},
  touchImporterFields: () => {},
  generateCustomsData: false,
};

export default shippingInformationHOC =>
  compose(shippingInformationHOC)(ShippingInformation);
