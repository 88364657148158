import { isEmpty, isNil } from "lodash";
import { connect } from "react-redux";
import { matchPath, Route, withRouter } from "react-router";
import { compose, lifecycle, withHandlers } from "recompose";

import { withOverlay } from "@dpdgroupuk/mydpd-ui";

import { ShipmentActions } from "~/pages/Shipment/redux";

import { ShipmentReviewActions, ShipmentReviewSelectors } from "../../redux";
import { AddressModels, SettingsModels } from "~/models";
import { AuthSelectors, UmsSelectors } from "~/redux";
import { getValue } from "~/utils/object";
import { ShipmentEntity } from "~/constants/forms";

export default compose(
  withRouter,
  withOverlay,
  connect(
    state => ({
      shipment: ShipmentReviewSelectors.getSelectedShipment(state),
      isShipmentLoading:
        ShipmentReviewSelectors.getIsSelectedShipmentLoading(state),
      user: AuthSelectors.getAuthUser(state),
      customer: UmsSelectors.getCustomer(state),
    }),
    (dispatch, { history, overlay, redirectTo }) => ({
      fetchShipmentById: shipmentId =>
        dispatch(ShipmentActions.getShipmentById(shipmentId)),
      fetchNiShipmentRequiredFields: query =>
        dispatch(ShipmentReviewActions.fetchNiShipmentRequiredFields(query)),
    })
  ),
  withHandlers({
    loadFn:
      ({
        customer,
        user,
        history,
        overlay,
        redirectTo,
        fetchShipmentById,
        fetchNiShipmentRequiredFields,
      }) =>
      async shipmentId => {
        try {
          overlay.show();

          const shipment = await fetchShipmentById(shipmentId);
          const countryCode = getValue(
            shipment,
            ShipmentEntity.OUTBOUND_CONSIGNMENT.DELIVERY_DETAILS.ADDRESS
              .COUNTRY_CODE,
            ""
          );
          const postcode = getValue(
            shipment,
            ShipmentEntity.OUTBOUND_CONSIGNMENT.DELIVERY_DETAILS.ADDRESS
              .POSTCODE,
            ""
          );

          // @see: https://it.dpduk.live/it/diagram/diag_IIyyz96GAqAAhSVJ.html
          if (
            SettingsModels.isNewVersion(customer.shippingVersion) &&
            AddressModels.isNiShipment(countryCode, postcode)
          ) {
            await fetchNiShipmentRequiredFields({
              countryCode,
              postcode,
              isBusiness: getValue(
                shipment,
                ShipmentEntity.INVOICE.IMPORTER_DETAILS.IS_BUSINESS,
                false
              ),
              atRisk: getValue(
                shipment,
                ShipmentEntity.INVOICE.IMPORTER_DETAILS.AT_RISK,
                false
              ),
              businessUnit: user.businessId,
            });
          }
        } catch (e) {
          history.replace({
            pathname: redirectTo,
          });
        } finally {
          overlay.hide();
        }
      },
  }),
  lifecycle({
    componentDidMount() {
      const { history, path, loadFn } = this.props;

      const match = matchPath(history.location.pathname, {
        path,
        exact: true,
        strict: false,
      });

      loadFn(match?.params?.shipmentId);
    },
    componentDidUpdate(prevProps) {
      const { shipment, isShipmentLoading, history, redirectTo } = this.props;

      if (
        (isEmpty(shipment) || isNil(shipment)) &&
        prevProps.isShipmentLoading &&
        !isShipmentLoading
      ) {
        history.replace({
          pathname: redirectTo,
        });
      }
    },
  })
)(({ component: Component, shipment, ...rest }) => (
  <Route
    {...rest}
    render={props => <Component shipment={shipment} {...props} />}
  />
));
