import { PropTypes } from "prop-types";
import { Col, Row } from "react-bootstrap";
import { Field } from "redux-form";

import { FormControl } from "@dpdgroupuk/mydpd-ui";

import { ShipmentEntity } from "~/constants/forms";
import { invoiceTypes } from "~/constants/invoice";
import * as S from "~/constants/strings";
import { Normalizers } from "~/models";

const OldInvoiceDetails = ({
  disabledFields,
  shippingInfoDefaults,
  onFieldEntry,
  requiredFields,
  shippingCostLabel,
}) => (
  <>
    <Row>
      <Col xs={12}>
        <Field
          component={FormControl.Dropdown}
          name={ShipmentEntity.INVOICE.INVOICE_TYPE}
          label={S.INVOICE_TYPE}
          helperText
          onBlur={onFieldEntry}
          values={invoiceTypes}
          required
          textTransform={S.UPPERCASE}
          disabled={disabledFields[ShipmentEntity.INVOICE.INVOICE_TYPE]}
        />
      </Col>
    </Row>
    <Row>
      <Col xs={12}>
        <Field
          component={FormControl.Dropdown}
          name={ShipmentEntity.INVOICE.EXPORT_REASON}
          label={S.REASON_FOR_EXPORT}
          helperText
          onBlur={onFieldEntry}
          values={shippingInfoDefaults.exportReasons}
          required={requiredFields[ShipmentEntity.INVOICE.EXPORT_REASON]}
          textTransform={S.UPPERCASE}
          disabled={disabledFields[ShipmentEntity.INVOICE.EXPORT_REASON]}
        />
      </Col>
    </Row>
    <Row>
      <Col xs={12}>
        <Field
          component={FormControl.Dropdown}
          name={ShipmentEntity.INVOICE.INTERNATIONAL_BILLING_TERMS}
          label={S.INTERNATIONAL_BILLING_TERMS}
          helperText
          onBlur={onFieldEntry}
          values={shippingInfoDefaults.internationalBillings}
          required
          textTransform={S.UPPERCASE}
          disabled={
            disabledFields[ShipmentEntity.INVOICE.INTERNATIONAL_BILLING_TERMS]
          }
        />
      </Col>
    </Row>
    <Row>
      <Col xs={12}>
        <Field
          component={FormControl.Input}
          name={ShipmentEntity.INVOICE.INVOICE_REFERENCE}
          label={S.INVOICE_REFERENCE}
          helperText={S.MAX_30_CHARACTERS}
          onBlur={onFieldEntry}
          maxLength={30}
          disabled={disabledFields[ShipmentEntity.INVOICE.INVOICE_REFERENCE]}
        />
      </Col>
    </Row>
    <Row>
      <Col xs={6}>
        <Field
          component={FormControl.Input}
          name={ShipmentEntity.INVOICE.NUMBER_OF_PARCELS}
          label={S.TOTAL_PACKAGES_NUMBER}
          maxLength={2}
          normalize={Normalizers.integerNormalize}
          onBlur={onFieldEntry}
          required
          disabled={disabledFields[ShipmentEntity.INVOICE.NUMBER_OF_PARCELS]}
          helperText
        />
      </Col>
      <Col xs={6}>
        <Field
          component={FormControl.Input}
          name={ShipmentEntity.INVOICE.TOTAL_WEIGHT}
          label={S.TOTAL_WEIGHT}
          normalize={Normalizers.floatNormalize}
          maxLength={5}
          onBlur={onFieldEntry}
          required
          disabled={disabledFields[ShipmentEntity.INVOICE.TOTAL_WEIGHT]}
        />
      </Col>
    </Row>
    <Row>
      <Col xs={12}>
        <Field
          component={FormControl.Input}
          name={ShipmentEntity.INVOICE.SHIPPING_COST}
          label={shippingCostLabel}
          helperText
          onBlur={onFieldEntry}
          normalize={Normalizers.floatNormalize}
          maxLength={11}
          required={requiredFields[ShipmentEntity.INVOICE.SHIPPING_COST]}
          disabled={disabledFields[ShipmentEntity.INVOICE.SHIPPING_COST]}
        />
      </Col>
    </Row>
  </>
);

OldInvoiceDetails.propTypes = {
  shippingCostLabel: PropTypes.string,
  requiredFields: PropTypes.object,
  disabledFields: PropTypes.object,
  shippingInfoDefaults: PropTypes.object,
  isNewVersion: PropTypes.bool,
  onFieldEntry: PropTypes.func,
};

export default OldInvoiceDetails;
