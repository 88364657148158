import isEmpty from "lodash/isEmpty";
import { destroy, initialize, reset, touch } from "redux-form";

import {
  createActionTypes,
  createAsyncAction,
  createAsyncActionTypes,
} from "@dpdgroupuk/redux-action-creator";

import { shipmentApi } from "~/apis";
import {
  DELIVERY_DETAILS_SEARCH_FORM,
  RETURN_DETAILS_SEARCH_FORM,
  SCAN_SHIPMENT_FORM,
} from "~/constants/forms";
import {
  AddressBookActions,
  ProfilesSelectors,
  ReferenceActions,
  ReferenceSelectors,
  UmsSelectors,
} from "~/redux";
import { getDeepKeys } from "~/utils/object";

import { getScanShipmentInitialValues } from "./models";
import { getAddressBookErrors } from "./selectors";

export const ActionTypes = createActionTypes("SHIPMENT", {
  FETCH_BY_SCAN_REFERENCE: createAsyncActionTypes("FETCH_BY_SCAN_REFERENCE"),
  CLEAR: "CLEAR",
});

export const fetchShipmentByScanReference = createAsyncAction(
  query => shipmentApi.getShipmentByScanReference(query),
  ActionTypes.FETCH_BY_SCAN_REFERENCE
);

export const clearShipment = () => ({
  type: ActionTypes.CLEAR,
});

export const handleSubmitAddressbook =
  ({ pageConfig, notifier }) =>
  (dispatch, getState) =>
  onSuccess => {
    const errors = getAddressBookErrors(getState(), pageConfig);

    if (!isEmpty(errors)) {
      const flat = getDeepKeys(errors);
      dispatch(touch(pageConfig.formName, ...flat));
      notifier.scrollToError(flat);
    } else {
      onSuccess();
    }
  };

export const clearScanShipmentPage = customerRef1 => (dispatch, getState) => {
  const initialValues = getScanShipmentInitialValues(
    ProfilesSelectors.getActiveProfile(getState(), {
      formName: SCAN_SHIPMENT_FORM,
    }),
    ReferenceSelectors.getCountriesKeyValue(getState()),
    UmsSelectors.getPreferences(getState())
  );
  dispatch(ReferenceActions.clearServices());
  dispatch(initialize(SCAN_SHIPMENT_FORM, { ...initialValues, customerRef1 }));
  dispatch(reset(DELIVERY_DETAILS_SEARCH_FORM));
  dispatch(reset(RETURN_DETAILS_SEARCH_FORM));
  dispatch(AddressBookActions.clearAddressBook());
  dispatch(clearShipment());
};

export const unmountScanningPage = () => dispatch => {
  dispatch(clearShipment());
  dispatch(AddressBookActions.clearAddressBook());
  dispatch(ReferenceActions.clearReference());
  dispatch(destroy(DELIVERY_DETAILS_SEARCH_FORM));
  dispatch(destroy(RETURN_DETAILS_SEARCH_FORM));
};
