import { propTypes } from "redux-form";

import BaseContactForm from "~/components/BaseContactForm";
import { INBOUND_CONSIGNMENT_UPPERCASE } from "~/constants/forms";

const ReturnContactContent = props => (
  <BaseContactForm {...props} shipmentSection={INBOUND_CONSIGNMENT_UPPERCASE} />
);

ReturnContactContent.propTypes = {
  ...propTypes,
};

export default ReturnContactContent;
