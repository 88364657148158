import { AddressBookActions, ReferenceActions } from "~/redux";

import { ActionTypes } from "./actions";

export const initialState = {
  isLoadingOutboundServices: false,
  isLoadingInboundServices: false,
  shipment: {},
  isAddressBookSubmitting: false,
  selectedAddressBook: null,
  selectedReturnAddressBook: null,
  uniqueSenderRef1: null,
};

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = initialState, action) => {
  switch (action.type) {
    case ReferenceActions.ReferenceActionTypes.FETCH_OUTBOUND_SERVICES
      .REQUEST: {
      return {
        ...state,
        isLoadingOutboundServices: true,
      };
    }
    case ReferenceActions.ReferenceActionTypes.FETCH_OUTBOUND_SERVICES
      .SUCCESS: {
      return {
        ...state,
        isLoadingOutboundServices: false,
      };
    }
    case ReferenceActions.ReferenceActionTypes.FETCH_OUTBOUND_SERVICES
      .FAILURE: {
      return {
        ...state,
        isLoadingOutboundServices: false,
      };
    }

    case ReferenceActions.ReferenceActionTypes.FETCH_INBOUND_SERVICES.REQUEST: {
      return {
        ...state,
        isLoadingInboundServices: true,
      };
    }
    case ReferenceActions.ReferenceActionTypes.FETCH_INBOUND_SERVICES.SUCCESS: {
      return {
        ...state,
        isLoadingInboundServices: false,
      };
    }
    case ReferenceActions.ReferenceActionTypes.FETCH_INBOUND_SERVICES.FAILURE: {
      return {
        ...state,
        isLoadingInboundServices: false,
      };
    }
    case ReferenceActions.ReferenceActionTypes.CLEAR_SERVICES: {
      return {
        ...initialState,
        uniqueSenderRef1: state.uniqueSenderRef1,
        selectedAddressBook: state.selectedAddressBook,
        selectedReturnAddressBook: state.selectedReturnAddressBook,
      };
    }
    case ActionTypes.CREATE_SHIPMENT.SUCCESS:
    case ActionTypes.UPDATE_SHIPMENT.SUCCESS: {
      return {
        ...state,
        shipment: action.payload,
      };
    }
    case ActionTypes.SET_SELECTED_ADDRESS_BOOK: {
      return {
        ...state,
        selectedAddressBook: action.payload,
      };
    }
    case ActionTypes.CREATE_UPDATE.REQUEST: {
      return {
        ...state,
        isAddressBookSubmitting: true,
      };
    }
    case ActionTypes.CREATE_UPDATE.SUCCESS: {
      return {
        ...state,
        isAddressBookSubmitting: false,
        selectedAddressBook: action.payload,
      };
    }
    case ActionTypes.CREATE_UPDATE.FAILURE: {
      return {
        ...state,
        isAddressBookSubmitting: false,
      };
    }
    case AddressBookActions.AddressBookActionTypes.FETCH_DEFAULT_ADDRESS_BOOK
      .SUCCESS:
    case ActionTypes.SET_SELECTED_RETURN_ADDRESS_BOOK: {
      return {
        ...state,
        selectedReturnAddressBook: action.payload,
      };
    }
    case ActionTypes.GENERATE_UNIQUE_REFERENCE_1.SUCCESS: {
      return {
        ...state,
        uniqueSenderRef1: action.payload,
      };
    }
    case AddressBookActions.AddressBookActionTypes.CLEAR_ADDRESS_BOOK: {
      return {
        ...state,
        selectedAddressBook: initialState.selectedAddressBook,
        selectedReturnAddressBook: initialState.selectedReturnAddressBook,
      };
    }
    case ActionTypes.CLEAR_SELECTED_RETURN_ADDRESSBOOK: {
      return {
        ...state,
        selectedReturnAddressBook: initialState.selectedReturnAddressBook,
      };
    }
    case ActionTypes.CLEAR_SELECTED_ADDRESSBOOK: {
      return {
        ...state,
        selectedAddressBook: initialState.selectedAddressBook,
      };
    }
    case ActionTypes.CLEAR_PAGE: {
      return initialState;
    }
    default:
      return state;
  }
};
