import { useMemo } from "react";

import { PropTypes } from "prop-types";
import { propTypes } from "redux-form";

import { Step } from "@dpdgroupuk/mydpd-ui";

import DeliveryDetailsContent from "~/components/DeliveryDetailsContent";
import { DELIVERY_DETAILS } from "~/constants/forms";
import { HELP_INFO } from "~/constants/info";
import * as S from "~/constants/strings";
import { ShipmentModels } from "~/models";

const DeliveryDetails = props => {
  const { createShipmentSyncErrors } = props;

  const isValidDeliveryDetailsSection = useMemo(
    () =>
      ShipmentModels.isValidDeliveryDetailsSection(createShipmentSyncErrors),
    [createShipmentSyncErrors]
  );

  return (
    <Step
      totalSteps={props.totalSteps}
      step={props.step}
      withStepCounter
      complete={!props.pristine && isValidDeliveryDetailsSection}
      help={HELP_INFO[DELIVERY_DETAILS]}
      title={S.DELIVERY_DETAILS_TITLE}
      helpModalTitle={S.DELIVERY_DETAILS_TITLE}
    >
      <DeliveryDetailsContent {...props} />
    </Step>
  );
};

DeliveryDetails.propTypes = {
  createShipmentSyncErrors: PropTypes.object,
  totalSteps: PropTypes.number,
  step: PropTypes.number,
  ...propTypes,
};

export default DeliveryDetails;
