import get from "lodash/get";

import { createNamespace } from "~/utils/namespace";

export const CREATE_SHIPMENT_FORM = "CREATE_SHIPMENT_FORM";
export const CONTAINER_FORM = "CONTAINER_FORM";
export const EDIT_SHIPMENT_FORM = "EDIT_SHIPMENT_FORM";
export const EDIT_FAILED_SHIPMENT_FORM = "EDIT_FAILED_SHIPMENT_FORM";
export const DELIVERY_DETAILS_SEARCH_FORM = "DELIVERY_DETAILS_SEARCH_FORM";
export const PRODUCT_BOOK_SEARCH_FORM = "PRODUCT_BOOK_SEARCH_FORM";
export const RETURN_DETAILS_SEARCH_FORM = "RETURN_DETAILS_SEARCH_FORM";
export const EDIT_EXPORTER_FORM = "EDIT_EXPORTER_FORM";
export const EDIT_PRODUCT_FORM = "EDIT_PRODUCT_FORM";
export const EDIT_IMPORTER_FORM = "EDIT_IMPORTER_FORM";
export const FAILED_IMPORTS_SEARCH_FORM = "FAILED_IMPORTS_SEARCH_FORM";
export const SHIPMENT_REVIEW_SEARCH_FORM = "SHIPMENT_REVIEW_SEARCH_FORM";
export const SHIPMENT_REVIEW_CHANGE_DATE_FORM =
  "SHIPMENT_REVIEW_CHANGE_DATE_FORM";
export const SHIPMENT_REVIEW_PRINT_UNPRINTED_FORM =
  "SHIPMENT_REVIEW_PRINT_UNPRINTED_FORM";
export const IMPORTS_FORM = "IMPORTS_FORM";
export const IMPORT_TEMPLATE_FORM = "IMPORT_TEMPLATE_FORM";
export const IMPORT_ACTIVITY_TEMPLATE_NAME_FORM = "TEMPLATE_NAME_FORM";
export const GENERATE_EXPORT_FILE_FORM = "GENERATE_EXPORT_FILE_FORM";
export const TEST_IMPORT_FILE_FORM = "TEST_IMPORT_FILE_FORM";
export const SAVE_TEMPLATE_FORM = "SAVE_TEMPLATE_FORM";
export const ADD_EXTRA_LABEL_FORM = "ADD_EXTRA_LABEL_FORM";
export const SCAN_SHIPMENT_FORM = "SCAN_SHIPMENT_FORM";
export const TOOLS_FORM = "TOOLS_FORM";
export const REIMPORT_FORM = "REIMPORT_FORM";
export const END_DAY_FORM = "END_DAY_FORM";
export const PRODUCT_DESCRIPTIONS_FORM = "PRODUCT_DESCRIPTIONS_FORM";

export const COLLECTION_DETAILS = "collectionDetails";

// Form sections and Groups
export const OUTBOUND_CONSIGNMENT_UPPERCASE = "OUTBOUND_CONSIGNMENT";
export const INBOUND_CONSIGNMENT_UPPERCASE = "INBOUND_CONSIGNMENT";
export const DELIVERY_DETAILS = "deliveryDetails";
export const PICKUP_DETAILS = "pickupDetails";
export const PACKAGE_DETAILS = "packageDetails";
export const DELIVERY_CONTACT = "deliveryContact";
export const OUTBOUND_CONSIGNMENT = "outboundConsignment";
export const INBOUND_CONSIGNMENT = "inboundConsignment";
export const INVOICE = "invoice";
export const EXPORTER_DETAILS = "exporterDetails";
export const IMPORTER_DETAILS = "importerDetails";
export const RETURN_DETAILS = "returnDetails";
export const PACKAGE_RETURN_DETAILS = "packageReturnDetails";
export const PACKAGE_SERVICE_DETAILS = "packageServiceDetails";
export const PARCEL_DETAILS = "parcelDetails";
export const PARCEL_RETURN_DETAILS = "parcelReturnDetails";
export const PARCEL_RETURN_SERVICE_DETAILS = "parcelReturnServiceDetails";
export const RETURN_CONTACT = "returnContact";
export const SHIPPING_INFORMATION = "shippingInformation";
export const PACKAGE_CONTENT = "packageContent";
export const AVAILABLE_FIELDS = "availableFields";
export const FIELD_ORDER = "fieldOrder";
export const FILE_OPTIONS = "fileOptions";

// Groups
export const ADDRESS = "address";
export const NOTIFICATION_DETAILS = "notificationDetails";
export const CONTACT_DETAILS = "contactDetails";

export const Fields = {
  SHORT_NAME: "shortName",
  COUNTRY_CODE: "countryCode",
  POSTCODE: "postcode",
  ORGANISATION: "organisation",
  TOWN: "town",
  COUNTY: "county",
  STREET: "street",
  LOCALITY: "locality",
  CONTACT_NAME: "contactName",
  ADDRESS_TYPE: "addressType",
  TELEPHONE: "telephone",
  MOBILE: "mobile",
  EMAIL: "email",
  SHIPPING_REF_1: "shippingRef1",
  SHIPPING_REF_2: "shippingRef2",
  SHIPPING_REF_3: "shippingRef3",
  SHIPPING_REF_6: "shippingRef6",
  DELIVERY_INSTRUCTION: "deliveryInstructions",
  NETWORK_DESCRIPTION: "networkDescription",
  IS_DEFAULT_ADDRESS: "isDefaultAddress",
  IS_VALID: "isValid",
  READ_ONLY: "readOnly",
  VAT_NUMBER: "vatNumber",
  FDA_NUMBER: "fdaRegistrationNumber",
  TYPE: "type",
  DATE: "date",
  GROUP_NAME: "groupName",
  NETWORK_CODE: "networkCode",
  PACKAGE_NUMBER: "packageNumber",
  NUMBER_OF_PARCELS: "numberOfParcels",
  TOTAL_WEIGHT: "totalWeight",
  DISPLAY_WEIGHT: "displayWeight",
  PRODUCT: "product",
  SHIPMENT_DATE: "shipmentDate",
  REMEMBER_DATE: "rememberDate",
  LIABILITY: "liability",
  LIABILITY_VALUE: "liabilityValue",
  SHIPMENT_TYPE: "shipmentType",
  INVOICE_TYPE: "invoiceType",
  EXPORT_REASON: "exportReason",
  INTERNATIONAL_BILLING_TERMS: "termsOfDelivery",
  INVOICE_REFERENCE: "invoiceReference",
  SHIPPING_COST: "shippingCost",
  GENERATE_CUSTOMS_DATA: "generateCustomsData",
  PROFILE_CODE: "profileCode",
  EORI_NUMBER: "eoriNumber",
  DESTINATION_TAX_ID_REG_NO: "destinationTaxId",
  INVOICE_IS_DECLARED: "invoiceIsDeclared",
  PID_NUMBER: "pidNumber",
  CUSTOMS_VALUE: "customsValue",
  DELIVERY_DESCRIPTION: "deliveryDescription",
  IS_BUSINESS: "isBusiness",
  GST_VAT_PAID: "gstVatPaid",
  CURRENCY: "currency",
  PARCELS: "parcels",
  EXPORT_FILE: "exportFile",
  TEMPLATE: "template",
  SHIPMENT_EXPORT: "shipmentExport",
  SHIPMENT_EXPORT_TWO: "shipmentExportTwo",
  SHIPMENT_EXPORT_THREE: "shipmentExportThree",
  FILE_PATH: "filePath",
  ADDRESS_BOOK_ID: "addressBookId",
  TOTAL_NEW_PARCELS: "totalNewParcels",
  TOTAL_NEW_PARCELS_WEIGHT: "totalWeightOfNewParcels",
  SHIPMENT_TEMPLATE_ID: "shipmentTemplateId",
  CUSTOMER_REF_1: "customerRef1",
  IS_SCANNED: "isScanned",
  PICKUP_LOCATION_CODE: "pickupLocationCode",
  PICKUP_ORGANISATION: "pickupOrganisation",
  PICKUP_ADDRESS_LINE1: "pickupAddressLine1",
  PICKUP_ADDRESS_LINE2: "pickupAddressLine2",
  PICKUP_ADDRESS_LINE3: "pickupAddressLine3",
  PICKUP_ADDRESS_LINE4: "pickupAddressLine4",
  PICKUP_POST_CODE: "pickupPostcode",
  PRINT_ALL: "printAll",
  UKIMS_NUMBER: "ukimsNumber",
  AT_RISK: "atRisk",
  // group section
  OUTBOUND_CONSIGNMENT,
  INBOUND_CONSIGNMENT,
  NOTIFICATION_DETAILS,
  CONTACT_DETAILS,
  EXPORTER_DETAILS,
  IMPORTER_DETAILS,
  DELIVERY_DETAILS,
  COLLECTION_DETAILS,
  ADDRESS,
  INVOICE,
  PICKUP_DETAILS,
};

export const SEARCH_CRITERIA_FIELD = "searchCriteriaField";
export const SEARCH_CRITERIA_VALUE = "searchCriteriaValue";
export const SEARCH_CRITERIA = "searchCriteria";
export const SEARCH_DATE = "searchDate";
export const NUMBER_OF_LABELS = "labels";
export const LABEL_TYPE = "labelType";
export const FAILURE_DETAILS = "failureDetails";
export const STAGING_SHIPMENT_ID = "stagingShipmentId";

export const FilterFields = {
  SEARCH_CRITERIA_FIELD,
  SEARCH_CRITERIA_VALUE,
  [Fields.TYPE]: Fields.TYPE,
};

export const ShipmentReviewFilterFields = {
  SEARCH_CRITERIA,
  SEARCH_DATE,
};

export const ContainerFields = {
  NUMBER_OF_LABELS,
  LABEL_TYPE,
};

const ADDRESS_NAMESPACE = {
  COUNTRY_CODE: Fields.COUNTRY_CODE,
  POSTCODE: Fields.POSTCODE,
  ORGANISATION: Fields.ORGANISATION,
  TOWN: Fields.TOWN,
  COUNTY: Fields.COUNTY,
  STREET: Fields.STREET,
  LOCALITY: Fields.LOCALITY,
};

const CONTACT_DETAILS_NAMESPACE = {
  CONTACT_NAME: Fields.CONTACT_NAME,
  TELEPHONE: Fields.TELEPHONE,
};

const NOTIFICATION_DETAILS_NAMESPACE = {
  MOBILE: Fields.MOBILE,
  EMAIL: Fields.EMAIL,
};

export const AddressBookEntity = createNamespace(
  {
    ADDRESS: ADDRESS_NAMESPACE,
    DELIVERY_INSTRUCTION: Fields.DELIVERY_INSTRUCTION,
    CONTACT_DETAILS: CONTACT_DETAILS_NAMESPACE,
    NOTIFICATION_DETAILS: NOTIFICATION_DETAILS_NAMESPACE,
    SHIPPING_REF_1: Fields.SHIPPING_REF_1,
    SHIPPING_REF_2: Fields.SHIPPING_REF_2,
    SHIPPING_REF_3: Fields.SHIPPING_REF_3,
  },
  Fields
);

export const CollectionDetailsFieldsPaths = {
  POSTCODE: `${COLLECTION_DETAILS}.${AddressBookEntity.ADDRESS.POSTCODE}`,
  COUNTRY_CODE: `${COLLECTION_DETAILS}.${AddressBookEntity.ADDRESS.COUNTRY_CODE}`,
};

export const FilterOptionsList = [
  {
    value: Fields.SHORT_NAME,
    label: "Short Name",
  },
  {
    value: Fields.ORGANISATION,
    label: "Organisation",
  },
  {
    value: Fields.POSTCODE,
    label: "Postcode",
  },
];

export const SEARCH_TYPE = {
  0: "shortName",
  1: "shortName",
  2: "organisation",
  3: "postcode",
};

export const ProductEntity = {
  PARCEL_ID: "parcelId",
  SHORT_NAME: "shortName",
  PRODUCT_CODE: "productCode",
  PRODUCT_DESCRIPTION: "productDescription",
  COUNTRY_OF_ORIGIN: "countryOfOrigin",
  PRODUCT_QUANTITY: "productQty",
  UNIT_WEIGHT: "unitWeight",
  UNIT_VALUE: "unitValue",
  PRODUCT_TYPE: "productType",
  PRODUCT_COMPOSITION: "productComposition",
  COMMODITY_CODE: "commodityCode",
  PRODUCT_URL: "productUrl",
  CURRENCY: "currency",
};

export const ProductDescriptionsEntity = {
  PRODUCT_DESCRIPTION: "productDescription",
};

export const SearchProductOptionsList = [
  {
    value: ProductEntity.SHORT_NAME,
    label: "Short Name",
  },
  {
    value: ProductEntity.PRODUCT_CODE,
    label: "Product Code",
  },
  {
    value: ProductEntity.PRODUCT_TYPE,
    label: "Product Type",
  },
  {
    value: ProductEntity.PRODUCT_DESCRIPTION,
    label: "Product Description",
  },
  {
    value: ProductEntity.PRODUCT_COMPOSITION,
    label: "Product Composition",
  },
  {
    value: ProductEntity.COUNTRY_OF_ORIGIN,
    label: "Country",
  },
  {
    value: ProductEntity.COMMODITY_CODE,
    label: "Commodity Code",
  },
];

export const ImporterDetailsEntity = createNamespace(
  {
    VAT_NUMBER: Fields.VAT_NUMBER,
    EORI_NUMBER: Fields.EORI_NUMBER,
    PID_NUMBER: Fields.PID_NUMBER,
    AT_RISK: Fields.AT_RISK,
    UKIMS_NUMBER: Fields.UKIMS_NUMBER,
    ADDRESS: ADDRESS_NAMESPACE,
    CONTACT_DETAILS: {
      ...CONTACT_DETAILS_NAMESPACE,
      EMAIL: Fields.EMAIL,
    },
    IS_BUSINESS: Fields.IS_BUSINESS,
  },
  Fields
);

export const ExporterDetailsEntity = createNamespace(
  {
    VAT_NUMBER: Fields.VAT_NUMBER,
    FDA_NUMBER: Fields.FDA_NUMBER,
    EORI_NUMBER: Fields.EORI_NUMBER,
    UKIMS_NUMBER: Fields.UKIMS_NUMBER,
    ADDRESS: ADDRESS_NAMESPACE,
    GST_VAT_PAID: Fields.GST_VAT_PAID,
    DESTINATION_TAX_ID_REG_NO: Fields.DESTINATION_TAX_ID_REG_NO,
    CONTACT_DETAILS: CONTACT_DETAILS_NAMESPACE,
  },
  Fields
);

export const InvoiceEntity = createNamespace(
  {
    TOTAL_WEIGHT: Fields.TOTAL_WEIGHT,
    NUMBER_OF_PARCELS: Fields.NUMBER_OF_PARCELS,
    INVOICE_TYPE: Fields.INVOICE_TYPE,
    INTERNATIONAL_BILLING_TERMS: Fields.INTERNATIONAL_BILLING_TERMS,
    INVOICE_REFERENCE: Fields.INVOICE_REFERENCE,
    EXPORT_REASON: Fields.EXPORT_REASON,
    SHIPPING_COST: Fields.SHIPPING_COST,
    EXPORTER_DETAILS: ExporterDetailsEntity,
    IMPORTER_DETAILS: ImporterDetailsEntity,
  },
  Fields
);

export const ShipmentEntity = createNamespace(
  {
    IS_SCANNED: Fields.IS_SCANNED, // NOTE: ONLY USED ON SCANNING
    SHIPMENT_DATE: Fields.SHIPMENT_DATE,
    SHIPMENT_TYPE: Fields.SHIPMENT_TYPE, // NOT USED IN CREATE SHIPMENT REQUEST
    GENERATE_CUSTOMS_DATA: Fields.GENERATE_CUSTOMS_DATA,
    OUTBOUND_CONSIGNMENT: {
      DELIVERY_DETAILS: {
        ADDRESS: ADDRESS_NAMESPACE,
        CONTACT_DETAILS: CONTACT_DETAILS_NAMESPACE,
        NOTIFICATION_DETAILS: NOTIFICATION_DETAILS_NAMESPACE,
      },
      COLLECTION_DETAILS: {
        ADDRESS: ADDRESS_NAMESPACE,
        CONTACT_DETAILS: CONTACT_DETAILS_NAMESPACE,
      },
      PICKUP_DETAILS: {
        PICKUP_LOCATION_CODE: Fields.PICKUP_LOCATION_CODE,
        PICKUP_ORGANISATION: Fields.PICKUP_ORGANISATION,
        PICKUP_ADDRESS_LINE1: Fields.PICKUP_ADDRESS_LINE1,
        PICKUP_ADDRESS_LINE2: Fields.PICKUP_ADDRESS_LINE2,
        PICKUP_ADDRESS_LINE3: Fields.PICKUP_ADDRESS_LINE3,
        PICKUP_ADDRESS_LINE4: Fields.PICKUP_ADDRESS_LINE4,
        PICKUP_POST_CODE: Fields.PICKUP_POST_CODE,
      },
      DELIVERY_INSTRUCTION: Fields.DELIVERY_INSTRUCTION,
      NETWORK_DESCRIPTION: Fields.NETWORK_DESCRIPTION,
      LIABILITY: Fields.LIABILITY,
      LIABILITY_VALUE: Fields.LIABILITY_VALUE,
      NETWORK_CODE: Fields.NETWORK_CODE,
      NUMBER_OF_PARCELS: Fields.NUMBER_OF_PARCELS,
      SHIPPING_REF_1: Fields.SHIPPING_REF_1,
      SHIPPING_REF_2: Fields.SHIPPING_REF_2,
      SHIPPING_REF_3: Fields.SHIPPING_REF_3,
      SHIPPING_REF_6: Fields.SHIPPING_REF_6,
      TOTAL_WEIGHT: Fields.TOTAL_WEIGHT,
      DISPLAY_WEIGHT: Fields.DISPLAY_WEIGHT,
      PRODUCT: Fields.PRODUCT, // NOT USED IN CREATE SHIPMENT REQUEST
      CUSTOMS_VALUE: Fields.CUSTOMS_VALUE,
      DELIVERY_DESCRIPTION: Fields.DELIVERY_DESCRIPTION,
      DESTINATION_TAX_ID_REG_NO: Fields.DESTINATION_TAX_ID_REG_NO,
      PARCELS: Fields.PARCELS,
      CURRENCY: Fields.CURRENCY,
      GST_VAT_PAID: Fields.GST_VAT_PAID,
    },
    INBOUND_CONSIGNMENT: {
      DELIVERY_DETAILS: {
        ADDRESS: ADDRESS_NAMESPACE,
        CONTACT_DETAILS: CONTACT_DETAILS_NAMESPACE,
        NOTIFICATION_DETAILS: NOTIFICATION_DETAILS_NAMESPACE,
      },
      DELIVERY_INSTRUCTION: Fields.DELIVERY_INSTRUCTION,
      LIABILITY: Fields.LIABILITY,
      LIABILITY_VALUE: Fields.LIABILITY_VALUE,
      NETWORK_CODE: Fields.NETWORK_CODE,
      NUMBER_OF_PARCELS: Fields.NUMBER_OF_PARCELS,
      SHIPPING_REF_1: Fields.SHIPPING_REF_1,
      SHIPPING_REF_2: Fields.SHIPPING_REF_2,
      SHIPPING_REF_3: Fields.SHIPPING_REF_3,
      TOTAL_WEIGHT: Fields.TOTAL_WEIGHT,
      DELIVERY_DESCRIPTION: Fields.DELIVERY_DESCRIPTION,
      PRODUCT: Fields.PRODUCT, // NOT USED IN CREATE SHIPMENT REQUEST
    },
    INVOICE: InvoiceEntity,
    PROFILE_CODE: Fields.PROFILE_CODE,
  },
  Fields
);

export const TemplatesEntity = createNamespace(
  {
    DATE: Fields.DATE,
    EXPORT_FILE: Fields.EXPORT_FILE,
    TEMPLATE: Fields.TEMPLATE,
  },
  Fields
);

export const TestFileEntity = createNamespace(
  {
    FILE_PATH: Fields.FILE_PATH,
  },
  Fields
);

export const AddExtraLabelEntity = createNamespace(
  {
    TOTAL_NEW_PARCELS: Fields.TOTAL_NEW_PARCELS,
    TOTAL_NEW_PARCELS_WEIGHT: Fields.TOTAL_NEW_PARCELS_WEIGHT,
  },
  Fields
);

export const OutboundServicesRelatedFields = [
  ShipmentEntity.SHIPMENT_TYPE,
  ShipmentEntity.OUTBOUND_CONSIGNMENT.TOTAL_WEIGHT,
  ShipmentEntity.OUTBOUND_CONSIGNMENT.NUMBER_OF_PARCELS,
  ShipmentEntity.OUTBOUND_CONSIGNMENT.DELIVERY_DETAILS.ADDRESS.COUNTRY_CODE,
  ShipmentEntity.OUTBOUND_CONSIGNMENT.DELIVERY_DETAILS.ADDRESS.POSTCODE,
  ShipmentEntity.OUTBOUND_CONSIGNMENT.DELIVERY_DETAILS.ADDRESS.TOWN,
  ShipmentEntity.OUTBOUND_CONSIGNMENT.DELIVERY_DETAILS.ADDRESS.COUNTY,
];

export const InboundServicesRelatedFields = [
  ...OutboundServicesRelatedFields,
  ShipmentEntity.INBOUND_CONSIGNMENT.TOTAL_WEIGHT,
  ShipmentEntity.INBOUND_CONSIGNMENT.NUMBER_OF_PARCELS,
  ShipmentEntity.INBOUND_CONSIGNMENT.DELIVERY_DETAILS.ADDRESS.COUNTRY_CODE,
  ShipmentEntity.INBOUND_CONSIGNMENT.DELIVERY_DETAILS.ADDRESS.POSTCODE,
  ShipmentEntity.INBOUND_CONSIGNMENT.DELIVERY_DETAILS.ADDRESS.TOWN,
  ShipmentEntity.INBOUND_CONSIGNMENT.DELIVERY_DETAILS.ADDRESS.COUNTY,
];

export const outboundErrorFieldPath = {
  [Fields.NUMBER_OF_PARCELS]:
    ShipmentEntity.OUTBOUND_CONSIGNMENT.NUMBER_OF_PARCELS,
  [Fields.TOTAL_WEIGHT]: ShipmentEntity.OUTBOUND_CONSIGNMENT.TOTAL_WEIGHT,
  [`${DELIVERY_DETAILS}.${AddressBookEntity.ADDRESS.COUNTRY_CODE}`]:
    ShipmentEntity.OUTBOUND_CONSIGNMENT.DELIVERY_DETAILS.ADDRESS.COUNTRY_CODE,
  [`${DELIVERY_DETAILS}.${AddressBookEntity.ADDRESS.POSTCODE}`]:
    ShipmentEntity.OUTBOUND_CONSIGNMENT.DELIVERY_DETAILS.ADDRESS.POSTCODE,
  [`${DELIVERY_DETAILS}.${AddressBookEntity.ADDRESS.TOWN}`]:
    ShipmentEntity.OUTBOUND_CONSIGNMENT.DELIVERY_DETAILS.ADDRESS.TOWN,
  [`${DELIVERY_DETAILS}.${AddressBookEntity.ADDRESS.STREET}`]:
    ShipmentEntity.OUTBOUND_CONSIGNMENT.DELIVERY_DETAILS.ADDRESS.STREET,
  [ShipmentEntity.OUTBOUND_CONSIGNMENT.NUMBER_OF_PARCELS]:
    ShipmentEntity.OUTBOUND_CONSIGNMENT.NUMBER_OF_PARCELS,
};

export const inboundErrorFieldPath = {
  [Fields.NUMBER_OF_PARCELS]:
    ShipmentEntity.INBOUND_CONSIGNMENT.NUMBER_OF_PARCELS,
  [Fields.TOTAL_WEIGHT]: ShipmentEntity.INBOUND_CONSIGNMENT.TOTAL_WEIGHT,
  [CollectionDetailsFieldsPaths.POSTCODE]:
    ShipmentEntity.OUTBOUND_CONSIGNMENT.DELIVERY_DETAILS.ADDRESS.POSTCODE,
  [CollectionDetailsFieldsPaths.COUNTRY_CODE]:
    ShipmentEntity.OUTBOUND_CONSIGNMENT.DELIVERY_DETAILS.ADDRESS.COUNTRY_CODE,
  [`${DELIVERY_DETAILS}.${AddressBookEntity.ADDRESS.TOWN}`]:
    ShipmentEntity.INBOUND_CONSIGNMENT.DELIVERY_DETAILS.ADDRESS.TOWN,
  [`${DELIVERY_DETAILS}.${AddressBookEntity.ADDRESS.STREET}`]:
    ShipmentEntity.INBOUND_CONSIGNMENT.DELIVERY_DETAILS.ADDRESS.STREET,
};

// helpers

export const getOutboundCountry = values =>
  get(
    values,
    ShipmentEntity.OUTBOUND_CONSIGNMENT.DELIVERY_DETAILS.ADDRESS.COUNTRY_CODE,
    ""
  );
export const getInboundCountry = values =>
  get(
    values,
    ShipmentEntity.INBOUND_CONSIGNMENT.DELIVERY_DETAILS.ADDRESS.COUNTRY_CODE,
    ""
  );

export const ImportsFields = {
  DEFINITION: "definition",
  DIRECTORY: "importDir",
  EXTENSION: "fileExtension",
  DELIMITER: "fieldDelimiter",
  FILE_FIELD_DELIMITER: "fileFieldDelimiter",
  GROUP_NAME_FIELD_DELIMITER: "groupNameFieldDelimiter",
  PRODUCT_DELIMITER: "productFieldDelimiter",
  AUTO_PRINT: "autoPrint",
  PRINT_SEQUENCE: "printSequence",
  DISABLE_BAD_LABELS: "disableBadLabels",
  HEADER_LINES: "headerLines",
  FOOTER_LINES: "footerLines",
  OVERRIDE_WEIGHT: "overrideWeight",
  WEIGHT_OVERRIDE_TOGGLE: "overrideWeightToggle",
  PROFILE_CODE: "profileCode",
  DEFAULT_TEMPLATE: "defaultTemplate",
  TEMPLATES: "template",
  EXCLUDE: "excludeFields",
  INCLUDE: "includeFields",
  AUTO_IMPORT: "autoImport",
  TEMPLATE_NAME: "templateName",
  CREATE_RECEIPT: "createReceipt",
  ONE_RECEIPT_PER_SHIPMENT: "oneReceiptPerShipment",
  ONE_LINE_PER_SHIPMENT: "oneLinePerShipment",
  USE_REFERENCE_FIVE: "useReferenceFive",
  WORKING_DIR: "workingDir",
  IGNORE_HEADER: "ignoreHeader",
  EXPORT_DIRECTORY: "exportDirectory",
  EXPORT_EXTENSION: "exportExtension",
  EXPORT_FILE: "exportFile",
  EXPORT_FILE_BEHAVIOR: "exportFileBehaviour",
  TEMPLATE_FILE: "templateFile",
  FILE_IGNORE_HEADER: "fileIgnoreHeader",
  TEMPLATE_ID: "templateId",
};

export const SHIPMENT_FILE_OPTIONS = [
  ImportsFields.PRODUCT_DELIMITER,
  ImportsFields.EXTENSION,
  ImportsFields.DELIMITER,
  ImportsFields.WORKING_DIR,
  ImportsFields.IGNORE_HEADER,
  ImportsFields.AUTO_IMPORT,
  ImportsFields.AUTO_PRINT,
  ImportsFields.HEADER_LINES,
  ImportsFields.FOOTER_LINES,
  ImportsFields.OVERRIDE_WEIGHT,
  ImportsFields.PRINT_SEQUENCE,
  ImportsFields.PROFILE_CODE,
  ImportsFields.DEFAULT_TEMPLATE,
  ImportsFields.DISABLE_BAD_LABELS,
];

export const SHIPMENT_RECEIPT_FILE_OPTIONS = [
  ImportsFields.WORKING_DIR,
  ImportsFields.FILE_FIELD_DELIMITER,
  ImportsFields.CREATE_RECEIPT,
  ImportsFields.EXTENSION,
  ImportsFields.ONE_LINE_PER_SHIPMENT,
  ImportsFields.ONE_RECEIPT_PER_SHIPMENT,
  ImportsFields.USE_REFERENCE_FIVE,
];

export const SHIPMENT_EXPORT_FILE_OPTIONS = [
  ImportsFields.EXPORT_FILE,
  ImportsFields.DELIMITER,
  ImportsFields.EXPORT_FILE_BEHAVIOR,
];

export const ADDRESSBOOK_FILE_OPTIONS = [
  ImportsFields.EXTENSION,
  ImportsFields.FILE_FIELD_DELIMITER,
  ImportsFields.FILE_IGNORE_HEADER,
  ImportsFields.GROUP_NAME_FIELD_DELIMITER,
];

export const TOOLS_FIELDS = {
  GROUP: "group",
  SHIPMENTS_PER_LINE: "shipmentsPerLine",
  WEIGHT_PER_SHIPMENT: "weightPerShipment",
  REF_2: Fields.SHIPPING_REF_2,
  REF_3: Fields.SHIPPING_REF_3,
  SHIPMENT_DATE: Fields.SHIPMENT_DATE,
  DELIVERY_INSTRUCTION: Fields.DELIVERY_INSTRUCTION,
  REQUIRED_SERVICE: "requiredService",
  PROFILE_CODE: Fields.PROFILE_CODE,
  RETURN_ADDRESS: "returnAddress",
};
