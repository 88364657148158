import { Redirect, Route } from "react-router";

import { NotFoundHandleSwitch } from "@dpdgroupuk/mydpd-app";

import Container from "~/pages/Container";
import CreateShipment from "~/pages/CreateShipment";
import FailedImports from "~/pages/FailedImports";
import EditFailedShipment from "~/pages/FailedImports/pages/EditFailedShipment";
import ImportActivity from "~/pages/ImportActivity";
import Imports from "~/pages/Imports";
import Scanning from "~/pages/Scanning";
import ShipmentReview from "~/pages/ShipmentReview";
import Tools from "~/pages/Tools";

import * as routes from "./constants";
import { isRestrictedTab } from "./model";
import ProtectedRoute from "./ProtectedRoute";
import PropTypes from "prop-types";

export const Routes = ({ setThirdMenuItems }) => (
  <NotFoundHandleSwitch>
    <Redirect exact from="/" to={routes.CREATE} />
    <ProtectedRoute
      exact
      strict
      path={routes.CREATE}
      component={CreateShipment}
      redirectTo={routes.REVIEW}
      isEnabled={settings => !isRestrictedTab(settings, routes.CREATE)}
    />
    <Route path={routes.REVIEW} component={ShipmentReview} />
    <ProtectedRoute
      exact
      strict
      path={routes.CONTAINER}
      component={Container}
      isEnabled={settings => !isRestrictedTab(settings, routes.CONTAINER)}
    />
    <Route exact strict path={routes.IMPORTS} component={Imports} />
    <Route exact path={routes.IMPORT_ACTIVITY} component={ImportActivity} />;
    <ProtectedRoute
      exact
      strict
      path={routes.FAILED_IMPORTS}
      component={FailedImports}
      redirectTo={routes.REVIEW}
      isEnabled={settings => !isRestrictedTab(settings, routes.FAILED_IMPORTS)}
    />
    <ProtectedRoute
      exact
      strict
      path={routes.FAILED_IMPORT_EDIT}
      component={EditFailedShipment}
      redirectTo={routes.REVIEW}
      isEnabled={settings =>
        !isRestrictedTab(settings, routes.FAILED_IMPORT_EDIT)
      }
    />
    <ProtectedRoute
      exact
      strict
      path={routes.SCANNING}
      component={Scanning}
      isEnabled={settings => !isRestrictedTab(settings, routes.SCANNING)}
    />
    <ProtectedRoute
      path={routes.TOOLS}
      component={Tools}
      setThirdMenuItems={setThirdMenuItems}
      isEnabled={settings => !isRestrictedTab(settings, routes.TOOLS)}
    />
  </NotFoundHandleSwitch>
);

Routes.defaultProps = {
  setThirdMenuItems: () => null,
};

Routes.propTypes = {
  setThirdMenuItems: PropTypes.func,
};
