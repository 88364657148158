import React from "react";

import PropTypes from "prop-types";
import * as S from "~/constants/strings";
import Modal from "../../../../components/ShipmentReviewModal";
import { Col, Container, Row } from "react-bootstrap";
import ProductDescriptionsListItem from "~/components/ProductDescriptionsListItem";
import styles from "./ProductDescriptionModal.module.scss";

const ProductDescriptionModal = ({ open, onSave, data }) => (
  <Modal
    open={open}
    title={`${S.PRODUCT_DESCRIPTION}s (${data.length})`}
    saveButton={{ visible: true, text: S.CLOSE }}
    cancelButton={{ visible: false, text: "" }}
    onSave={onSave}
    size="md"
  >
    <Container className={styles.modalContainer}>
      <Row>
        {data.map((value, index) => (
          <Col key={index} xs={12}>
            <ProductDescriptionsListItem
              sequenceNumber={index + 1}
              text={value}
            />
          </Col>
        ))}
      </Row>
    </Container>
  </Modal>
);

ProductDescriptionModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onSave: PropTypes.func,
  data: PropTypes.array,
};

ProductDescriptionModal.defaultProps = {
  data: [],
};

export default ProductDescriptionModal;
