import { isEmpty } from "lodash-es";
import { connect } from "react-redux";
import { compose, withHandlers } from "recompose";

import { withPrinter } from "@dpdgroupuk/mydpd-app";
import { PRINTER_TYPE } from "@dpdgroupuk/mydpd-enums";
import { withSnackbar } from "@dpdgroupuk/mydpd-ui";

import { CREATE_SHIPMENT_FORM, InvoiceEntity } from "~/constants/forms";
import * as S from "~/constants/strings";
import { withReceipt } from "~/features";
import { TemplateSelectors, UmsSelectors } from "~/redux";
import { ignoreCatch } from "~/utils/async";
import { formatISODate } from "~/utils/date";

import { ShipmentActions } from "../pages/Shipment/redux";
import { getValue } from "~/utils/object";

export default compose(
  withSnackbar,
  withPrinter,
  withReceipt,
  connect(
    state => ({
      currentPrinterType: UmsSelectors.getPrinterType(state),
      shipmentReceiptTemplate:
        TemplateSelectors.getShipmentReceiptTemplate(state),
    }),
    dispatch => ({
      fetchLabel: (shipmentId, params) =>
        dispatch(ShipmentActions.fetchLabel(shipmentId, params)),
      fetchMultipleLabels: query =>
        dispatch(ShipmentActions.fetchLabels(query)),
      fetchInvoiceLabel: (shipmentId, params) =>
        dispatch(ShipmentActions.fetchInvoiceLabel(shipmentId, params)),
      fetchUnprintedLabels: params =>
        dispatch(ShipmentActions.fetchUnprintedLabels(params)),
    })
  ),
  withHandlers(() => {
    const handlers = {
      fetchLabels:
        ({ fetchMultipleLabels, currentPrinterType }) =>
        shipmentIds =>
          fetchMultipleLabels({
            shipmentIds: shipmentIds.join(","),
            printerType: currentPrinterType,
          }),
      fetchLabelsForParcels:
        ({ fetchLabel, currentPrinterType }) =>
        (shipmentId, parcelNumbers) =>
          fetchLabel(shipmentId, {
            printerType: currentPrinterType,
            parcelNumbers: parcelNumbers.join(","),
          }),
      fetchInvoices:
        ({ fetchInvoiceLabel }) =>
        shipmentIds =>
          Promise.all(
            shipmentIds.map(shipmentId => fetchInvoiceLabel(shipmentId))
          ),
      fetchReceiptByShipmentDate:
        ({ shipmentReceiptTemplate, receiptController }) =>
        async date => {
          let receipts;
          if (
            shipmentReceiptTemplate.createReceipt &&
            shipmentReceiptTemplate.oneReceiptPerShipment
          ) {
            receipts = await receiptController.getReceiptsByShipmentDate(date);
          }
          return receipts;
        },
      fetchReceiptByShipmentIds:
        ({ shipmentReceiptTemplate, receiptController, pageConfig }) =>
        async ids => {
          let receipts;
          if (
            pageConfig?.formName !== CREATE_SHIPMENT_FORM &&
            shipmentReceiptTemplate.createReceipt &&
            shipmentReceiptTemplate.oneReceiptPerShipment
          ) {
            receipts = await receiptController.getReceiptsByShipmentIds(ids);
          }
          return receipts;
        },
      printLabels:
        props =>
        async (labelsArray = [], invoices = []) => {
          if (props.currentPrinterType === PRINTER_TYPE.LASER) {
            return props.printer.printLabels([...labelsArray, ...invoices]);
          }

          await props.printer.printLabels(labelsArray);
          !!invoices.length && (await props.printer.printHtml(invoices));
        },
      printWithoutInvoice: props =>
        props.notifier.runAsync(
          async shipments => {
            const shipmentIds = shipments.map(({ shipmentId }) => shipmentId);
            // NOTE: this order is required - at first get receiptData then get labels!
            // don't propagate error to continue process @see: https://dpdgroup.slack.com/archives/G01LEMW2J6L/p1655909478256389
            const receipts = await ignoreCatch(
              handlers.fetchReceiptByShipmentIds(props)(shipmentIds)
            );
            const labels = await handlers.fetchLabels(props)(shipmentIds);
            await handlers.printLabels(props)(labels);
            receipts &&
              receipts.length &&
              (await props.receiptController.printReceipts(
                receipts,
                props.shipmentReceiptTemplate,
                props.isOneReceiptFile // TODO: where get value
              ));
          },
          { entityName: S.LABELS }
        ),
      printExtraLabelsWithoutInvoice: props =>
        props.notifier.runAsync(
          async (shipmentId, parcelNumbers) => {
            // don't propagate error to continue process @see: https://dpdgroup.slack.com/archives/G01LEMW2J6L/p1655909478256389
            const receipts = await ignoreCatch(
              handlers.fetchReceiptByShipmentIds(props)([shipmentId])
            );
            const labels = await handlers.fetchLabelsForParcels(props)(
              shipmentId,
              parcelNumbers
            );

            await handlers.printLabels(props)(labels);
            receipts &&
              receipts.length &&
              (await props.receiptController.printReceipts(
                receipts,
                props.shipmentReceiptTemplate,
                props.isOneReceiptFil // TODO: where get value
              ));
          },
          { entityName: S.LABELS }
        ),
      printWithInvoice: props =>
        props.notifier.runAsync(
          async (shipments, printInvoice) => {
            const { shipmentIds, shipmentInvoicesIds } = shipments.reduce(
              (acc, { shipmentId, invoice }) => {
                acc.shipmentIds.push(shipmentId);

                if (
                  (!isEmpty(invoice) &&
                    // TODO: confirm this case for old version
                    getValue(invoice, InvoiceEntity.INVOICE_TYPE)) ||
                  printInvoice
                ) {
                  acc.shipmentInvoicesIds.push(shipmentId);
                }

                return acc;
              },
              { shipmentIds: [], shipmentInvoicesIds: [] }
            );
            // NOTE: this order is required - at first get receiptData then get labels!
            // don't propagate error to continue process @see: https://dpdgroup.slack.com/archives/G01LEMW2J6L/p1655909478256389
            const receipts = await ignoreCatch(
              handlers.fetchReceiptByShipmentIds(props)(shipmentIds)
            );
            let labels;
            try {
              labels = await handlers.fetchLabels(props)(shipmentIds);
              let invoices = [];
              if (shipmentInvoicesIds.length) {
                invoices =
                  await handlers.fetchInvoices(props)(shipmentInvoicesIds);
              }
              await handlers.printLabels(props)(labels, invoices);
            } catch (error) {
              if (labels) {
                await handlers.printLabels(props)(labels);
              }
              throw error;
            } finally {
              receipts &&
                receipts.length &&
                (await props.receiptController.printReceipts(
                  receipts,
                  props.shipmentReceiptTemplate,
                  props.isOneReceiptFile // TODO: where get value
                ));
            }
          },
          { entityName: S.LABELS }
        ),
      printUnprintedLabels: props =>
        props.overlay.showWhile(
          props.notifier.runAsync(
            async ({ shipmentDate, printAll }) => {
              const isoShipmentDate = printAll
                ? null
                : formatISODate(shipmentDate);
              // NOTE: this order is required - at first get receiptData then get labels!
              // don't propagate error to continue process @see: https://dpdgroup.slack.com/archives/G01LEMW2J6L/p1655909478256389
              const receipts = await ignoreCatch(
                handlers.fetchReceiptByShipmentDate(props)(isoShipmentDate)
              );
              const labels = await props.fetchUnprintedLabels({
                printerType: props.currentPrinterType,
                ...(isoShipmentDate && { shipmentDate: isoShipmentDate }),
              });

              if (labels && labels.length) {
                await handlers.printLabels(props)(labels);
                receipts &&
                  receipts.length &&
                  (await props.receiptController.printReceipts(
                    receipts,
                    props.shipmentReceiptTemplate,
                    props.isOneReceiptFile // TODO: where get value
                  ));
                return true;
              } else {
                props.prompt.showInfo({
                  header: S.NO_UNPRINTED_SHIPMENTS,
                  message: S.YOU_DONT_HAVE_UNPRINTED_SHIPMENTS,
                });
                return false;
              }
            },
            { entityName: S.UNPRINTED_LABELS }
          )
        ),
      printInvoice: props =>
        props.notifier.runAsync(
          async shipment => {
            const invoices = await handlers.fetchInvoices(props)([
              shipment.shipmentId,
            ]);

            await props.printer.printHtml(invoices);
          },
          { entityName: S.INVOICE }
        ),
      printLabelsByParcelNumber: props =>
        props.notifier.runAsync(
          async (shipment, parcelNumber) => {
            const labels = await props.fetchLabel(shipment.shipmentId, {
              printerType: props.currentPrinterType,
              parcelNumbers: [parcelNumber],
            });

            await handlers.printLabels(props)(labels);
          },
          { entityName: S.LABELS }
        ),
    };

    return handlers;
  })
);
