import { useMemo } from "react";
import { PropTypes } from "prop-types";
import { Col, Row } from "react-bootstrap";
import { Field, propTypes } from "redux-form";

import { FormControl } from "@dpdgroupuk/mydpd-ui";

import ReturnPackageServiceDetailsContent from "~/components/ReturnPackageServiceDetailsContent";
import { ShipmentEntity } from "~/constants/forms";

import * as S from "~/constants/strings";
import { Normalizers, SettingsModels, ShipmentModels } from "~/models";

const ReturnPackageDetailsContent = props => {
  const { disabledFields, allowedFields, customer } = props;

  const isNewVersion = useMemo(
    () => SettingsModels.isNewVersion(customer.shippingVersion),
    [customer.shippingVersion]
  );

  return (
    <>
      {allowedFields[
        ShipmentEntity.INBOUND_CONSIGNMENT.DELIVERY_DESCRIPTION
      ] && (
        <Row>
          <Col>
            <Field
              component={FormControl.Input}
              label={S.ITEM_DESCRIPTION}
              name={ShipmentEntity.INBOUND_CONSIGNMENT.DELIVERY_DESCRIPTION}
              helperText={S.MAX_30_CHARACTERS}
              maxLength={30}
              onBlur={props.onFieldEntry}
              disabled={
                disabledFields[
                  ShipmentEntity.INBOUND_CONSIGNMENT.DELIVERY_DESCRIPTION
                ]
              }
            />
          </Col>
        </Row>
      )}
      <Row>
        <Col xs={6}>
          <Field
            maxLength={2}
            normalize={Normalizers.integerNormalize}
            component={FormControl.Input}
            label={S.TOTAL_PACKAGES_NUMBER}
            name={ShipmentEntity.INBOUND_CONSIGNMENT.NUMBER_OF_PARCELS}
            onBlur={props.onFieldEntry}
            disabled={
              disabledFields[
                ShipmentEntity.INBOUND_CONSIGNMENT.NUMBER_OF_PARCELS
              ]
            }
            required
            helperText
          />
        </Col>
        <Col xs={6}>
          <Field
            normalize={Normalizers.floatNormalize}
            maxLength={5}
            component={FormControl.Input}
            helperText={ShipmentModels.getRoundedHelperText(
              props.selectedReturnCountry
            )}
            label={S.TOTAL_WEIGHT}
            name={ShipmentEntity.INBOUND_CONSIGNMENT.TOTAL_WEIGHT}
            onBlur={props.onFieldEntry}
            disabled={
              disabledFields[ShipmentEntity.INBOUND_CONSIGNMENT.TOTAL_WEIGHT]
            }
            required
          />
        </Col>
      </Row>
      {/* Display a section here only for the old version */}
      {!isNewVersion && <ReturnPackageServiceDetailsContent {...props} />}
      <Row>
        <Col>
          <Field
            component={FormControl.Input}
            label={S.YOUR_REFERENCE_1}
            name={ShipmentEntity.INBOUND_CONSIGNMENT.SHIPPING_REF_1}
            helperText={S.MAX_25_CHARACTERS}
            maxLength={25}
            onBlur={props.onFieldEntry}
            disabled={
              disabledFields[ShipmentEntity.INBOUND_CONSIGNMENT.SHIPPING_REF_1]
            }
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <Field
            component={FormControl.Input}
            label={S.YOUR_REFERENCE_2}
            name={ShipmentEntity.INBOUND_CONSIGNMENT.SHIPPING_REF_2}
            helperText={S.MAX_25_CHARACTERS}
            maxLength={25}
            onBlur={props.onFieldEntry}
            disabled={
              disabledFields[ShipmentEntity.INBOUND_CONSIGNMENT.SHIPPING_REF_2]
            }
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <Field
            component={FormControl.Input}
            label={S.YOUR_REFERENCE_3}
            name={ShipmentEntity.INBOUND_CONSIGNMENT.SHIPPING_REF_3}
            helperText={S.MAX_25_CHARACTERS}
            maxLength={25}
            onBlur={props.onFieldEntry}
            disabled={
              disabledFields[ShipmentEntity.INBOUND_CONSIGNMENT.SHIPPING_REF_3]
            }
          />
        </Col>
      </Row>
    </>
  );
};

ReturnPackageDetailsContent.propTypes = {
  onFieldEntry: PropTypes.func,
  disabledFields: PropTypes.object,
  allowedFields: PropTypes.object,
  customer: PropTypes.object,
  ...propTypes,
};

export default ReturnPackageDetailsContent;
