import isEmpty from "lodash/isEmpty";
import isNil from "lodash/isNil";
import { connect } from "react-redux";
import { matchPath, withRouter } from "react-router";
import { compose, withHandlers, withProps, withState } from "recompose";
import { touch } from "redux-form";

import {
  UserDataSelectors,
  withAppLoader,
  withNotifier,
} from "@dpdgroupuk/mydpd-app";
import { Banner, withSnackbar } from "@dpdgroupuk/mydpd-ui";

import {
  EDIT_EXPORTER_FORM,
  EDIT_FAILED_SHIPMENT_FORM,
  EDIT_IMPORTER_FORM,
  ExporterDetailsEntity,
  ImporterDetailsEntity,
  ShipmentEntity,
} from "~/constants/forms";
import * as S from "~/constants/strings";
import { ShipmentModels } from "~/models";
import withConsignmentNumberBanner from "~/pages/Shipment/hocs/withConsignmentNumberBanner";
import withEditFailedImportLoad from "~/pages/Shipment/hocs/withEditFailedImportLoad";
import withEditFailedImportPrint from "~/pages/Shipment/hocs/withEditFailedImportPrint";
import withShipmentAsyncValidation from "~/pages/Shipment/hocs/withShipmentAsyncValidation";
import withShipmentData from "~/pages/Shipment/hocs/withShipmentData";
import { ShipmentSelectors } from "~/pages/Shipment/redux";
import Shipment from "~/pages/Shipment/Shipment";
import { ReferenceActions } from "~/redux";
import * as routes from "~/router/constants";
import { flattenEntityRoutes, getValue } from "~/utils/object";
import { initializeForm } from "~/utils/reduxForm";

import { fetchFailedShipmentById } from "../../redux/actions";
import {
  getIsSelectedFailedShipmentLoading,
  getSelectedFailedShipment,
} from "../../redux/selectors";
import { getAllowedFields, getDisabledFields } from "./selectors";

export default compose(
  withRouter,
  Banner.withBanner,
  withSnackbar,
  withNotifier,
  withProps(() => ({
    pageConfig: {
      formName: EDIT_FAILED_SHIPMENT_FORM,
      pageTitle: S.EDIT_FAILED_SHIPMENT,
      pageMiddleText: S.ALL_FIELDS_MARKED_REQUIRED,
    },
  })),
  withState("csvService", "setCsvService"),
  withState("csvOutboundPackageData", "setCsvOutboundPackageData"),
  connect(
    (state, { pageConfig }) => ({
      disabledFields: getDisabledFields(state, pageConfig),
      requiredFields: ShipmentSelectors.getShipmentRequiredFields(
        state,
        pageConfig
      ),
      isShipmentLoading: getIsSelectedFailedShipmentLoading(state),
      shipment: getSelectedFailedShipment(state),
      storageDate: UserDataSelectors?.getItem(state.app, "date"),
      allowedFields: getAllowedFields(state, pageConfig),
    }),
    (dispatch, { notifier }) => ({
      fetchShipmentById: notifier.runAsync(
        stagingShipmentId =>
          dispatch(fetchFailedShipmentById(stagingShipmentId)),
        { entityName: S.SHIPMENT_ENTITY }
      ),
      fetchNetworks: notifier.runAsync(
        query => dispatch(ReferenceActions.fetchNetworks(query)),
        { entityName: S.NETWORKS }
      ),
      touchFields: fields =>
        dispatch(touch(EDIT_FAILED_SHIPMENT_FORM, ...fields)),
      touchExporterFields: () =>
        dispatch(
          touch(
            EDIT_EXPORTER_FORM,
            ...flattenEntityRoutes(ExporterDetailsEntity)
          )
        ),
      touchImporterFields: () =>
        dispatch(
          touch(
            EDIT_IMPORTER_FORM,
            ...flattenEntityRoutes(ImporterDetailsEntity)
          )
        ),
    })
  ),
  withShipmentAsyncValidation,
  withShipmentData,
  withEditFailedImportLoad,
  withAppLoader({
    loadFn: async ({
      customerPrefs,
      fetchShipmentById,
      setAbortController,
      history,
      loadEditFailedImport,
    }) => {
      setAbortController(new AbortController());

      let shipment;
      const match = matchPath(history.location.pathname, {
        path: routes.FAILED_IMPORT_EDIT,
        exact: true,
        strict: false,
      });

      try {
        shipment = await fetchShipmentById(match?.params?.stagingShipmentId);
      } catch (e) {}

      if (isEmpty(shipment) || isNil(shipment)) {
        return history.replace({
          pathname: routes.FAILED_IMPORTS,
        });
      }

      if (ShipmentModels.isDisabledAdhocEditShipment(customerPrefs)) {
        return history.replace({
          pathname: routes.FAILED_IMPORTS,
          state: {
            error: S.NO_PERMISSIONS_TO_CREATE_UPDATE_SHIPMENT,
          },
        });
      }

      await loadEditFailedImport(shipment);
    },
  }),
  withHandlers({
    onBackClick:
      ({ banner, history }) =>
      () => {
        banner.hideAll();
        history.push(routes.FAILED_IMPORTS);
      },
    onOutboundCountryChange:
      ({
        selectedAddressBook,
        profile,
        preferences,
        shippingSettings,
        uniqueSenderRef1,
        dispatch,
        pageConfig,
        validateOnUniqueShipmentRef1,
        createShipmentValues,
        csvOutboundPackageData,
        setCsvOutboundPackageData,
      }) =>
      async selection => {
        const countryCode = selection?.value || S.GB;
        const deliveryInstructions = profile.useMyDpdAccountSettings
          ? getValue(profile, "defaultInformation", "").toUpperCase()
          : "";
        const additionalFields = {};

        if (!selectedAddressBook?.shippingRef1) {
          additionalFields[ShipmentEntity.OUTBOUND_CONSIGNMENT.SHIPPING_REF_1] =
            ShipmentModels.getDefaultUniqueShippingRef1(
              countryCode,
              uniqueSenderRef1,
              {
                preferences,
                shippingSettings,
              }
            );
        }

        dispatch(ReferenceActions.setActiveOutboundService({}));
        csvOutboundPackageData
          ? setCsvOutboundPackageData()
          : dispatch(
              initializeForm(pageConfig.formName, {
                ...(deliveryInstructions && {
                  [ShipmentEntity.OUTBOUND_CONSIGNMENT.DELIVERY_INSTRUCTION]:
                    deliveryInstructions,
                }),
                [ShipmentEntity.OUTBOUND_CONSIGNMENT.TOTAL_WEIGHT]:
                  ShipmentModels.getDefaultTotalWeight(
                    profile,
                    preferences,
                    countryCode,
                    getValue(
                      createShipmentValues,
                      ShipmentEntity.OUTBOUND_CONSIGNMENT.NETWORK_CODE,
                      ""
                    )
                  ),
                [ShipmentEntity.OUTBOUND_CONSIGNMENT.NUMBER_OF_PARCELS]:
                  ShipmentModels.getDefaultNumberOfParcels(
                    preferences,
                    countryCode
                  ),
                ...additionalFields,
              })
            );
        // https://it.dpduk.live/version/customer-shipping/sprint-2.9/diag_w0IY9V6GAqAADNEM.html
        // run async validation manually since redux form doesn't give new value in time
        // async background task
        additionalFields[ShipmentEntity.OUTBOUND_CONSIGNMENT.SHIPPING_REF_1] &&
          validateOnUniqueShipmentRef1(
            getValue(
              additionalFields,
              ShipmentEntity.OUTBOUND_CONSIGNMENT.SHIPPING_REF_1
            )
          ).catch(() => {
            // ignore error because it's shown in the popup inside validateOnUniqueShipmentRef1
          });
      },
  }),
  withEditFailedImportPrint,
  withConsignmentNumberBanner
)(Shipment);
