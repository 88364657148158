import isEmpty from "lodash/isEmpty";
import withHandlers from "recompose/withHandlers";

import { SHOW_ALERT_DISPLAY_TIME } from "@dpdgroupuk/mydpd-app/lib/features/Notifier/constants";

import { Fields, ShipmentEntity } from "~/constants/forms";
import { ShipmentModels } from "~/models";
import {
  getFailedShipmentInitialDataValues,
  isAvailableCsvService,
} from "~/pages/FailedImports/pages/EditFailedShipment/models";
import { flattenEntityRoutes, getValue } from "~/utils/object";
import { DELIVERY_COUNTRY_CODE } from "~/constants/strings";
import { pick } from "lodash";

export default withHandlers({
  loadEditFailedImport:
    ({
      customerPrefs,
      fetchCountriesProfilesCurrenciesExportReasonsUniqueSenderRef,
      fetchAndProcessServices,
      initializeShipment,
      preferences,
      runCheckPostcodeIssues,
      initWithShipmentDate,
      fetchNetworks,
      touchFields,
      storageDate,
      customer,
      securitySettings,
      validateOnUniqueShipmentRef1,
      authUser,
      userCustomerAddress,
      shippingSettings,
      pageConfig,
      setCsvService,
      user,
      snackbar,
      setCsvOutboundPackageData,
    }) =>
    async shipment => {
      let network;
      const {
        failureDetails: { failureReasons },
      } = shipment;

      try {
        network = await fetchNetworks({
          networkCode: shipment.shipmentDetails.outboundConsignment.networkCode,
        });
      } catch (e) {}

      isAvailableCsvService(network?.networkCode, user.serviceExclusions) &&
        setCsvService(network);
      const {
        countries = [],
        currencies,
        exportReasons,
        uniqueSenderRef1,
        deliveryCountry,
        activeProfile,
      } = await fetchCountriesProfilesCurrenciesExportReasonsUniqueSenderRef({
        isAvailableInvoice: !isEmpty(shipment.shipmentDetails.invoice),
        shouldGenerateUniqueSenderRef1:
          ShipmentModels.shouldGenerateUniqueSenderRef1(
            pageConfig.formName,
            shippingSettings.allocateSenders
          ),
        shipmentCountryCode: getValue(
          shipment,
          ShipmentEntity.OUTBOUND_CONSIGNMENT.DELIVERY_DETAILS.ADDRESS
            .COUNTRY_CODE,
          ""
        ),
        shipmentProfileCode: getValue(
          shipment,
          ShipmentEntity.PROFILE_CODE,
          ""
        ),
      });

      // set valid values for successful fetchAndProcessServices
      const defaultShipmentData =
        ShipmentModels.setupDefaultValuesForInvalidData({
          shipment,
          network,
          preferences,
          currencies,
          countries,
          exportReasons,
          deliveryCountry,
          profile: activeProfile,
          userCustomerAddress,
          customer,
        });
      shipment = defaultShipmentData.shipment;
      const selectedCountry = defaultShipmentData.selectedCountry;

      const {
        outboundNetworks,
        inboundNetworks,
        selectedOutboundNetwork,
        outboundErrors,
        inboundErrors,
      } = await fetchAndProcessServices({
        shipment,
        profile: activeProfile,
        selectedCountry,
        setupDefaultService: false,
      });

      const initialValues = getFailedShipmentInitialDataValues({
        exportReasons,
        outboundNetworks,
        selectedOutboundNetwork,
        inboundNetworks,
        customer,
        preferences,
        customerPrefs,
        profile: activeProfile,
        shipment,
        securitySettings,
        currencies,
        countries,
        storageDate,
        authUser,
        uniqueSenderRef1,
        shippingSettings,
      });

      await initializeShipment({
        shipment,
        inboundNetworks,
        selectedOutboundNetwork,
        outboundErrors,
        inboundErrors,
        initialValues: {
          ...initialValues,
          stagingShipmentId: shipment?.stagingShipmentId,
        },
      });
      await touchFields(flattenEntityRoutes(ShipmentEntity));

      const errors = failureReasons[0].split("|");
      for (const error of errors) {
        if (error.includes(Fields.TELEPHONE) || error.includes(Fields.MOBILE)) {
          snackbar.showAlert({
            message: error,
            displayTime: SHOW_ALERT_DISPLAY_TIME,
          });
        }

        if (error.toLowerCase().includes(DELIVERY_COUNTRY_CODE)) {
          const packageData = pick(initialValues, [
            ShipmentEntity.OUTBOUND_CONSIGNMENT.TOTAL_WEIGHT,
            ShipmentEntity.OUTBOUND_CONSIGNMENT.NUMBER_OF_PARCELS,
            ShipmentEntity.OUTBOUND_CONSIGNMENT.SHIPPING_REF_1,
          ]);
          setCsvOutboundPackageData(packageData);
        }
      }

      if (validateOnUniqueShipmentRef1) {
        validateOnUniqueShipmentRef1(
          getValue(
            initialValues,
            ShipmentEntity.OUTBOUND_CONSIGNMENT.SHIPPING_REF_1
          )
        );
      }

      runCheckPostcodeIssues();
      initWithShipmentDate();
    },
});
