import { PropTypes } from "prop-types";

import { Field, propTypes } from "redux-form";

import DomesticService from "~/components/DomesticService";
import {
  INBOUND_CONSIGNMENT_UPPERCASE,
  ShipmentEntity,
} from "~/constants/forms";

import * as S from "~/constants/strings";

const ReturnPackageServiceDetailsContent = props => {
  const { disabledFields } = props;

  return (
    <Field
      component={DomesticService}
      name={ShipmentEntity.INBOUND_CONSIGNMENT.NETWORK_CODE}
      shipmentSection={INBOUND_CONSIGNMENT_UPPERCASE}
      values={props.inboundServices}
      productDisabled={
        disabledFields[ShipmentEntity.INBOUND_CONSIGNMENT.PRODUCT]
      }
      serviceDisabled={
        disabledFields[ShipmentEntity.INBOUND_CONSIGNMENT.NETWORK_CODE]
      }
      onBlur={props.onFieldEntry}
      required={
        props.requiredFields[ShipmentEntity.INBOUND_CONSIGNMENT.NETWORK_CODE]
      }
      textTransform={S.UPPERCASE}
      onChange={props.onInboundNetworkChange}
    />
  );
};

ReturnPackageServiceDetailsContent.propTypes = {
  onFieldEntry: PropTypes.func,
  disabledFields: PropTypes.object,
  ...propTypes,
};

export default ReturnPackageServiceDetailsContent;
