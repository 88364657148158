import {
  createActionTypes,
  createAsyncAction,
  createAsyncActionTypes,
} from "@dpdgroupuk/redux-action-creator";

import { referenceApi, shipmentApi } from "~/apis";
import { UmsSelectors } from "~/redux";

export const ActionTypes = createActionTypes("SHIPMENT", {
  FETCH: createAsyncActionTypes("FETCH"),
  GET_SHIPMENTS_STATS: createAsyncActionTypes("GET_SHIPMENTS_STATS"),
  VOID: createAsyncActionTypes("VOID"),
  UNVOID: createAsyncActionTypes("UNVOID"),
  HOLD: createAsyncActionTypes("HOLD"),
  UNHOLD: createAsyncActionTypes("UNHOLD"),
  VOID_PARCELS: createAsyncActionTypes("VOID_PARCELS"),
  UNVOID_PARCELS: createAsyncActionTypes("UNVOID_PARCELS"),
  UPDATE_DATE: createAsyncActionTypes("UPDATE_DATE"),
  DELETE: createAsyncActionTypes("DELETE"),
  UPDATE_PARCELS: createAsyncActionTypes("UPDATE_PARCELS"),
  FETCH_NI_SHIPMENT_REQUIRED_FIELDS: createAsyncActionTypes(
    "FETCH_NI_SHIPMENT_REQUIRED_FIELDS"
  ),
  CLEAR: "CLEAR",
});

export const fetchShipments = createAsyncAction(
  query => shipmentApi.getShipmentList(query),
  ActionTypes.FETCH
);

export const clearReviewData = () => ({
  type: ActionTypes.CLEAR,
});

export const voidShipment = createAsyncAction(
  (shipmentId, options) => shipmentApi.voidShipment(shipmentId, options),
  ActionTypes.VOID
);

export const voidParcels = createAsyncAction(
  (shipmentId, options) => shipmentApi.voidParcels(shipmentId, options),
  ActionTypes.VOID_PARCELS
);

export const unvoidParcels = createAsyncAction(
  (shipmentId, options) => shipmentApi.unvoidParcels(shipmentId, options),
  ActionTypes.UNVOID_PARCELS
);

export const unvoidShipment = createAsyncAction(
  (shipmentId, options) => shipmentApi.unvoidShipment(shipmentId, options),
  ActionTypes.UNVOID
);

export const updateDateShipment = createAsyncAction(
  (shipmentId, options) => shipmentApi.updateShipmentDate(shipmentId, options),
  ActionTypes.UPDATE_DATE
);

export const deleteShipment = createAsyncAction(
  (shipmentId, options) => shipmentApi.deleteShipment(shipmentId, options),
  ActionTypes.DELETE
);

export const updateShipmentParcels = createAsyncAction(
  (shipmentId, query) => shipmentApi.updateShipmentParcels(shipmentId, query),
  ActionTypes.UPDATE_PARCELS
);

export const fetchShipmentsStats = createAsyncAction(
  options => (dispatch, getState) => {
    const query = {
      includePastShipments: UmsSelectors.isEodUser(getState()),
    };

    return shipmentApi.fetchShipmentsStats(query, options);
  },
  ActionTypes.GET_SHIPMENTS_STATS
);

export const holdShipment = createAsyncAction(
  shipmentApi.holdShipment,
  ActionTypes.HOLD
);

export const unholdShipment = createAsyncAction(
  shipmentApi.unholdShipment,
  ActionTypes.HOLD
);

export const fetchNiShipmentRequiredFields = createAsyncAction(
  referenceApi.fetchRequiredFieldsForDomesticZone,
  ActionTypes.FETCH_NI_SHIPMENT_REQUIRED_FIELDS
);
