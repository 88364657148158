import { Redirect, Route } from "react-router";
import { Switch } from "react-router-dom";

import { withImportBanner } from "~/features";
import * as routes from "~/router/constants";

import FailedImportsList from "./pages/FailedImportsList";
import EditFailedShipment from "~/pages/FailedImports/pages/EditFailedShipment";

const FailedImports = () => (
  <Switch>
    <Route
      exact
      strict
      path={routes.FAILED_IMPORTS}
      component={FailedImportsList}
    />
    <Route
      exact
      path={routes.FAILED_IMPORT_EDIT}
      component={EditFailedShipment}
      redirectTo={routes.FAILED_IMPORTS}
    />
    <Redirect to={routes.FAILED_IMPORTS} />
  </Switch>
);

export default withImportBanner(FailedImports);
