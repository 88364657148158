import { useMemo } from "react";

import PropTypes from "prop-types";
import { useColumnOrder } from "react-table/src/plugin-hooks/useColumnOrder";
import { useFlexLayout } from "react-table/src/plugin-hooks/useFlexLayout";
import { useResizeColumns } from "react-table/src/plugin-hooks/useResizeColumns";

import { useUserHeaderStatePersist } from "@dpdgroupuk/mydpd-app";
import { DndTable } from "@dpdgroupuk/mydpd-ui";

import * as S from "~/constants/strings";
import { separateThousandWithComma } from "~/utils/number";

import styles from "./ProductDetailsTable.module.scss";
import { getValue } from "~/utils/object";

const PRODUCT_TABLE_DATA_COLUMNS = [
  {
    Header: S.PRODUCT_CODE,
    accessor: "productCode",
    minWidth: 136,
  },
  {
    Header: S.PRODUCT_TYPE,
    accessor: "productType",
    minWidth: 111,
  },
  {
    Header: S.DETAILED_DESCRIPTION,
    accessor: "productDescription",
    minWidth: 166,
  },
  {
    Header: S.COMMODITY_CODE,
    accessor: "commodityCode",
    minWidth: 135,
  },
  {
    Header: S.UNIT_WEIGHT,
    accessor: "unitWeight",
    minWidth: 111,
    Cell: ({ row: { original } }) => {
      const unitWeight = getValue(original, "unitWeight", "");

      return separateThousandWithComma(
        unitWeight ? unitWeight.toFixed(2) : unitWeight
      );
    },
  },
  {
    Header: S.UNIT_VALUE,
    accessor: "unitValue",
    minWidth: 111,
    Cell: ({ row: { original } }) => {
      const unitValue = getValue(original, "unitValue", "");
      return separateThousandWithComma(
        unitValue ? unitValue.toFixed(2) : unitValue
      );
    },
  },
  {
    Header: S.QUANTITY,
    accessor: "productQty",
    minWidth: 111,
  },
];

const ProductDetailsTable = ({ data }) => {
  const memoizedData = useMemo(
    () => data.sort((a, b) => a.productCode?.localeCompare(b.productCode)),
    [data]
  );

  return (
    <DndTable
      columns={PRODUCT_TABLE_DATA_COLUMNS}
      data={memoizedData}
      classes={{
        container: styles.tableContainer,
      }}
      tableHooks={[
        useFlexLayout,
        useResizeColumns,
        useColumnOrder,
        useUserHeaderStatePersist,
      ]}
      initialState={{
        storageKey: "productDetails",
      }}
    />
  );
};

ProductDetailsTable.propTypes = {
  data: PropTypes.array,
  onClickVoid: PropTypes.func,
  onClickUnvoid: PropTypes.func,
};

export default ProductDetailsTable;
