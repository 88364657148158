import { Col, Row } from "react-bootstrap";
import * as S from "~/constants/strings";
import { Field } from "redux-form";
import { Fields, ImporterDetailsEntity } from "~/constants/forms";
import { Normalizers } from "~/models";
import { FormControl } from "@dpdgroupuk/mydpd-ui";
import { formatMessage } from "~/utils/string";
import PropTypes from "prop-types";

const ImporterBusinessFields = ({
  requiredFields,
  allowedFields,
  onFieldEntry,
}) => (
  <>
    {allowedFields[Fields.EORI_NUMBER] && (
      <Row>
        <Col>
          <Field
            component={FormControl.Input}
            label={formatMessage(S.$_$_NUMBER, S.IMPORTERS, S.EORI)}
            name={ImporterDetailsEntity.EORI_NUMBER}
            onBlur={onFieldEntry}
            required={requiredFields[ImporterDetailsEntity.EORI_NUMBER]}
            maxLength={45}
            helperText
            normalize={Normalizers.emptyValueNormalize}
          />
        </Col>
      </Row>
    )}
    {allowedFields[Fields.VAT_NUMBER] && (
      <Row>
        <Col>
          <Field
            component={FormControl.Input}
            label={formatMessage(S.$_$_NUMBER, S.IMPORTERS, S.VAT)}
            name={Fields.VAT_NUMBER}
            onBlur={onFieldEntry}
            required={requiredFields[ImporterDetailsEntity.VAT_NUMBER]}
            maxLength={45}
            helperText
            normalize={Normalizers.emptyValueNormalize}
          />
        </Col>
      </Row>
    )}
    {allowedFields[Fields.UKIMS_NUMBER] && (
      <Row>
        <Col>
          <Field
            component={FormControl.Input}
            label={formatMessage(S.$_$_NUMBER, S.IMPORTERS, S.UKIMS)}
            name={ImporterDetailsEntity.UKIMS_NUMBER}
            onBlur={onFieldEntry}
            required={requiredFields[ImporterDetailsEntity.UKIMS_NUMBER]}
            helperText={S.MAX_32_CHARACTERS}
            maxLength={32}
          />
        </Col>
      </Row>
    )}
    {allowedFields[Fields.PID_NUMBER] && (
      <Row>
        <Col>
          <Field
            component={FormControl.Input}
            label={formatMessage(S.$_$_NUMBER, S.IMPORTERS, S.PID)}
            name={ImporterDetailsEntity.PID_NUMBER}
            onBlur={onFieldEntry}
            required={requiredFields[ImporterDetailsEntity.PID_NUMBER]}
            maxLength={45}
            helperText
            normalize={Normalizers.emptyValueNormalize}
          />
        </Col>
      </Row>
    )}
  </>
);

ImporterBusinessFields.propTypes = {
  requiredFields: PropTypes.object,
  allowedFields: PropTypes.object,
  onFieldEntry: PropTypes.func,
};

export default ImporterBusinessFields;
