import { noop } from "lodash/fp";
import get from "lodash/get";
import moment from "moment";
import { connect } from "react-redux";
import { compose, withHandlers } from "recompose";

import { withNotifier } from "@dpdgroupuk/mydpd-app";
import {
  Banner,
  withOverlay,
  withPrompt,
  withSnackbar,
} from "@dpdgroupuk/mydpd-ui";

import { DEFAULT_DATE_FORMAT, ISO_DATE_FORMAT } from "~/constants/dateFormats";
import {
  SHIPMENT_REVIEW_CHANGE_DATE_FORM,
  ShipmentEntity,
  ShipmentReviewFilterFields,
} from "~/constants/forms";
import { SHOW_ALERT_DISPLAY_TIME } from "~/constants/snackbar";
import * as S from "~/constants/strings";
import withPrint from "~/hocs/withPrint";
import { formatISODate } from "~/utils/date";
import { getErrorMessage } from "~/utils/error";
import {
  getQueryFilters,
  getQueryPagination,
  getQueryPaginationByTotal,
} from "~/utils/query";
import { formatMessage } from "~/utils/string";

import { ShipmentReviewActions } from "../redux";
import { checkMinDate } from "~/redux";

export default compose(
  withSnackbar,
  withPrompt,
  withOverlay,
  withNotifier,
  Banner.withBanner,
  withPrint,
  connect(
    null,
    (
      dispatch,
      {
        snackbar,
        setSelectedShipments,
        overlay,
        replaceFilter,
        location,
        queryFromStorage,
        notifier,
      }
    ) => {
      const fetchShipmentList = (pageParams, query) =>
        dispatch(
          ShipmentReviewActions.fetchShipments({
            searchPage: pageParams.page,
            searchPageSize: pageParams.pageSize,
            ...(query.searchCriteria && {
              searchCriteria: query.searchCriteria,
            }),
            ...(query.searchDate && {
              searchDate: moment(query.searchDate, DEFAULT_DATE_FORMAT).format(
                ISO_DATE_FORMAT
              ),
            }),
          })
        );
      const fetchShipmentsStats = notifier.runAsync(
        () => dispatch(ShipmentReviewActions.fetchShipmentsStats()),
        { entityName: S.SHIPMENT }
      );

      const reloadFn = async userSearchQuery => {
        const locationSearchQuery = location.search;

        try {
          overlay.show();

          if (setSelectedShipments) {
            setSelectedShipments([]);
          }

          const searchQuery =
            userSearchQuery || locationSearchQuery || queryFromStorage;

          const pagination = getQueryPagination(searchQuery);
          const query = getQueryFilters(
            searchQuery,
            ShipmentReviewFilterFields
          );

          const [{ data: shipmentsList }] = await Promise.all([
            fetchShipmentList(pagination, query),
            fetchShipmentsStats(),
          ]).then(results =>
            results.map(result => ({
              data: result?.data || [],
            }))
          );

          if (!shipmentsList.results.length && !!shipmentsList?.totalResults) {
            const newPagination = getQueryPaginationByTotal(
              searchQuery,
              shipmentsList.totalResults
            );
            replaceFilter(newPagination);
          } else {
            overlay.hide();
          }
        } catch (e) {
          overlay.hide();

          snackbar.showAlert({
            message: getErrorMessage(e, S.SHIPMENT),
            displayTime: SHOW_ALERT_DISPLAY_TIME,
          });
        } finally {
          overlay.hide();
        }
      };

      return {
        fetchShipmentsStats,
        fetchShipmentList,
        reloadFn,
        holdShipment: shipmentId =>
          dispatch(ShipmentReviewActions.holdShipment(shipmentId)),
        unholdShipment: shipmentId =>
          dispatch(ShipmentReviewActions.unholdShipment(shipmentId)),
        voidShipment: shipmentId =>
          dispatch(ShipmentReviewActions.voidShipment(shipmentId)),
        unvoidShipment: shipmentId =>
          dispatch(ShipmentReviewActions.unvoidShipment(shipmentId)),
        deleteShipment: shipmentId =>
          dispatch(ShipmentReviewActions.deleteShipment(shipmentId)),
      };
    }
  ),
  withHandlers({
    onClickHold: ({ prompt, overlay, notifier, reloadFn, holdShipment }) =>
      notifier.runAsync(
        async (selectedShipments = []) => {
          try {
            overlay.show();

            await Promise.all(
              selectedShipments.map(({ shipmentId }) =>
                holdShipment(shipmentId)
              )
            );
            await reloadFn();

            const successPromptContent =
              selectedShipments.length > 1
                ? {
                    header: S.SHIPMENTS_HELD,
                    message: S.SHIPMENTS_HELD_MESSAGE,
                  }
                : {
                    header: S.SHIPMENT_HELD,
                    message: formatMessage(
                      S.$_SHIPMENT_HELD_MESSAGE,
                      get(
                        selectedShipments[0],
                        "outboundConsignment.consignmentNumber"
                      )
                    ),
                  };

            prompt.showInfo(successPromptContent);
          } finally {
            overlay.hide();
          }
        },
        { entityName: S.SHIPMENT }
      ),
    onClickUnhold: ({ prompt, overlay, notifier, reloadFn, unholdShipment }) =>
      notifier.runAsync(
        async (selectedShipments = []) => {
          try {
            overlay.show();

            await Promise.all(
              selectedShipments.map(({ shipmentId }) =>
                unholdShipment(shipmentId)
              )
            );
            await reloadFn();

            const successPromptContent =
              selectedShipments.length > 1
                ? {
                    header: S.SHIPMENTS_UNHELD,
                    message: S.SHIPMENTS_UNHELD_MESSAGE,
                  }
                : {
                    header: S.SHIPMENT_UNHELD,
                    message: formatMessage(
                      S.$_SHIPMENT_UNHELD_MESSAGE,
                      get(
                        selectedShipments[0],
                        "outboundConsignment.consignmentNumber"
                      )
                    ),
                  };

            prompt.showInfo(successPromptContent);
          } finally {
            overlay.hide();
          }
        },
        { entityName: S.SHIPMENT }
      ),
    onClickVoid: ({ prompt, overlay, notifier, reloadFn, voidShipment }) =>
      notifier.runAsync(
        async (selectedShipments = []) => {
          try {
            overlay.show();

            await Promise.all(
              selectedShipments.map(({ shipmentId }) =>
                voidShipment(shipmentId)
              )
            );
            await reloadFn();

            const successPromptContent =
              selectedShipments.length > 1
                ? {
                    header: S.SHIPMENTS_VOIDED,
                    message: S.SHIPMENTS_VOIDED_MESSAGE,
                  }
                : {
                    header: S.SHIPMENT_VOIDED,
                    message: formatMessage(
                      S.$_SHIPMENT_VOIDED_MESSAGE,
                      get(
                        selectedShipments[0],
                        "outboundConsignment.consignmentNumber"
                      )
                    ),
                  };

            prompt.showInfo(successPromptContent);
          } finally {
            overlay.hide();
          }
        },
        { entityName: S.SHIPMENT }
      ),
    onClickUnvoid: ({ overlay, prompt, reloadFn, notifier, unvoidShipment }) =>
      notifier.runAsync(
        async (selectedShipments = []) => {
          let success;
          let error;
          const successPromptContent =
            selectedShipments.length > 1
              ? {
                  header: S.SHIPMENTS_UNVOIDED,
                  message: S.SHIPMENTS_UNVOIDED_MESSAGE,
                }
              : {
                  header: S.SHIPMENT_UNVOIDED,
                  message: formatMessage(
                    S.$_SHIPMENT_UNVOIDED_MESSAGE,
                    get(
                      selectedShipments[0],
                      "outboundConsignment.consignmentNumber"
                    )
                  ),
                };

          try {
            overlay.show();
            for await (const shipment of selectedShipments) {
              await Promise.allSettled([
                unvoidShipment(shipment.shipmentId),
              ]).then(results => {
                if (results[0].value) {
                  success = true;
                }

                // NOTE: show only first error -  the same behaviour as on the old app
                // TODO: clarify it and show correct error-message with consignmentNumber (case when first failure, second success)
                if (results[0].reason && !error) {
                  error = results[0].reason;
                }
              });
            }
          } finally {
            if (success) {
              await reloadFn();
              prompt.showInfo(successPromptContent);
            }
            overlay.hide();

            if (error) {
              // eslint-disable-next-line no-unsafe-finally
              throw error;
            }
          }
        },
        { entityName: S.SHIPMENT }
      ),
    onClickDelete: ({ notifier, prompt, reloadFn, overlay, deleteShipment }) =>
      notifier.runAsync(
        async (selectedShipments = []) => {
          try {
            overlay.show();

            await Promise.all(
              selectedShipments.map(({ shipmentId }) =>
                deleteShipment(shipmentId)
              )
            );
            await reloadFn();

            const successPromptContent =
              selectedShipments.length > 1
                ? {
                    header: S.SHIPMENTS_DELETED,
                    message: S.SHIPMENTS_DELETED_MESSAGE,
                  }
                : {
                    header: S.SHIPMENT_DELETED,
                    message: formatMessage(
                      S.$_SHIPMENT_DELETED_MESSAGE,
                      get(
                        selectedShipments[0],
                        "outboundConsignment.consignmentNumber"
                      )
                    ),
                  };

            prompt.showInfo(successPromptContent);
          } finally {
            overlay.hide();
          }
        },
        { entityName: S.SHIPMENT }
      ),
    onClickChangeDate: ({ notifier, prompt, reloadFn, overlay, dispatch }) =>
      notifier.runAsync(
        async (selectedShipments = [], onSuccess = noop) => {
          try {
            overlay.show();
            const date = dispatch(
              checkMinDate({
                formName: SHIPMENT_REVIEW_CHANGE_DATE_FORM,
                dateFormat: DEFAULT_DATE_FORMAT,
                fieldPath: ShipmentEntity.SHIPMENT_DATE,
              })
            );

            await Promise.all(
              selectedShipments.map(({ shipmentId }) =>
                dispatch(
                  ShipmentReviewActions.updateDateShipment(shipmentId, {
                    shipmentDate: formatISODate(date),
                  })
                )
              )
            );
            onSuccess();

            await reloadFn();

            const successPromptContent =
              selectedShipments.length > 1
                ? {
                    header: S.SHIPMENTS_DATE_CHANGED,
                    message: formatMessage(
                      S.SHIPMENTS_DATE_CHANGED_MESSAGE_TO_$,
                      date
                    ),
                  }
                : {
                    header: S.SHIPMENT_DATE_CHANGED,
                    message: formatMessage(
                      S.$_SHIPMENT_DATE_CHANGED_MESSAGE_TO_$,
                      get(
                        selectedShipments[0],
                        "outboundConsignment.consignmentNumber"
                      ),
                      date
                    ),
                  };

            prompt.showInfo(successPromptContent);
          } finally {
            overlay.hide();
          }
        },
        { entityName: S.SHIPMENT }
      ),
    onShipmentPrint: ({ printWithInvoice, reloadFn, overlay }) =>
      overlay.showWhile(async selectedShipments => {
        await printWithInvoice(selectedShipments);
        reloadFn();
      }),
  })
);
