import classNames from "classnames";
import PropTypes from "prop-types";

import { Check, Cross, Pen, Trash } from "~/components/Icon";
import styles from "./ProductDescriptionListItem.module.scss";
import { useCallback, useMemo, useRef, useState } from "react";

const ProductDescriptionsListItem = ({
  sequenceNumber,
  text,
  onRemove,
  isRemoveBtnVisible,
  onSaveChanges,
  isEditingProductDescription,
  setIsEditingProductDescription,
}) => {
  const textRef = useRef(null);
  const [initialText, setInitialText] = useState(text);
  const [contentEditable, setContentEditable] = useState(false);
  const [isVisibleCheck, setIsVisibleCheck] = useState(false);
  const isPenAllowed = useMemo(
    () => !contentEditable && !isEditingProductDescription,
    [contentEditable, isEditingProductDescription]
  );
  const isTrashAllowed = useMemo(
    () =>
      isRemoveBtnVisible && !contentEditable && !isEditingProductDescription,
    [isRemoveBtnVisible, contentEditable, isEditingProductDescription]
  );

  // set cursor after last character
  const setCursorToEnd = useCallback(() => {
    if (textRef.current) {
      const range = document.createRange();
      const selection = window.getSelection();
      range.selectNodeContents(textRef.current);
      range.collapse(false);
      selection.removeAllRanges();
      selection.addRange(range);
      textRef.current.focus();
    }
  }, []);

  const handleInput = useCallback(() => {
    if (textRef.current) {
      const textContent = textRef.current.innerText.trim();
      setIsVisibleCheck(!!textContent.length);

      if (textContent.length > 200) {
        textRef.current.innerText = textContent.slice(0, 200);
        setCursorToEnd();
      }
    }
  }, [setCursorToEnd]);

  const handleKeyDown = useCallback(event => {
    if (event.code === "Enter") {
      event.preventDefault();
      const textContent = textRef.current.innerText.trim();

      if (textContent.length) {
        handleCheckClick();
      }
    }
  }, []);

  const handleEditClick = useCallback(() => {
    setContentEditable(true);
    setIsVisibleCheck(true);
    setIsEditingProductDescription(true);
    // NOTE: set cursor when DOM was updated
    setTimeout(setCursorToEnd, 0);
  }, [setContentEditable, setIsVisibleCheck, setCursorToEnd]);

  const handleCheckClick = useCallback(() => {
    if (textRef.current) {
      onSaveChanges(
        { productDescription: textRef.current.innerText.trim() },
        sequenceNumber - 1
      );
      setInitialText(textRef.current.innerText.trim());
      setContentEditable(false);
      setIsVisibleCheck(false);
      setIsEditingProductDescription(false);
    }
  }, [
    setContentEditable,
    setIsVisibleCheck,
    setInitialText,
    setIsEditingProductDescription,
    onSaveChanges,
    sequenceNumber,
  ]);

  const handleCrossClick = useCallback(() => {
    if (textRef.current) {
      setContentEditable(false);
      setIsVisibleCheck(false);
      setIsEditingProductDescription(false);
      textRef.current.innerText = initialText;
    }
  }, [
    setContentEditable,
    setIsVisibleCheck,
    setIsEditingProductDescription,
    initialText,
  ]);

  const handleBlur = useCallback(() => {
    textRef.current.focus();
    setCursorToEnd();
  }, []);

  const handleRemoveClick = useCallback(
    event => onRemove(event, sequenceNumber - 1),
    [sequenceNumber, onRemove]
  );

  return (
    <li className={classNames(styles.item, "mb-3")} onBlur={handleBlur}>
      <div className={styles.leftContainer}>
        <p className={styles.sequenceNumber}>{sequenceNumber}</p>
        <div className={styles.textContainer}>
          <p
            className={styles.text}
            ref={textRef}
            contentEditable={contentEditable}
            onInput={handleInput}
            onKeyDown={handleKeyDown}
            suppressContentEditableWarning={true} // NOTE: suppresses React warning about using contentEditable.
          >
            {text}
          </p>
        </div>
      </div>
      {onSaveChanges && (
        <div className={styles.iconContainer}>
          {isPenAllowed && (
            <div onClick={handleEditClick}>
              <Pen color={"#808285"} size={"18"} />
            </div>
          )}
          {isVisibleCheck && (
            <div onClick={handleCheckClick}>
              <Check />
            </div>
          )}
          {contentEditable && (
            <div onClick={handleCrossClick}>
              <Cross />
            </div>
          )}
          {isTrashAllowed && (
            <div onClick={handleRemoveClick}>
              <Trash />
            </div>
          )}
        </div>
      )}
    </li>
  );
};

ProductDescriptionsListItem.propTypes = {
  sequenceNumber: PropTypes.number,
  text: PropTypes.string,
  onRemove: PropTypes.func,
  onSaveChanges: PropTypes.func,
  setIsEditingProductDescription: PropTypes.func,
  isRemoveBtnVisible: PropTypes.bool,
  isEditingProductDescription: PropTypes.bool,
};

export default ProductDescriptionsListItem;
