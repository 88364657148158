import Joi from "joi";

import { REQUIRED_TYPE } from "@dpdgroupuk/mydpd-enums";

import * as S from "~/constants/strings";

import {
  commodityCodeGeneric,
  commodityCodeStrict,
  countryCode,
} from "./commonSchema";

// Add correct error messages after main Joi rework will be merged
export default Joi.object().keys({
  productCode: Joi.string().allow("").max(255).label(S.PRODUCT_CODE), // skuRequired
  productType: Joi.string().allow("").max(40).label(S.PRODUCT_TYPE), // skuRequired
  productDescription: Joi.string()
    .allow("")
    .max(200)
    .label(S.DETAILED_PRODUCT_DESCRIPTION),
  productComposition: Joi.string()
    .allow("")
    .max(200)
    .label(S.PRODUCT_COMPOSITION), // skuRequired
  commodityCode: Joi.when("$selectedServiceCode", {
    is: "9",
    then: commodityCodeStrict,
    otherwise: Joi.when("$selectedDeliveryCountry.countryKey", {
      is: S.IE,
      then: Joi.when("$selectedNetworkCode", {
        is: "11",
        then: commodityCodeStrict,
        otherwise: Joi.when("$selectedService.commodityRequired", {
          is: REQUIRED_TYPE.MANDATORY,
          then: commodityCodeStrict,
          otherwise: commodityCodeGeneric,
        }),
      }),
      otherwise: commodityCodeGeneric,
    }),
  }).label(S.COMMODITY_CODE), // commodityRequired
  unitWeight: Joi.number()
    .allow("")
    .min(0.01)
    .max(99999.99)
    .label(S.PRODUCT_UNIT_WEIGHT),
  productQty: Joi.number()
    .allow("")
    .min(1)
    .max(99999)
    .label(S.PRODUCT_QUANTITY),
  unitValue: Joi.number()
    .allow("")
    .min(0.01)
    .max(99999.99)
    .label(S.PRODUCT_UNIT_VALUE),
  productUrl: Joi.string().allow("").max(255).label(S.PRODUCT_URL),
  countryOfOrigin: countryCode.label(S.COUNTRY_OF_ORIGIN),
});
