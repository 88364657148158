const Cross = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <mask
      id="mask0_2185_26288"
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      width="24"
      height="24"
    >
      <rect width="24" height="24" fill="#D9D9D9" />
    </mask>
    <g mask="url(#mask0_2185_26288)">
      <path
        d="M4.26634 12.6666L3.33301 11.7333L7.06634 7.99992L3.33301 4.26659L4.26634 3.33325L7.99967 7.06659L11.733 3.33325L12.6663 4.26659L8.93301 7.99992L12.6663 11.7333L11.733 12.6666L7.99967 8.93325L4.26634 12.6666Z"
        fill="#DC0032"
      />
    </g>
  </svg>
);

export default Cross;
