export const CREATE = "/create";
export const REVIEW = "/review";
export const SHIPMENT_VIEW = "/review/:shipmentId";
export const SHIPMENT_EDIT = "/review/:shipmentId/edit";
export const CONTAINER = "/container";
export const IMPORTS = "/imports";
export const IMPORT_ACTIVITY = "/import-activity";
export const FAILED_IMPORTS = "/failed-imports";
export const FAILED_IMPORT_EDIT = "/failed-imports/:stagingShipmentId/edit";
export const TOOLS = "/tools";
export const SCANNING = "/scanning";
