import { Col, Row } from "react-bootstrap";
import * as S from "~/constants/strings";
import { Field } from "redux-form";
import { ReduxifiedRadioField } from "~/components/Radio/Radio";
import { ImporterDetailsEntity } from "~/constants/forms";
import { Normalizers } from "~/models";
import { FormControl } from "@dpdgroupuk/mydpd-ui";
import { formatMessage } from "~/utils/string";
import PropTypes from "prop-types";

const ImporterBusinessFields = ({
  classes,
  onImporterTypeChange,
  isBusiness,
  requiredFields,
  onFieldEntry,
}) => {
  return (
    <>
      <Row>
        <Col>
          <div className={classes.importerType}>{S.IMPORTER_TYPE}</div>
        </Col>
      </Row>
      <Row>
        <Col>
          <Field
            component={ReduxifiedRadioField}
            type="radio"
            label={S.BUSINESS}
            name={ImporterDetailsEntity.IS_BUSINESS}
            onChange={event => onImporterTypeChange(event.target.value)}
            checked={isBusiness}
            value={S.BUSINESS_KEY_VALUE.value}
            normalize={Normalizers.convertToBool}
          />
        </Col>
      </Row>
      <Row className={classes.typeRelatedField}>
        <Col>
          <Field
            component={FormControl.Input}
            label={formatMessage(S.$_$_NUMBER, S.IMPORTERS, S.EORI)}
            name={ImporterDetailsEntity.EORI_NUMBER}
            onBlur={onFieldEntry}
            required={requiredFields[ImporterDetailsEntity.EORI_NUMBER]}
            disabled={!isBusiness}
            maxLength={45}
            helperText
            normalize={Normalizers.emptyValueNormalize}
          />
        </Col>
      </Row>
      <Row className={classes.typeRelatedField}>
        <Col>
          <Field
            component={FormControl.Input}
            label={formatMessage(S.$_$_NUMBER, S.IMPORTERS, S.VAT)}
            name={ImporterDetailsEntity.VAT_NUMBER}
            onBlur={onFieldEntry}
            required={requiredFields[ImporterDetailsEntity.VAT_NUMBER]}
            disabled={!isBusiness}
            maxLength={45}
            helperText
            normalize={Normalizers.emptyValueNormalize}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <Field
            component={ReduxifiedRadioField}
            type="radio"
            label={S.CONSUMER}
            name={ImporterDetailsEntity.IS_BUSINESS}
            onChange={(_, newValue) => onImporterTypeChange(newValue)}
            checked={!isBusiness}
            value={S.CONSUMER_KEY_VALUE.value}
            normalize={Normalizers.convertToBool}
          />
        </Col>
      </Row>
      <Row className={classes.typeRelatedField}>
        <Col>
          <Field
            component={FormControl.Input}
            label={formatMessage(S.$_$_NUMBER, S.IMPORTERS, S.PID)}
            name={ImporterDetailsEntity.PID_NUMBER}
            onBlur={onFieldEntry}
            required={requiredFields[ImporterDetailsEntity.PID_NUMBER]}
            disabled={isBusiness}
            maxLength={45}
            helperText
            normalize={Normalizers.emptyValueNormalize}
          />
        </Col>
      </Row>
    </>
  );
};

ImporterBusinessFields.propTypes = {
  classes: PropTypes.object,
  requiredFields: PropTypes.object,
  isBusiness: PropTypes.bool,
  onImporterTypeChange: PropTypes.func,
  onFieldEntry: PropTypes.func,
};

export default ImporterBusinessFields;
