const Trash = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.25 17C5.8375 17 5.4845 16.8478 5.191 16.5434C4.897 16.2386 4.75 15.8722 4.75 15.4444V5.33333H4V3.77778H7.75V3H12.25V3.77778H16V5.33333H15.25V15.4444C15.25 15.8722 15.1033 16.2386 14.8097 16.5434C14.5157 16.8478 14.1625 17 13.75 17H6.25ZM13.75 5.33333H6.25V15.4444H13.75V5.33333ZM7.75 13.8889H9.25V6.88889H7.75V13.8889ZM10.75 13.8889H12.25V6.88889H10.75V13.8889Z"
        fill="#808285"
      />
    </svg>
  );
};

export default Trash;
