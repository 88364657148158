import { Redirect, Route } from "react-router";
import { Switch } from "react-router-dom";

import { withImportBanner } from "~/features";
import ShipmentList from "~/pages/ShipmentReview/pages/ShipmentList";
import * as routes from "~/router/constants";

import ProtectedShipmentRoute from "./components/ProtectedShipmentRoute";
import ShipmentEdit from "~/pages/ShipmentReview/pages/ShipmentEdit";
import ShipmentView from "./pages/ShipmentView";

const ShipmentReview = () => (
  <Switch>
    <Route exact strict path={routes.REVIEW} component={ShipmentList} />
    <ProtectedShipmentRoute
      exact
      path={routes.SHIPMENT_VIEW}
      component={ShipmentView}
      redirectTo={routes.REVIEW}
    />
    <Route
      exact
      path={routes.SHIPMENT_EDIT}
      component={ShipmentEdit}
      redirectTo={routes.REVIEW}
    />
    <Redirect to={routes.REVIEW} />
  </Switch>
);

export default withImportBanner(ShipmentReview);
