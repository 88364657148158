import { get } from "lodash";

import { REGEX } from "@dpdgroupuk/mydpd-enums";

import { Fields, ProductEntity, ShipmentEntity } from "~/constants/forms";
import { MAXIMUM_NO_OF_ARRAY_ELEMENT } from "~/constants/numbers";
import {
  PKG_NUMBER,
  NUMBER,
  PRODUCT_DESCRIPTION,
  VALUE_$,
} from "~/constants/strings";
import { ParcelModels, ShipmentModels } from "~/models";
import { separateThousandWithComma } from "~/utils/number";
import { getValue } from "~/utils/object";
import { formatMessage } from "~/utils/string";

export const getProductTableColumns = (selectedCurrency, isNewVersion) => [
  {
    Header: isNewVersion ? NUMBER : PKG_NUMBER,
    accessor: Fields.PACKAGE_NUMBER,
    Cell: ({ row: { original } }) =>
      // For the new version the first column in the table is
      // just a count of how many products have been added
      isNewVersion
        ? parseInt(original.productIndex) + 1
        : parseInt(original.packageNumber) + 1,
    minWidth: 100,
    width: 100,
  },
  {
    Header: formatMessage(
      VALUE_$,
      ShipmentModels.getCurrencyLabel(selectedCurrency)
    ),
    accessor: ProductEntity.UNIT_VALUE,
    Cell: ({ row: { original } }) =>
      separateThousandWithComma(
        ParcelModels.calculateProductTotalValue(original).toFixed(2)
      ),
    minWidth: 100,
    width: 100,
  },
  {
    Header: PRODUCT_DESCRIPTION.toUpperCase(),
    accessor: ProductEntity.PRODUCT_DESCRIPTION,
    minWidth: 238,
  },
];

export const getParcelsProductsErrors = errors =>
  getValue(errors, "outboundConsignment.parcels", []);

export const getShipmentPackageNumberOptions = values => {
  const numberOfParcels =
    parseInt(
      get(values, ShipmentEntity.OUTBOUND_CONSIGNMENT.NUMBER_OF_PARCELS)
    ) || 0;
  if (numberOfParcels > MAXIMUM_NO_OF_ARRAY_ELEMENT) {
    return [];
  }
  return Array(numberOfParcels)
    .fill()
    .map((v, index) => ({
      value: index,
      label: index + 1,
    }));
};

export const isProductBookReadonly = (
  record,
  advancedCustomerConfiguration
) => {
  if (!record) return false;

  if (
    get(record, "readOnly") ||
    !!get(advancedCustomerConfiguration, "readOnlyProductBook", false)
  ) {
    return true;
  }
};

export const getCommodityDescription = ({ commodityDescription }) =>
  commodityDescription.replace(REGEX.HTML_TAGS, "");
