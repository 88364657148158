import { isEmpty, pick } from "lodash";

import { postcodeHelper } from "@dpdgroupuk/common-utils/helpers";

import { COUNTRY_NAME } from "~/constants/countryName";
import { ADDRESS, DELIVERY_DETAILS, Fields } from "~/constants/forms";
import * as S from "~/constants/strings";
import { PostcodeModels } from "~/models";
import postcodeValidation from "~/models/validators/additionalPostcodeValidation";
import collectionDetailsAddressSchema from "~/models/validators/profileAddressSchema";
import createValidator from "~/utils/joiReduxForm";
import { getValue, toUppercaseValues } from "~/utils/object";
import { formatString, truncateString } from "~/utils/string";

export const transformCountryAddress = countryCode => {
  const preparedCountryCode = countryCode.trim().toUpperCase();
  const map = {
    [S.UK]: S.GB,
    [COUNTRY_NAME.ENGLAND]: S.GB,
    [COUNTRY_NAME.UNITED_KINGDOM]: S.GB,
    [COUNTRY_NAME.GREAT_BRITAIN]: S.GB,
    [COUNTRY_NAME.IRELAND]: S.IE,
    [COUNTRY_NAME.REPUBLIC_OF_IRELAND]: S.IE,
  };

  return map[preparedCountryCode] || preparedCountryCode;
};

export const truncateAddress = address => ({
  countryCode: formatString(address.countryCode),
  street: truncateString(formatString(address.street), 35),
  locality: truncateString(formatString(address.locality), 35),
  town: truncateString(formatString(address.town), 35),
  county: truncateString(formatString(address.county), 35),
  postcode: PostcodeModels.isGBPostCodeFormat(address.postcode)
    ? postcodeHelper.formatPostcode(address.postcode)
    : formatString(address.postcode),
  organisation: truncateString(formatString(address.organisation), 35),
});

const isValidProfileAddress = (address, countries, profile) => {
  const profileCountry = countries.find(
    ({ countryKey }) => countryKey === address.countryCode
  );
  const props = {
    values: address,
    requiredFields: {
      countryCode: true,
      postcode: true,
    },
  };
  const profileAddressErrors = createValidator(collectionDetailsAddressSchema, [
    () => postcodeValidation(props, profileCountry, "postcode"),
  ])(address, props);

  return (
    isEmpty(profileAddressErrors) &&
    (address.organisation ||
      address.street ||
      address.locality ||
      address.town ||
      address.county ||
      getValue(profile, "telephone") ||
      getValue(profile, "email"))
  );
};

export const getProfileAddress = profile => ({
  countryCode: transformCountryAddress(getValue(profile, "countryName", "")),
  street: getValue(profile, "street", ""),
  locality: getValue(profile, "suburb", ""),
  // CSHIP-5761 there are 380 profiles with city inside
  town: getValue(profile, "city", "") || getValue(profile, "suburb", ""),
  county: getValue(profile, "county", ""),
  postcode: getValue(profile, "postcode", ""),
  organisation: getValue(profile, "company", ""),
});

export const getOriginUserCollectionAddress = ({
  customer,
  profile,
  customerAddress,
  countries,
}) => {
  const profileAddress = getProfileAddress(profile);

  if (isValidProfileAddress(profileAddress, countries, profile)) {
    return profileAddress;
  }

  const baseAddress =
    customerAddress.country && customerAddress.postcode
      ? customerAddress
      : {
          country: customer.country,
          addline1: customer.addressline1,
          addline2: customer.addressline2,
          addline3: customer.addressline3,
          addline4: customer.addressline4,
          postcode: customer.postcode,
        };

  return {
    countryCode: getValue(baseAddress, "country", ""),
    street: getValue(baseAddress, "addressline1", ""),
    locality: getValue(baseAddress, "addressline2", ""),
    town: getValue(baseAddress, "addressline3", ""),
    county: getValue(baseAddress, "addressline4", ""),
    postcode: getValue(baseAddress, "postcode", ""),
    organisation: getValue(customer, "customername", ""),
  };
};

// @see: https://it.dpduk.live/it/diagram/diag_2dgPzx6C48kIGMvJ.html
export const transformAddressValues = ({ street, locality, property }) => {
  let addressLine1;
  let addressLine2;

  if (property) {
    addressLine1 = `${property} ${street}`;
  } else {
    addressLine1 = street;
  }

  if (addressLine1 && addressLine1.length > 35) {
    addressLine1 = property;
    addressLine2 = `${street}, ${locality}`;

    if (addressLine2 && addressLine2.length > 35) {
      addressLine2 = street;
    }
  } else {
    addressLine2 = locality;
  }

  return {
    addressLine1,
    addressLine2,
  };
};

export const formatPreferenceAddress = ({
  property,
  address1: street,
  address2: locality,
}) => transformAddressValues({ property, street, locality });

export const getAddressField = address => {
  const { addressLine1, addressLine2 } = transformAddressValues(address);
  const { countryCode, town, county, postcode, organisation } = address;

  return {
    ...(countryCode && { [Fields.COUNTRY_CODE]: countryCode }),
    [Fields.STREET]: addressLine1,
    [Fields.LOCALITY]: addressLine2,
    [Fields.TOWN]: town,
    [Fields.COUNTY]: county,
    [Fields.POSTCODE]: postcode,
    [Fields.ORGANISATION]: organisation,
  };
};

export const updateEditInvoiceFormFields = ({ address }) => ({
  [ADDRESS]: getAddressField(address),
});

export const updateDeliveryDetails = (formSection, address) => ({
  [formSection]: {
    [DELIVERY_DETAILS]: {
      [ADDRESS]: {
        countryCode: S.GB,
        ...getAddressField(address),
      },
    },
  },
});

export const transformServiceAddress = address =>
  JSON.stringify(
    toUppercaseValues({
      address: {
        ...pick(address, [Fields.COUNTRY_CODE, Fields.TOWN, Fields.COUNTY]),
        [Fields.POSTCODE]: address?.postcode?.replace(/\s/g, ""),
      },
    })
  );

// @see https://it.dpduk.live/version/customer-shipping/sprint-2.6/diag_.IB4iX6GAqCIardW.html?id=1652860475579
// @see https://dpdgroup.slack.com/archives/G01LEMW2J6L/p1658406401604449
export const getDefaultExporterAddress = ({
  customer,
  userCustomerAddress,
  profile,
  prefsInvoiceSettings,
}) => {
  let address;
  const { customername, country } = customer;

  if (profile?.invDetailsPostcode) {
    address = {
      [Fields.STREET]: profile.invDetailsAddress1,
      [Fields.LOCALITY]: profile.invDetailsAddress2,
      [Fields.TOWN]: profile.invDetailsAddress3,
      [Fields.COUNTY]: profile.invDetailsAddress4,
      [Fields.POSTCODE]: profile.invDetailsPostcode,
      [Fields.ORGANISATION]: (
        profile.invDetailsCompanyName || customername
      )?.toUpperCase(),
      [Fields.TELEPHONE]: profile.invTelephoneNumber,
    };
  } else {
    const { addressLine1, addressLine2 } =
      formatPreferenceAddress(prefsInvoiceSettings);
    address = {
      [Fields.STREET]: addressLine1,
      [Fields.LOCALITY]: addressLine2,
      [Fields.TOWN]: prefsInvoiceSettings.address3,
      [Fields.COUNTY]: prefsInvoiceSettings.address4,
      [Fields.POSTCODE]: prefsInvoiceSettings.postcode,
      [Fields.CONTACT_NAME]: prefsInvoiceSettings.contactName,
      [Fields.TELEPHONE]: prefsInvoiceSettings.contactNumber,
      [Fields.ORGANISATION]: (
        prefsInvoiceSettings.companyName || customername
      )?.toUpperCase(),
    };
  }

  address[Fields.COUNTRY_CODE] =
    userCustomerAddress?.country || country || S.GB;

  return address;
};

export const isCountryGB = countryCode => countryCode === S.GB;

export const isCountryDomestic = countryCode =>
  countryCode === S.GB || countryCode === S.IE;

export const isRegionBT = postcode => postcode.toUpperCase().startsWith("BT");

export const isNiShipment = (countryCode, postcode) =>
  isCountryGB(countryCode) && isRegionBT(postcode);
