import { get, omit } from "lodash";
import {
  EXPORTER_DETAILS,
  Fields,
  INVOICE,
  ShipmentEntity,
} from "~/constants/forms";
import { REQUIRED_TYPE } from "@dpdgroupuk/mydpd-enums";
import { AddressModels, ServiceModels, SettingsModels } from "~/models";

export const getInitialInvoiceExporterDetails = formValues =>
  omit(get(formValues, `${INVOICE}.${EXPORTER_DETAILS}`, {}), [
    Fields.GST_VAT_PAID,
    Fields.DESTINATION_TAX_ID_REG_NO,
  ]);

export const isShippingInformationRequired = (
  generateCustomsData,
  selectedService
) => {
  const properties = [
    selectedService?.exportReasonRequired,
    selectedService?.dt1Available,
    selectedService?.ddpAvailable,
    selectedService?.fdaRequired,
    selectedService?.costsRequired,
  ];

  return (
    generateCustomsData &&
    !ServiceModels.isFlagNeedles(selectedService, "proforma") &&
    (properties.includes(REQUIRED_TYPE.MANDATORY) ||
      properties.includes(REQUIRED_TYPE.OPTIONAL))
  );
};

// @see: https://it.dpduk.live/version/customer-shipping/ni-protocol-shipping/sprint-2/diag_F_RVHF6GAqAADOKk.html
export const isShippingInformationVisible = (
  customer,
  selectedService,
  { generateCustomsData, countryCode, postCode }
) => {
  const isNewVersion = SettingsModels.isNewVersion(customer.shippingVersion);
  const isInvoiceDetailsSectionRequired = isShippingInformationRequired(
    generateCustomsData,
    selectedService
  );
  const isNiShipment = AddressModels.isNiShipment(countryCode, postCode);
  const isExportImportEoriRequired =
    ServiceModels.isFlagRequired(selectedService, "exportImportRequired") &&
    !ServiceModels.isFlagNeedles(selectedService, "eoriRequired");

  return {
    invoiceDetailsSection:
      (!isNewVersion && generateCustomsData) ||
      (isNewVersion && isInvoiceDetailsSectionRequired),
    exporterImporterDetails:
      (!isNewVersion && generateCustomsData) ||
      (isNewVersion && generateCustomsData && isExportImportEoriRequired),
    // @see: https://it.dpduk.live/it/diagram/diag_SYhhYwmGAqCIdhEc.html
    businessFields: isNewVersion && isNiShipment,
  };
};

export const isProductsRequired = (customer, selectedService, formValues) =>
  SettingsModels.isNewVersion(customer.shippingVersion)
    ? (ServiceModels.isFlagRequired(selectedService, "prodRequired") &&
        formValues?.[ShipmentEntity.GENERATE_CUSTOMS_DATA]) ||
      ServiceModels.isDpdDirectService(selectedService)
    : (ServiceModels.isFlagRequired(selectedService, "prodRequired") &&
        ServiceModels.isFlagNeedles(selectedService, "proforma")) ||
      formValues?.[ShipmentEntity.GENERATE_CUSTOMS_DATA];

export const isBusinessRequired = (
  shippingVersion,
  selectedService,
  generateCustomsData
) =>
  SettingsModels.isNewVersion(shippingVersion) &&
  ServiceModels.isFlagRequired(selectedService, "exportImportRequired") &&
  generateCustomsData;
